import Rover from '@rover/globals/Rover';
import Mustache from '@rover/globals/Mustache';
import _ from '@rover/globals/underscore';
// WARNING: DF from datetimeInjected is not SSR compatible.
// For newer code, use const DF = getDateTimeFormatMapForLang(<lang>) from '@rover/shared/js/constants/i18n/datetime'
import DF from '@rover/shared/js/constants/i18n/datetime/datetimeInjected';
import NumUnitsSupportView from './num_units_support_view';

export default NumUnitsSupportView.extend({
  template: Mustache.template('new_design/common/noncontiguous_dates'),
  initialize(options) {
    NumUnitsSupportView.prototype.initialize.call(this, options);

    this.datesModel = new Rover.models.MultiDate();
    if (this.service.get('service_type').supports_day_of_week_search) {
      this.datesModel.setDays(this.getDaysFromUrl());
    } else {
      this.datesModel.set('dates', this.getDateRangeFromUrl());
    }
    this.propagateDatesChanged(this.datesModel, 'change');

    this.datePickerView = new Rover.views.DatesPicker({
      slug: this.service.get('service_type').slug,
      model: this.datesModel,
      disableStart: options.disableStart,
      dateFormat: DF.DATE_PICKER,
      momentFormat: DF.DATE_SHORT,
      momentLogFormat: DF.MONTH_DAY_FULL,
    });
  },
  render() {
    const ctx = { units: this.service.units() };
    this.$el.html(this.template.render(ctx));
    this.$('.js-dates-view').append(this.datePickerView.render().$el);

    if (this.numUnitsView) {
      this.$('.js-num-units-view').append(this.numUnitsView.render().$el);
    }

    return this;
  },
  getDatesData() {
    const dates = this.datesModel.get('dates');
    const numUnits = this.getNumUnits();
    const self = this;

    if (!dates.length) return {};

    return {
      dates: _.map(dates, date => ({
        date: self.formatDate(date),
        num_units: numUnits,
      })),
    };
  },
});
