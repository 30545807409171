/* globals _ */
import dataLayer from '@rover/globals/dataLayer';
import $ from '@rover/globals/jquery';
import Rover from '@rover/globals/Rover';
import _ from '@rover/globals/underscore';

Rover.utils = Rover.utils || {};

Rover.utils.scrollTo = (selector: any, speed = 'fast') => {
  const top =
    ($(selector) as any).offset().top -
    ($('.navbar-header') as any).height() -
    ($('.header-toolbar-wrapper') as any).height() -
    20;
  $('html,body').animate(
    {
      scrollTop: top,
    },
    speed
  );
};

export const { scrollTo } = Rover.utils;

export const trackContactProviderClick = (isUserLoggedIn) => {
  dataLayer.push({
    event: 'record-event',
    event_name: isUserLoggedIn ? 'CSC - logged in' : 'CSC - not logged in',
  });
};

export const isElBelowViewportTop = ($el) => {
  const el = $el instanceof HTMLElement ? $el : $el[0];
  const rect = el.getBoundingClientRect();
  return rect.top >= 0 && rect.left >= 0;
};

export const isElAboveViewportBottom = ($el) => {
  const el = $el instanceof HTMLElement ? $el : $el[0];
  const rect = el.getBoundingClientRect();
  return (
    rect.top + rect.height <= window.innerHeight && rect.left + rect.width <= window.innerWidth
  );
};

Rover.utils.getBreakpoint = () => {
  const w: number = $(window).width() || 0;
  const breakpoints = {
    lg: 1200,
    md: 992,
    sm: 768,
    xs: 0,
  };

  if (w >= breakpoints.lg) {
    return 'lg';
  }
  if (w >= breakpoints.md) {
    return 'md';
  }
  if (w >= breakpoints.sm) {
    return 'sm';
  }

  return 'xs';
};

/*
 *  inViewport($el)
 *
 *  Returns the height, in pixels, of $el
 */
Rover.utils.getElemVisibleY = ($elem) => {
  const elH = $elem.outerHeight();
  const H: any = $(window).height();
  const r = $elem[0].getBoundingClientRect();
  const t = r.top;
  const b = r.bottom;
  // eslint-disable-next-line no-nested-ternary
  return Math.max(0, t > 0 ? Math.min(elH, H - t) : b < H ? b : H);
};

Rover.utils.parallel = (funcs, allDone) => {
  let counter = funcs.length;

  const cb = () => {
    // eslint-disable-next-line no-plusplus
    counter--;

    if (counter === 0) {
      if (_.isFunction(allDone)) {
        allDone();
      }
    }
  };

  funcs.forEach((func) => func(cb));
};

export const createThenableDebouncer = () => {
  /**
    Think of this as a debounce for Promises. Rather than waiting some amount
    of time before it allows a call to execute, it waits for last pending
    Promise to resolve.
     This creates a function which when passed functions which return thenables
    (i.e. Promises) will execute the most recently passed function only if
    there is no pending thenable.
     Usage:
    import { createThenableDebouncer } from './app/js/utilities/ui';
    var debouncer = createThenableDebouncer();
    debouncer(new Promise(...do stuff...));
    debouncer(new Promise(...do stuff...));
    debouncer(new Promise(...do stuff...));
    debouncer(new Promise(...do stuff...));
    * */
  let nextFunc;
  let runner;

  function runComplete() {
    const thenableFunc = nextFunc;
    runner = null;

    if (thenableFunc) {
      nextFunc = null;
      runner = startRun(thenableFunc); // eslint-disable-line @typescript-eslint/no-use-before-define
    }
  }
  function startRun(thenableFunc) {
    return thenableFunc().then(runComplete);
  }

  function run(thenableFunc) {
    if (!runner) {
      runner = startRun(thenableFunc);
    } else {
      nextFunc = thenableFunc;
    }
  }

  return run;
};

const exportedUi = {
  trackContactProviderClick,
  isElAboveViewportBottom,
  isElBelowViewportTop,
  createThenableDebouncer,
};

export default exportedUi;
