/* eslint-disable max-classes-per-file */
export type HttpErrorStatusCodes = '301' | '403' | '404' | '410' | '429' | '500';

class HttpError extends Error {
  // we type this as `any` to allow subclasses to be more specific about what codes they allow
  statusCode: any;

  constructor(statusCode: HttpErrorStatusCodes, message: string) {
    super(message);
    this.statusCode = statusCode;
  }
}

export interface Http3xxError extends HttpError {
  statusCode: '301';
  location: string;
}
export class Http301Error extends HttpError implements Http3xxError {
  location: string;

  constructor(location: URL | string, message = 'Error 301: Moved Permanently') {
    super('301', message);
    this.location = location.toString();
  }
}
export function isRedirectError(error: HttpError): error is Http3xxError {
  return (
    error.statusCode.startsWith('3') &&
    // @ts-expect-error: `.location` doesn't exist on all subclasses of `HttpError`, that's why we're checking it
    typeof error.location === 'string'
  );
}

export interface Http4xxError extends HttpError {
  statusCode: '403' | '404' | '410' | '429';
}
export const isClientError = (error: HttpError): error is Http4xxError =>
  error.statusCode.startsWith('4');
export class Http403Error extends HttpError implements Http4xxError {
  constructor(message = 'Error 403: Forbidden') {
    super('403', message);
  }
}
export class Http404Error extends HttpError implements Http4xxError {
  constructor(message = 'Error 404: Not Found') {
    super('404', message);
  }
}
export class Http410Error extends HttpError implements Http4xxError {
  constructor(message = 'Error 410: Gone') {
    super('410', message);
  }
}
export class Http429Error extends HttpError implements Http4xxError {
  constructor(message = 'Error 429: Too Many Requests') {
    super('429', message);
  }
}

export class Http500Error extends HttpError {
  constructor(message = 'Error 500: Internal Server Error') {
    super('500', message);
  }
}
export default HttpError;
