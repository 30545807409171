import Rover from '@rover/globals/Rover';
import Backbone from '@rover/globals/backbone';

Rover.models = Rover.models || {};
Rover.collections = Rover.collections || {};

Rover.models.Dog = Backbone.Model.extend({
  defaults: {
    id: null,
    name: '',
    weight: '',
    breeds: [],
    gender: '',
  },
});

Rover.collections.DogCollection = Backbone.Collection.extend({
  model: Rover.models.Dog,
  // Future: set this up and fetch dogs asynchronously
  // url: fetchUsersDogsUrl
  //
});
