import { t } from '@lingui/macro';

import $ from '@rover/globals/jquery';
import moment from '@rover/globals/moment';
import Rover from '@rover/globals/Rover';
import _ from '@rover/globals/underscore';
// WARNING: DF from datetimeInjected is not SSR compatible.
// For newer code, use const DF = getDateTimeFormatMapForLang(<lang>) from '@rover/shared/js/constants/i18n/datetime'
import DF from '@rover/shared/js/constants/i18n/datetime/datetimeInjected';
import inject from '@rover/utilities/injector';
import i18n from '@rover/utilities/translation';

Rover.ValidationUtils = Rover.ValidationUtils || {};

function PostalCodesRegex(postal) {
  //  Any supported country should have the postal code regex added there
  //  Source of GB Regex: https://roverdotcom.atlassian.net/browse/DEV-20164
  const regexRules = {
    usRegex: /^\d{5}-\d{4}$|^\d{5}$/,
    caRegex: /^[A-Z][0-9][A-Z][-\s]?[0-9][A-Z][0-9]$/,
    gbRegex:
      /^([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9]?[A-Za-z]))))\s?[0-9][A-Za-z]{2})$/,
    deAndFrRegex: /^([0-8]\d|9[0-8])\d{3}$/,
  };
  return {
    valid: function validate() {
      return Object.values(regexRules).some((rule) => rule.test(postal));
    },
  };
}

$.validator.addMethod(
  'min_words',
  (value, element, n) => $(element).wordCount() >= n,
  (n) => i18n._(t`Please write at least ${n} words.`)
);

export const getSignupCountryConfiguration = (countrySelector) => {
  const string = $('#sign-up-country-configuration').val();
  if (string) {
    const signup_country_configuration = JSON.parse(string);
    const country_code = countrySelector.val();
    if (signup_country_configuration.hasOwnProperty(country_code)) {
      return signup_country_configuration[country_code];
    }
    return signup_country_configuration.US;
  }
  return null;
};

export const zipCodeValidator = (postal, element, params) => {
  const configuration = getSignupCountryConfiguration(params.countrySelector);
  if (configuration != null) {
    const regex = configuration.postalCodeRegex;
    const zipCodeValue = postal;
    return !!zipCodeValue.match(regex);
  }
  return false;
};

export const nameValidator = (name, element, params) => {
  const unsupportedChars = ['{', '}'];
  const includesUnsupportedChars = name.split('').some((c) => unsupportedChars.includes(c));
  const isValid = !includesUnsupportedChars;
  return isValid;
};

export const postalCodeValidator = (postal, element) => {
  const optionalCheck = $(element).validate().optional(element);
  return optionalCheck || PostalCodesRegex(postal).valid();
};

(function () {
  let filters = null;
  let contact = '';

  function initFilters() {
    if (filters) return;
    const emailDefinite = [/[a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}/];
    const urlDefinite = [
      /(http:\/\/|https:\/\/)?([a-zA-Z0-9]{2,4}\.)?[a-zA-Z0-9]+\.(com|org|gov|biz|cat|coop|info|int|jobs|mobi|museum|name|net|pro|tel|travel|co\.uk|COM|ORG|GOV|BIZ|CAT|COOP|INFO|INT|JOBS|MOBI|MUSEUM|NAME|NET|PRO|TEL|TRAVEL|CO\.UK)(\/[-a-zA-Z0-9\/]+)?/,
    ];
    const phoneDefinite = [/(1[- \.])?\(?\d{3}\)?[- \.]?\d{3}[- \.]?\d{4}/, /\d{3}[- \.]?\d{4}/];
    filters = emailDefinite.concat(urlDefinite).concat(phoneDefinite);
  }

  function runFilters(text) {
    let matches = _.map(filters, (filter) => text.match(filter));
    matches = _.filter(matches, (match) => !!match && !match[0].match(/rover.com/i));
    return matches;
  }

  function onContactsFound(contacts) {
    contact = contacts[0][0];
  }

  $.validator.addMethod(
    'disallow_contact',
    (value, element) => {
      initFilters();
      const contacts = runFilters($(element).val());
      if (contacts.length) onContactsFound(contacts);
      return contacts.length === 0;
    },
    () =>
      i18n._(t`For your security, please remove "${contact}" from your description. Owners
      will contact you through Rover, and you must book all stays through
      the site. It's safe and easy - plus, the stay will be covered by
      premium insurance and 24/7 emergency support.`)
  );

  $.validator.addMethod('zippostalValidate', zipCodeValidator, (params, element) =>
    i18n._(/* i18n: {0} Name of the country */ t`You have not entered a valid postcode for ${
      getSignupCountryConfiguration(params.countrySelector).country_name_as_subject
    }.
    Enter a valid postcode, or switch country`)
  );

  $.validator.addMethod('nameValidate', nameValidator, (params, element) =>
    i18n._(
      t`You have entered an invalid name format. Please remove any special characters from your name.`
    )
  );

  $.validator.addMethod(
    'l10ndate',
    (value, element) => moment(value, DF.DATE_SHORT).isValid(),
    'Please enter a valid date.'
  );
})();

(function (o) {
  /**
   * $ validation plugin does not play well with
   * bootstrap's button plugin. In particular when a button
   * is clicked, the event is not caught by the $
   * validation plugin becasue the event target is bootstrap's
   * div wrapper, not the input element.
   *
   * This click handler refires a click event from the
   * input element when there is an error pop-over over
   * the element.
   */
  const namespace = '.hideValidationErrorOnButtonClick';
  o.hideValidationErrorOnButtonClick = function () {
    $('[data-toggle^="button"]')
      .off(namespace)
      .on(`click${namespace}`, (evt) => {
        const $target = $(evt.target).closest('[data-toggle^="button"]');
        const $errorPopover = $('.error-popover', $target);
        $target.off(namespace);
        if (!$errorPopover.size()) return;
        $(`input[aria-describedby="${$errorPopover.attr('id')}"]`, $target).click();
        evt.stopImmediatePropagation();
      });
  };
})(Rover.ValidationUtils);

export default postalCodeValidator;
