import type { UtmParams } from '@rover/types/src/ImagePicker';

const appendUrlParams = (url: string, params: string | Record<string, string> | UtmParams) => {
  if (typeof params === 'string' && !params.length) return url;
  if (typeof params === 'object' && !Object.keys(params).length) return url;
  let joiner = '?';
  if (url.indexOf(joiner) !== -1) joiner = '&';
  let newUrl = url;

  if (typeof params === 'string') {
    newUrl += `${joiner}${params}`;
  } else {
    Object.keys(params).forEach((key: string) => {
      newUrl += `${joiner}${key}=${params[key]}`;
      joiner = '&';
    });
  }

  return newUrl;
};

export default appendUrlParams;
