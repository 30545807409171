import i18n from '@rover/utilities/translation';
import ModalView from '../components/rover_modal';

import '../backbone/views/contact_backups_modal_view';

$(() => {
  let $conversation = null;
  let inquiryTierUrl = null;
  const SOURCE_HEADER = Rover.api.getSourceHeader('web/conversation-detail');

  const showContactBackupsErrorModal = () => {
    const failureModal = new ModalView({
      title: i18n._('Failure'),
      content: i18n._('Your backups could not be contacted. Please try again in a few moments.'),
      showFooter: true,
      disableCancel: true,
      okCloses: true,
    });

    failureModal.open();
    window.contact_backups_modal.close();
  };

  const successHandler = () => {
    window.contact_backups_modal.close();
    window.location.reload();
  };

  const save = () => {
    $.ajax({
      url: inquiryTierUrl,
      method: 'PUT',
      contentType: 'application/json',
      headers: SOURCE_HEADER,
      data: JSON.stringify({
        status: 'active',
      }),
      success: successHandler,
      error: showContactBackupsErrorModal,
    });
    window.contact_backups_modal.togglePrimaryButton();
  };

  $('.js-cta-contact-backups-now').on('click', function(event) {
    $conversation = $(this).closest('.js-conversation-container');
    inquiryTierUrl = $conversation.data('inquiry-next-tier-url');
    const contactBackupsText = $conversation.data('inquiry-contact-backups-text');

    window.contact_backups_modal = new Rover.views.ContactBackupsModal({
      title: i18n._('Contact backups now'),
      content: contactBackupsText,
      showFooter: true,
      okCloses: false,
      okIcon: 'fr-spin rover-icon rover-icon-in-spinner archive-modal-loading-icon',
      okText: i18n._('Send'),
      inquiryTierUrl,
      onSave: save,
    });

    window.contact_backups_modal.open();
  });
});
