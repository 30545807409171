import React from 'react';

import { Box, Flex, Heading, Image, Paragraph } from '@rover/kibble/core';
import { DSTokenMap } from '@rover/kibble/styles';

import { ImageHeaderProps } from './ImageHeader.types';

const ImageHeader = ({
  caption,
  captionProps,
  children,
  contentProps,
  description,
  heading,
  headingProps,
  headingSize = '300',
  image,
  imageHeight = DSTokenMap.SPACE_48X,
  imageProps,
  imageWidth = '100%',
  paddedContent,
  paddedImage,
  resizeMethod,
  ...flexProps
}: ImageHeaderProps): JSX.Element => {
  return (
    <Flex flexDirection="column" gap="4x" mb="4x" {...flexProps}>
      <Box px={paddedImage ? '4x' : undefined}>
        <Image
          src={image}
          description={description}
          width={imageWidth}
          height={imageHeight}
          resizeMethod={resizeMethod}
          {...imageProps}
        />
      </Box>
      <Box px={paddedContent ? '4x' : undefined} {...contentProps}>
        {caption && (
          <Paragraph
            textAlign="left"
            size="100"
            pt="0x"
            pb={['2x', '4x']}
            mb="0x"
            textColor="tertiary"
            {...captionProps}
          >
            {caption}
          </Paragraph>
        )}
        {heading && (
          <Heading size={headingSize} mb={['2x', '4x']} {...headingProps}>
            {heading}
          </Heading>
        )}
        {children && <Box mb="0x">{children}</Box>}
      </Box>
    </Flex>
  );
};

export default ImageHeader;
