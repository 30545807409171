import { I18n as I18nType } from '@lingui/core';
import { t } from '@lingui/macro';

import { ConversationDetail } from '@rover/rsdk/src/apiClient/latest';
import { SERVICE_TYPE_CHOICES, SERVICE_TYPE_PRICE_UNITS } from '@rover/shared/js/constants/service';
import { ServiceTypeSlug } from '@rover/types';

function getConversationOpk(url): string {
  if (!url) {
    return '';
  }

  const match = url.match(/conversations\/([a-zA-Z0-9]+)\/$/);

  if (!match) {
    return '';
  }

  return match[1] || '';
}

/**
 *
 * @param conversation
 * @param i18n
 * @returns {string} Returns total number of units for a conversation
 * @example
 * getTotalUnits(conversation);
 * // => '12 visits'
 * // => '2 walks'
 */
export const getTotalUnits = (conversation: ConversationDetail, i18n: I18nType): string => {
  let numUnits = 0;
  if (!conversation.timeRanges) {
    numUnits = conversation.dates.reduce((acc, date) => {
      if (date.flexibleTimes != null && date.flexibleTimes?.length > 0) {
        return (
          acc +
          (date.flexibleTimes != null && date.flexibleTimes.length > 0
            ? date.flexibleTimes.length
            : 1)
        );
      }
      return acc + (date.times != null && date.times.length > 0 ? date.times.length : 1);
    }, 0);
  } else {
    numUnits = conversation.dates.length;
  }

  return `${numUnits} ${i18n._(SERVICE_TYPE_PRICE_UNITS[conversation.serviceType](numUnits))}`;
};

/**
 * @param conversation
 * @param i18n
 * @returns {string | null} Returns the duration of a unit
 * @example
 * getUnitDuration(conversation);
 * // => '30 min'
 * // => '60 min'
 * // => null
 */
export const getUnitDuration = (
  conversation: ConversationDetail,
  i18n: I18nType
): string | null => {
  if (
    [SERVICE_TYPE_CHOICES.walking, SERVICE_TYPE_CHOICES.dropIn].includes(
      conversation.serviceType as ServiceTypeSlug
    )
  ) {
    const hasActiveLongAddOn = conversation.bookingAddOns.reduce(
      (acc, addOn) => (addOn.active && ['long-drop-in', 'long-walk'].includes(addOn.slug)) || acc,
      false
    );

    return hasActiveLongAddOn ? i18n._(t`60 min`) : i18n._(t`30 min`);
  }

  return null;
};

/**
 * @param conversation
 * @param i18n
 * @returns {string} Returns the total number of pets in a conversation
 * @example
 * getTotalPets(conversation);
 * // => '1 pet'
 * // => '5 pets'
 */
export const getTotalPets = (conversation: ConversationDetail, i18n: I18nType): string => {
  const totalPets = conversation.pets.length;
  return totalPets > 1 ? i18n._(t`${totalPets} pets`) : i18n._(t`1 pet`);
};

export default getConversationOpk;
