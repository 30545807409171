import $ from '@rover/globals/jquery';
import Rover from '@rover/globals/Rover';

$(() => {
  const hidePhoto = function(e) {
    const $hideButton = $(e.currentTarget);
    $hideButton.btn('loading');

    $.ajax({
      url: Rover.urls.hidePhotoUrl,
      headers: {'Content-Type': 'application/json'},
      type: 'POST',
      data: JSON.stringify(
        {
          image_id: $hideButton.attr('data-object-id')
        }
      ),
      success() {
        toggleHiddenPhotoOnPage($hideButton.attr('data-hidden') === 'false', $hideButton);
      },
      error() {
        alert('Failed to perform operation. Please refresh the page and try again.');
        $hideButton.btn($hideButton.attr('data-hidden') === 'false' ? 'hide' : 'unhide');
      },
    });
  };

  var toggleHiddenPhotoOnPage = function(wasHidden, $buttonClicked) {
    const $photoOnPage = $(
      `.js-shareable-image[data-object-id=${$buttonClicked.attr('data-object-id')}]`
    );

    if (wasHidden) {
      $buttonClicked.attr('data-hidden', 'true').btn('unhide');
      $photoOnPage
        .addClass('hidden-photo')
        .attr('data-hidden', 'true')
        .find('.js-hidden-photo')
        .removeClass('hide');
    } else {
      $buttonClicked.attr('data-hidden', 'false').btn('hide');
      $photoOnPage
        .removeClass('hidden-photo')
        .attr('data-hidden', 'false')
        .find('.js-hidden-photo')
        .addClass('hide');
    }
  };

  const addBindings = function() {
    $(document).on('click', '.js-hide-photo', hidePhoto);
  };

  const init = function() {
    addBindings();
  };

  init();
});
