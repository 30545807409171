import Mustache from '@rover/globals/Mustache';
import Backbone from '@rover/globals/backbone';
import $ from '@rover/globals/jquery';
import _ from '@rover/globals/underscore';
import ServiceDateSpecView from './service_date_spec_view';
import DaysOfWeekWithStartDateView from './days_of_week_with_start_date_view';

export default ServiceDateSpecView.extend({
  template: Mustache.template('new_design/common/recurring'),
  bindings: {
    '.js-is-recurring': {
      observe: 'isRecurring',
      getVal($el, event, options) {
        return (
          $(event.currentTarget)
            .find('input')
            .val() === 'true'
        );
      },
    },
  },
  initialize(options) {
    ServiceDateSpecView.prototype.initialize.call(this, options);

    this.model = new Backbone.Model({
      isRecurring: null,
    });

    this.recurringView = new DaysOfWeekWithStartDateView(options);
    this.propagateDatesChanged(this.recurringView);
    this.nonRecurringView = options.nonRecurringView;
    this.propagateDatesChanged(this.nonRecurringView);

    // TODO: Change to listenTo when base_transition is gone
    this.model.on('change:isRecurring', _.bind(this.onIsRecurringChange, this));
  },
  render() {
    const ctx = { units: this.service.units() };
    this.$el.html(this.template.render(ctx));
    this.$el.append(this.recurringView.render().$el.addClass('hidden'));
    this.$el.append(this.nonRecurringView.render().$el.addClass('hidden'));
    this.stickit();
    this.onIsRecurringChange(this.model, this.model.get('isRecurring'));
    return this;
  },
  getDatesData() {
    if (this.isRecurring()) return this.recurringView.getDatesData();
    return this.nonRecurringView.getDatesData();
  },
  isRecurring() {
    return this.model.get('isRecurring');
  },
  onIsRecurringChange(model, isRecurring) {
    this.recurringView.$el.toggleClass('hidden', !isRecurring);
    this.nonRecurringView.$el.toggleClass('hidden', isRecurring || isRecurring === null);
  },
});
