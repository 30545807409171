import { t } from '@lingui/macro';
import uuid from 'uuid';

import $ from '@rover/globals/jquery';
import Rover from '@rover/globals/Rover';
import parseApiErrors from '@rover/utilities/parseApiErrors';
import i18n from '@rover/utilities/translation';

const CUSTOMER_SUPPORT_PHONE = Rover?.context?.customerSupportPhone;
class Alerts {
  static showToast({
    severity = 'info',
    message = '',
    container = '.js-alerts-container',
    showCloseButton = true,
    describedInputSelector = '', // Use if this is a form error associated with an individual input
    clearExisting = false,
  } = {}): void {
    if (clearExisting) {
      this.clearToasts(container);
    }
    const $parentContainer = $(container);
    const severityClass =
      ['success', 'info', 'warning', 'danger'].indexOf(severity) !== -1 ? severity : 'info';
    const closeButton = showCloseButton
      ? '<button type="button" class="close js-alert-close" data-dismiss="alert" aria-hidden="true" aria-label="close alert">&times;</button>'
      : '';
    const rendered = `
            <div class="alert alert-${severityClass} padded fade in js-alert">
                ${closeButton}
                ${message}
            </div>
        `;
    $parentContainer.append(rendered);

    // If the alert is associated with an input, give it an ID which can be used with aria-describedby on the input
    if (describedInputSelector.length > 0) {
      let errorContainerId = $parentContainer.attr('id');
      if (!errorContainerId) {
        errorContainerId = uuid.v4();
        $parentContainer.attr('id', errorContainerId);
      }
      $(describedInputSelector).attr('aria-describedby', errorContainerId).focus();
    }
  }

  static clearToasts(container = '.js-alerts-container'): void {
    const $parentContainer = $(container);
    $parentContainer.empty();
  }

  static showAlert({ attemptedAction = 'processing your request' } = {}): void {
    // eslint-disable-next-line no-alert
    alert(
      i18n._(
        t`Oops, we encountered an error while ${attemptedAction}. Please try again shortly or contact Customer Support at ${CUSTOMER_SUPPORT_PHONE}.`
      )
    );
  }

  static alertApiError(formattedError: string, clearExisting = false): void {
    const alertsContainer = $('.js-alerts-container');
    if (alertsContainer.length) {
      this.showToast({
        message: formattedError,
        severity: 'danger',
        clearExisting,
      });
      const top = alertsContainer.offset()?.top;
      // eslint-disable-next-line rover/no-platform-specific-globals-or-imports
      window.scrollTo(top || 0, 0);
    } else {
      this.showAlert({
        attemptedAction: i18n._(t`processing your request: ${formattedError}`),
      });
    }
  }

  static failedApiResponse(response: Response): void {
    const errors = parseApiErrors(response);

    if (errors === undefined || errors.length === 0) {
      this.alertApiError(
        i18n._(
          t`Oops, we encountered an error while processing your request. Please try again shortly or contact Customer Support ${CUSTOMER_SUPPORT_PHONE}`
        )
      );
      return;
    }

    errors.forEach((err) => this.alertApiError(String(err)));
  }
}

export default Alerts;
