import Backbone from '@rover/globals/backbone';
import _ from '@rover/globals/underscore';
import $ from '@rover/globals/jquery';

export default Backbone.View.extend({
  events: {
    'click .rover-button': 'onRoverBtnClick',
  },
  initialize() {
    _.bindAll(this, 'onCollectionChange');
    this.collection.on('change', this.onCollectionChange);
  },
  onCollectionChange() {
    const service = this.collection.getSelected();
    this.$('.rover-button').removeClass('active');
    if (!service) return;
    this.$(`.rover-button[data-slug="${service.get('slug')}"]`).addClass('active');
  },
  onRoverBtnClick(evt) {
    const $target = $(evt.target).closest('.rover-button');
    this.collection.setSelected($target.data('slug'));
  },
});
