import $ from '@rover/globals/jquery';
import Rover from '@rover/globals/Rover';
import { closeDialog } from '@rover/utilities/embedded';

import ModalView from './rover_modal';

((document) => {
  function getModalContent(conversation) {
    if (!conversation || !conversation.blocking_modal) {
      const d = $.Deferred();
      d.reject();
      return d.promise();
    }

    return $.get(conversation.blocking_modal.web_url);
  }

  function showModal(modalContent) {
    const backboneModal = new ModalView({
      content: modalContent,
      disableCancel: true,
      animate: true,
      padding: false,
      showFooter: false,
    });
    backboneModal.open();

    // and then wait for the acknowledgement
    const d = $.Deferred();
    $(document).on('safety-dialog-acknowledged', () => {
      backboneModal.close();
      d.resolve();
    });

    return d.promise();
  }

  /*
        Returns a Promise which will be fulfilled if the dialog is completed
        or will be rejected if the dialog is not needed or if anything fails.
    */
  function handleSafetyDialog(conversationUri) {
    return $.get(conversationUri).then(getModalContent).then(showModal);
  }

  function doSafetyAdvisory(conversation) {
    return getModalContent(conversation).then(showModal);
  }

  function triggerSafetyDialogAcknowledged() {
    $(document).trigger('safety-dialog-acknowledged');
    closeDialog();
  }

  function acknowledgeSafetyForm($target) {
    const payload = $target.serialize();
    const uri = $target.attr('action');

    return $.post(uri, payload).always(triggerSafetyDialogAcknowledged);
  }
  // Enable button for embedded mobile only after document ready to prohibit exposing DRF on safety acknowledgement POST
  function enableSafetyButton() {
    const safetyAckBtn = document.getElementById('safety-ack-btn');
    if (safetyAckBtn) {
      safetyAckBtn.removeAttribute('disabled');
    }
  }

  function init() {
    $(document).on('submit', '#safety-acknowledgement-form', (event) => {
      event.preventDefault();
      $(event.currentTarget).attr('disabled', 'disabled');
      $(event.currentTarget).closest('.js-modal-dialog').addClass('rover-loading');
      acknowledgeSafetyForm($(event.currentTarget));
    });
  }

  Rover.safety = {
    handleSafetyDialog,
    doSafetyAdvisory,
  };
  init();
  $(document).ready(enableSafetyButton);
})(document);
