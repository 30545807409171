import Backbone from '@rover/globals/backbone';
import Mustache from '@rover/globals/Mustache';
import _ from '@rover/globals/underscore';
import $ from '@rover/globals/jquery';

import NumUnitsModel from '../models/num_units_model';

export default Backbone.View.extend({
  template: Mustache.template('new_design/common/num_units'),
  bindings: {
    '.js-num-units-select': {
      observe: 'num_units',
      onSet(value) {
        return parseInt(value, 10);
      },
    },
    '.js-num-units-description': {
      observe: 'num_units',
      onGet: 'getDescription',
    },
  },
  initialize(opts) {
    this.model = new NumUnitsModel();
    this.descriptions = opts.desc;
    this.context = {
      label: opts.label,
      desc: this.getDescription(),
    };

    Backbone.$ = window.$; // Remove once we are off the old version of Backbone
  },
  render() {
    const ctx = _.extend(this.context, this.model.attributes);
    this.$el.html(this.template.render(ctx));
    this.stickit();
    setTimeout(() => {
      $('.js-num-units-select').val(this.model.get('num_units'));
    }, 0);
    return this;
  },
  getDescription(numUnits) {
    return this.descriptions(numUnits);
  },
});
