/* globals Url */
import moment from '@rover/globals/moment';
import _ from '@rover/globals/underscore';
import Backbone from '@rover/globals/backbone';
// WARNING: DF from datetimeInjected is not SSR compatible.
// For newer code, use const DF = getDateTimeFormatMapForLang(<lang>) from '@rover/shared/js/constants/i18n/datetime'
import DF from '@rover/shared/js/constants/i18n/datetime/datetimeInjected';

export default Backbone.View.extend({
  DAYS: ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'],
  initialize(options) {
    this.service = options.service;
    this.$el.addClass('js-service-dates-spec');
  },
  isRecurring() {
    return false;
  },
  propagateDatesChanged(target, event = 'datesChanged') {
    this.listenTo(target, event, _.bind(() => this.trigger('datesChanged'), this));
  },
  getDaysFromUrl() {
    const url = new Url();

    return _.filter(this.DAYS, day => {
      const value = url.query[day];
      if (!value) return false;
      return value.toLowerCase() === 'true';
    });
  },
  getDateRangeFromUrl() {
    const url = new Url();
    if (url.query.start_date && url.query.end_date) {
      return this.dateRange(url.query.start_date, url.query.end_date);
    }
    return [];
  },
  formatDate(dateString) {
    const date = moment(dateString, [DF.DATE_SHORT, DF.DATE_ISO]).toDate();
    if (!dateString) return null;
    return this.dateInISOFormat(date);
  },
  dateInISOFormat(date) {
    function zeroPad(s) {
      s += '';
      return `${s.length < 2 ? '0' : ''}${s}`;
    }

    return `${date.getFullYear()}-${zeroPad(date.getMonth() + 1)}-${zeroPad(date.getDate())}`;
  },
  dateRange(startDate, endDate) {
    let date = moment(startDate, [DF.DATE_SHORT, DF.DATE_ISO]);
    let end = moment(endDate, [DF.DATE_SHORT, DF.DATE_ISO]);
    const dateRange = [date.format(DF.DATE_SHORT)];

    // If the service's end date is exclusive, we need to exclude the last day selected
    if (this.service.is_end_date_exclusive()) {
      end = end.subtract(1, 'days');
    }

    while (date.isBefore(end)) {
      date = date.add(1, 'days');
      dateRange.push(date.format(DF.DATE_SHORT));
    }

    return dateRange;
  },
});
