import Rover from '@rover/globals/Rover';

import getUrlParameter from './getUrlParameter';
import { addEventParamsToUrl } from './url.server';

export { addEventParamsToUrl } from './url.server';
export { default as getQueryParams } from './getQueryParams';

Rover.utils = Rover.utils || {};
Rover.utils.getUrlParameter = getUrlParameter;

Rover.utils.hasHashParam = (name: string): boolean => {
  if (window.location.hash) {
    const hash = window.location.hash.substring(1);

    if (hash.indexOf(name) !== -1) {
      return true;
    }
  }

  return false;
};

export function redirect(url: string | Location | URL, target?: string): void {
  if (target === '_blank') {
    const redirectUrl = url instanceof Location ? url.href : url;
    window.open(redirectUrl instanceof URL ? redirectUrl.toString() : redirectUrl, target);
  } else {
    // `window` is also `typeof globalThis`, which doesn't allow setting `location` to a string
    // https://github.com/microsoft/TypeScript/issues/48949
    (window as Window).location = url instanceof URL ? url.toString() : url;
  }
}

// Our types for `URLSearchParams` are incorrect – it does implement Symbol.iterator
declare global {
  interface URLSearchParams {
    entries(): IterableIterator<[string, string]>;
    [Symbol.iterator](): IterableIterator<[string, string]>;
  }
}

declare global {
  interface Window {
    isRoverURL(url: string): boolean;
  }
}

window.isRoverURL = (url: string): boolean =>
  url.indexOf('//') === -1 ||
  url.indexOf(Rover.urls.base) !== -1 ||
  url.indexOf('//localhost') !== -1 ||
  url.indexOf('//0.0.0.0') !== -1 ||
  url.indexOf('//127.0.0.1') !== -1;

export default { ...Rover.utils, redirect, addEventParamsToUrl };
