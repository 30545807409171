import currentUser from '@rover/globals/currentUser';
import $ from '@rover/globals/jquery';
import Rover from '@rover/globals/Rover';
import appendUrlParams from '@rover/rsdk/src/modules/Network/appendUrlParams';

import inject from './injector';
import { localStorage, sessionStorage } from './storage';

const location = inject('location');

const logout = (ref: string): void => {
  localStorage.clear();
  sessionStorage.clear();
  currentUser.authenticated = false;
  location.href = ref
    ? appendUrlParams(Rover.urls.logout, {
        ref,
      })
    : Rover.urls.logout;
};

function showAnonymousElements(): void {
  $('.js-anonymous-user').removeClass('hide');
}

function init(): void {
  currentUser
    .waitForAnonymousUserLoaded()
    .then(() => showAnonymousElements())
    .catch(() => {});
}

Rover.utils = Rover.utils || {};
Rover.utils.logout = logout;
const exportedAuth = {
  init,
  showAnonymousElements,
  logout,
};
export default exportedAuth;
