import $ from '@rover/globals/jquery';

$(() => {
  const handleCookieableClick = function() {
    let $elt = $(this),
      cookieName = $elt.attr('data-cookieable-name'),
      expires = parseInt($elt.attr('data-cookieable-expires'), 10) || 7;

    if (cookieName) {
      $.cookie(cookieName, true, { expires, path: '/' });
    }
  };

  $('body').on('click', '.js-cookieable', handleCookieableClick);
});
