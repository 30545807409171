import Backbone from '@rover/globals/backbone';
import $ from '@rover/globals/jquery';
import Rover from '@rover/globals/Rover';
import _ from '@rover/globals/underscore';
import { captureError } from '@rover/rsdk/src/modules/ErrorReporting';
import { isMobileBrowser } from '@rover/rsdk/src/modules/Network/userAgent';
import scrollToElement from '@rover/utilities/scrollToElement';
import i18n from '@rover/utilities/translation';

import ModalView from '../components/rover_modal';

Rover.views.QuizView = Backbone.View.extend({
  initialize() {
    _.bindAll(this, 'advanceQuestion', 'displayError', 'onQuestionSuccess', 'onQuestionError');
    const questions = this.getQuestions();
    const currentQuestion = this.getCurrentQuestion();
    if (isMobileBrowser()) {
      this.fixMobileTapDelay(currentQuestion);
    }
    const index = questions.index(currentQuestion);
    if (index === 0) {
      this.showIntroModal();
    } else if (index === -1) {
      // Quiz is done, show success view
      this.showQuizDone();
    }
    const self = this;
    $('.js-question-form').ajaxForm({
      beforeSubmit() {
        $('.js-form-error').hide();
      },
      success() {
        return self.onQuestionSuccess(this);
      },
      error(response) {
        return self.onQuestionError(this, response);
      },
    });
  },
  fixMobileTapDelay(currentQuestion) {
    // An ugly hack to prevent quick taps from failing to select
    // the quiz answer on embedded web
    currentQuestion.find('label').on('touchend', (e) => {
      e.stopPropagation();
      e.preventDefault();
      const input = $(e.currentTarget).find('input');
      input.click();
    });
  },
  onQuestionSuccess() {
    this.advanceQuestion();
  },
  onQuestionError(ajaxForm, response) {
    // If the user submitting the quiz answer is logged out redirect them to
    // the login page.
    if (response.status === 403) {
      window.location.assign(Rover.urls.signin);
    } else {
      const errorMessage =
        // eslint-disable-next-line camelcase
        response?.responseJSON?.error_description ||
        i18n._('Something went wrong with your request. Please try again later.');

      this.displayError(errorMessage);
    }
  },
  getQuestions() {
    return $('.js-quiz-question');
  },
  getCurrentQuestion() {
    return $(this.getQuestions().filter(':visible'));
  },
  getNextQuestion() {
    const questions = this.getQuestions();
    const index = questions.index(this.getCurrentQuestion());
    const question = questions[index + 1];
    if (question) {
      return $(question);
    }
    return null;
  },
  advanceQuestion() {
    const nextQuestion = this.getNextQuestion();
    if (nextQuestion) {
      const currentQuestion = this.getCurrentQuestion();
      currentQuestion.hide();
      nextQuestion.show();

      const contentModuleId = nextQuestion.attr('data-content-module-id');
      $('.js-content-module').hide();
      $(`.js-content-module[data-content-module-id="${contentModuleId}"]`).show();
    } else {
      this.showQuizDone();
    }
  },
  displayError(errorText) {
    const currentQuestion = this.getCurrentQuestion();
    currentQuestion.find('input[type=radio]').prop('checked', false);
    const $formErrorDiv = currentQuestion.find('.js-form-error');
    $formErrorDiv.html(errorText);
    $formErrorDiv.show();
    scrollToElement($formErrorDiv.selector);
  },
  showIntroModal() {
    // for the new SSU we are not going to show the initial modal
    if (Rover.flags.isNewSSU) {
      return;
    }
    const modal = new ModalView({
      title: $('.js-intro-modal-title').html(),
      content: $('.js-intro-modal-content').html(),
    });
    modal.open();
  },
  showQuizDone() {
    // Do not display done page from legacy SSU when in new SSU. Reload so that we see the new
    // "done" page.
    if (Rover.flags.isNewSSU) {
      // Triggering a POST request to trigger the save events. Then using
      // legacy logic to reload the page.
      $.ajax({
        type: 'POST',
        data: JSON.stringify({ quiz_completed: true }),
        contentType: 'application/json',
      })
        .success(() => window.location.reload())
        .fail((e) => captureError(e));
    } else {
      $('.js-rover-primary-col').hide();
      $('.js-rover-secondary-col').hide();
      $('.js-quiz-done').show();
    }
  },
});

$(() => {
  const $body = $('body');
  if (!$body.hasClass('js-safety-quiz')) {
    return;
  }
  new Rover.views.QuizView({}); // eslint-disable-line no-new
});
