import Rover from '@rover/globals/Rover';
import Backbone from '@rover/globals/backbone';

Rover.models.WeekendAvailability = Backbone.Model.extend({
  defaults: {
    week_monday: null,
    start_date: null,
    end_date: null,
    is_available: null,
    has_answered: null,
    strings: null,
  },
  isNew() {
    // Force a put request to the endpoint
    return false;
  },
  url() {
    return Rover.urls.weekendAvailability;
  },
  setAvailable(success, error) {
    return this.save({ is_available: true }, { success, error });
  },
  setUnavailable(success, error) {
    return this.save({ is_available: false }, { success, error });
  },
});
