import captureError from '../ErrorReporting/captureError';

const retry = <Value>(fn: () => Promise<Value>, retriesLeft = 5, interval = 1000): Promise<Value> =>
  new Promise((resolve, reject) =>
    fn()
      .then(resolve)
      .catch((error) => {
        if (retriesLeft === 0) {
          captureError(error);
          reject(error);
          return;
        }

        setTimeout(() => {
          // eslint-disable-next-line no-console
          console.info(`Retrying after ${interval}ms ... ${retriesLeft} retries left`);
          retry(fn, retriesLeft - 1, interval)
            .then(resolve, reject)
            .catch((e) => {
              captureError(e);
            });
        }, interval);
      })
  );

export default retry;
