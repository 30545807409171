import $ from '@rover/globals/jquery';
import Auth from '@rover/utilities/auth';
import Events from '@rover/utilities/events';

import Authenticated from './Authenticated';

$(() => {
  Auth.init();
  Authenticated.init();
  Events.init();
});
