/* globals FB */
import { t } from '@lingui/macro';

import currentUser from '@rover/globals/currentUser';
import $ from '@rover/globals/jquery';
import Mustache from '@rover/globals/Mustache';
import Rover from '@rover/globals/Rover';
import Alerts from '@rover/utilities/Alerts';
import i18n from '@rover/utilities/translation';

// Helper functions for Facebook calls
const FacebookUtils = {
  initializationHandlers: [],
  initialized: false,
  setUpFBAsyncInit(fbAppId = undefined) {
    // Facebook's API automatically calls this function once it has finished loading
    window.fbAsyncInit = () => {
      const { FB } = window;
      FB.init({
        appId: fbAppId ?? Rover.facebook.appId, // App ID
        version: 'v7.0',
        status: true, // check login status
        cookie: true, // enable cookies to allow the server to access the session
        xfbml: true, // parse XFBML
      });
      FB.Event.subscribe('edge.create', FacebookUtils.eventHandlers.handleFacebookLike);
      FB.Event.subscribe(
        'auth.authResponseChange',
        FacebookUtils.eventHandlers.handleFacebookAuthChange
      );
      FB.Event.subscribe('comment.create', FacebookUtils.eventHandlers.handleFacebookCommentCreate);
      // Show all FBui elements
      $('.FBui').show();
      FacebookUtils.initialized = true;
      FacebookUtils.initializationHandlers.forEach((h) => h());
    };
  },
  isInitialized() {
    return new Promise((resolve) => {
      return FacebookUtils.initialized
        ? resolve(window.FB)
        : FacebookUtils.initializationHandlers.push(() => resolve(window.FB));
    });
  },
  defaultFBSignupError() {
    const { customerSupportPhone } = Rover.context;
    alert(
      i18n._(t`We're sorry, we were unable to connect your Facebook account
          due to an error. If you would like immediate help visit our Help Center at
          support.rover.com, or call us at ${customerSupportPhone}`)
    );
  },
  defaultFBLoginError() {
    const { customerSupportPhone } = Rover.context;
    alert(
      i18n._(t`We're sorry, we were unable to authenticate with Facebook.
          If you would like immediate help visit our Help Center at
          support.rover.com, or call us at ${customerSupportPhone}`)
    );
  },
  connectAccount(authResponse, successCallback, errorCallback) {
    $.ajax({
      method: 'GET',
      url: Rover.facebook.authCallbackUrl,
      data: {
        facebook_id: authResponse.user_id,
        opk: currentUser?.opk,
      },
    })
      .done(() => {
        $.ajax({
          method: 'POST',
          url: Rover.facebook.authCallbackUrl,
          data: {
            token: authResponse.access_token,
            facebook_id: authResponse.user_id,
            purpose: 'connect',
          },
        })
          .done(successCallback)
          .fail(errorCallback);
      })
      .fail(errorCallback);
  },
  publishGranted(permissionData) {
    if (permissionData) {
      for (let i = 0; i < permissionData.length; i += 1) {
        const permObj = permissionData[i];
        if (permObj.permission === 'publish_actions' && permObj.status === 'granted') {
          return true;
        }
      }
    }
    return false;
  },
  sharePhotoError($form, response) {
    if (response.type === 'needs_connect') {
      $('.js-need-connect-to-share').removeClass('hide');
    } else {
      FacebookUtils.defaultFBLoginError();
    }
  },
  clickHandlers: {
    handleConnectButtonClick(e) {
      Rover.facebook.currentConnectButton = $(e.currentTarget);
      Rover.facebook.currentConnectButton.btn('loading');
      Rover.facebook.connectLocation =
        Rover.facebook.currentConnectButton.attr('data-connect-location');

      const resetButton = (success) => {
        Rover.facebook.currentConnectButton.btn('reset');

        if (success) {
          if (Rover.facebook.currentConnectButton.attr('data-hide-on-success')) {
            Rover.facebook.currentConnectButton.hide();
            if (Rover.facebook.currentConnectButton.parents('.js-connect-facebook-wrapper')) {
              Rover.facebook.currentConnectButton.parents('.js-connect-facebook-wrapper').hide();
            }
          }
          if (Rover.facebook.currentConnectButton.attr('data-show-success-message')) {
            Alerts.showAlert('success', 'Your account is now connected with Facebook!');
          }
          $(window).trigger('connectsuccess.facebook.rover');
        }
      };

      Rover.facebook.login(
        (authResponse) => {
          FacebookUtils.connectAccount(
            authResponse,
            () => {
              resetButton(true);
            },
            (xhr) => {
              let msg =
                'We were unable to connect your Facebook account.' + ' Please try again later.';
              if (parseInt(xhr.status, 10) === 409) {
                msg =
                  'Another user is already connected to this Facebook account.' +
                  ' Please try again with a different Facebook login.';
              }
              Alerts.showAlert('warning', msg);
              resetButton(false);
            }
          );
        },
        () => {
          resetButton(false);
        }
      );
    },
    handleFBuiClick(e) {
      const $elt = $(e.currentTarget);
      const linkUrl = $elt.attr('data-link');
      // NOTE: Do not specify the data-* attributes if you want to use the
      // OpenGraph tags on the page (which you should do)
      const data = {
        method: $elt.attr('data-method') || 'feed',
        link: $elt.attr('data-link'),
        href: $elt.attr('data-link'),
        name: $elt.attr('data-name'),
        picture: $elt.attr('data-picture'),
        source: $elt.attr('data-source'),
        caption: $elt.attr('data-caption'),
        description: $elt.attr('data-description'),
      };

      FacebookUtils.isInitialized().then((FB) => {
        FB.ui(data, (response) => {
          if (response) {
            const trackingEvent = $elt.attr('data-tracking-event') || 'share_review';

            let shareAction = 'share';
            if ($elt.hasClass('js-enter-contest')) {
              shareAction = 'contest-share';
            }

            Rover.trackSocial(
              'facebook',
              shareAction,
              $elt.attr('data-content-type'),
              $elt.attr('data-object-id')
            );
            Rover.postShareCallback('facebook', 'share');
          }
        });
      });
    },
  },
  eventHandlers: {
    handleFacebookLike(url, htmlElement) {
      Rover.postShareCallback('facebook', 'like');
    },
    handleFacebookCommentCreate(response) {
      $.post('{% url "marketing-comment-notify" %}', {
        link: response.href,
      });
    },
    handleFacebookAuthChange(response) {
      Rover.facebook.connected = response.status === 'connected';
      if (!Rover.facebook.connected) {
        $.removeCookie('longtermtoken');
        return;
      }
      // Only ask for a longterm token once a day
      if ($.cookie('longtermtoken')) {
        return;
      }
      $.cookie('longtermtoken', true, { expires: 1, path: '/' });
      const data = {
        token: response.authResponse.accessToken,
        facebook_id: response.authResponse.userID,
        purpose: 'refresh',
      };
      if (Rover.facebook.connectLocation) {
        data.connect_location = Rover.facebook.connectLocation;
      }
      // Ping our server to renew/get a long term access token (stored on FacebookInfo record)
      $.post(Rover.facebook.authCallbackUrl, data);
    },
  },
};

const TwitterUtils = {
  handleTwitterLoad() {
    // Set up twitter event tracking and post-share callbacks
    if (window.twttr) {
      window.twttr.ready((twitterObject) => {
        twitterObject.events.bind('follow', () => {
          Rover.postShareCallback('twitter', 'like');
        });
        twitterObject.events.bind('tweet', () => {
          Rover.postShareCallback('twitter', 'share');
        });
      });
    }
  },
  basicTwitterIntentSupport() {
    // @see - https://dev.twitter.com/web/intents
    if (window.twitterIntentHandler) return;
    const intentRegex = /twitter\.com(:\d{2,4})?\/intent\/(\w+)/;
    const windowOptions = 'scrollbars=yes,resizable=yes,toolbar=no,location=yes';
    const width = 550;
    const height = 420;
    const winHeight = screen.height;
    const winWidth = screen.width;

    function handleIntent(e) {
      const event = e || window.event;
      let target = event.target || event.srcElement;

      while (target && target.nodeName.toLowerCase() !== 'a') {
        target = target.parentNode;
      }

      if (target && target.nodeName.toLowerCase() === 'a' && target.href) {
        const m = target.href.match(intentRegex);
        if (m) {
          const left = Math.round(winWidth / 2 - width / 2);
          let top = 0;

          if (winHeight > height) {
            top = Math.round(winHeight / 2 - height / 2);
          }

          const o = `${windowOptions},width=${width},height=${height},left=${left},top=${top}`;
          window.open(target.href, 'intent', o);
          event.returnValue = false;
          if (event.preventDefault) {
            event.preventDefault();
          }
        }
      }
    }

    if (document.addEventListener) {
      document.addEventListener('click', handleIntent, false);
    } else if (document.attachEvent) {
      document.attachEvent('onclick', handleIntent);
    }
    window.twitterIntentHandler = true;
  },
};

const SocialUtils = {
  handlePinItClick() {
    const newwindow = window.open($(this).attr('href'), 'name', 'height=300,width=600');

    if (window.focus) {
      newwindow.focus();
    }

    Rover.postShareCallback('pinterest', 'share');
    return false;
  },
  loadSocialScripts() {
    // Load facebook SDK if FB elements present on page
    if (
      $('.FBui').length ||
      $('.js-connect').length ||
      $('.js-connect-facebook').length ||
      $('.js-facebook-sign-up').length ||
      $('.js-facebook-register').length ||
      $('.js-facebook-share-modal').length ||
      $('.js-shareable-image').length ||
      $('.post-on-facebook-button').length ||
      $('.fb-like').length
    ) {
      $.getScript('//connect.facebook.net/en_US/sdk.js');
    }
    // Load google plus if share button markup is present on page
    if ($('.g-plusone').length) {
      $.getScript('https://apis.google.com/js/plusone.js');
      // Configuration which is apparently required despite not being
      // documented anywhere.  Thanks, Google
      window.___gcfg = {
        lang: 'en-US',
      };
    }
    // Load twitter if share button markup is present on page
    // Otherwise just run a basic click handler for twitter intents
    if ($('.twitter-share-button').length || $('.js-twitter-custom-share').length) {
      $.getScript('//platform.twitter.com/widgets.js', TwitterUtils.handleTwitterLoad);
    } else {
      TwitterUtils.basicTwitterIntentSupport();
    }
  },
  handleSocialSharePhoto(e) {
    const $currentTarget = $(e.currentTarget);
    const network = $currentTarget.attr('data-network');
    const $wrapper = $currentTarget.parents('.js-sharing-wrapper');

    const imageToShare = $wrapper.attr('data-image-to-share');
    const imageThumb = $wrapper.attr('data-image-thumbnail');
    const contentType = $wrapper.attr('data-content-type');
    const objectId = $wrapper.attr('data-object-id');
    const campaign = $wrapper.attr('data-campaign');
    const trackingContent = $wrapper.attr('data-tracking-content');
    const shareLocation = $wrapper.attr('data-share-location');

    const utmParams = {
      utm_medium: 'onsite',
      utm_source: network,
      utm_campaign: campaign,
      utm_content: trackingContent,
    };
    const urlToShare = `${$wrapper.attr('data-url-to-share')}?${$.param(utmParams)}`;
    const href = imageToShare.includes('?')
      ? `${imageToShare}&${$.param(utmParams)}`
      : `${imageToShare}?${$.param(utmParams)}`;

    if (network === 'facebook') {
      FB.ui(
        {
          method: 'share',
          href,
        },
        (response) => {
          Rover.trackSocial('facebook', 'image-share', contentType, objectId);
        }
      );
    } else if (network === 'pinterest') {
      const popupWidth = 750;
      const popupHeight = 331;
      const left = screen.width / 2 - popupWidth / 2;
      const top = screen.height / 2 - popupHeight / 2;
      const popupOptions = `top=${top},left=${left},height=${popupHeight},width=${popupWidth},toolbar=no,location=no,directories=no,status=no,menubar=no,scrollbars=no,resizable=no`;

      const pinterestUrl = Mustache.template('new_design/social/pinterest_share_link').render({
        url: encodeURIComponent(urlToShare),
        imageUrl: encodeURIComponent(imageToShare),
      });
      window.open(pinterestUrl, 'Share on Pinterest!', popupOptions);
    }
  },
};

export { FacebookUtils, SocialUtils };
