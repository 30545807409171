import React from 'react';
import styled from 'styled-components';
import { border, compose, margin } from 'styled-system';

import { themeOverride } from '@rover/kibble/styles';

import { ImageCommonProps } from './Image.common';

type SrcsetReturnType = {
  src: string;
  srcSet?: string;
  sizes?: string;
};
export type SrcsetArray =
  | [string, string, string]
  | [null, string, string]
  | [string, null, string]
  | [string, string, null];

export type Props = ImageCommonProps & {
  eager?: boolean;
  src: string | SrcsetArray;
  sizes?: string | string[];
  srcsetWidths?: SrcsetArray;
};

const SRCSET_DEFAULT_WIDTHS = ['767w', '991w', '1440w'];
const BREAKPOINTS = themeOverride.breakpoints;

const getSrcset = (sources: SrcsetArray, srcsetWidths?: SrcsetArray): string => {
  const srcsetArray: string[] = [];
  for (let i = 0; i < sources.length; i += 1) {
    if (sources[i]) {
      srcsetArray.push(
        `${sources[i]} ${
          srcsetWidths && srcsetWidths[i] ? `${srcsetWidths[i]}w` : SRCSET_DEFAULT_WIDTHS[i]
        }`
      );
    }
  }
  return srcsetArray.join(', ');
};

const getSizes = (sizes: string | string[]): string => {
  if (Array.isArray(sizes)) {
    return sizes
      .map((size, index) =>
        index === sizes.length - 1 ? `${size}` : `(max-width: ${BREAKPOINTS[index]}) ${size}`
      )
      .join(', ');
  }
  return sizes;
};

const getLargestSrc = (sources: SrcsetArray): string => {
  const flattenedSources = sources.filter(Boolean) as string[];
  return flattenedSources[flattenedSources.length - 1] || '';
};

const getSrcOrSrcset = (
  src: string | SrcsetArray,
  sizes?: string | string[],
  srcsetWidths?: SrcsetArray
): SrcsetReturnType => {
  if (Array.isArray(src)) {
    return {
      src: getLargestSrc(src),
      srcSet: getSrcset(src, srcsetWidths),
      sizes: sizes ? getSizes(sizes) : '100vw',
    };
  }
  return { src };
};

type ImageComponentType = (props: Props) => JSX.Element;

const NOT_FORWARDED_PROPS = ['borderRadius', 'marginTop'];

const Image: ImageComponentType = styled(
  ({
    resizeMethod = 'cover',
    eager = false,
    description,
    src,
    sizes,
    srcsetWidths,
    onLoad,
    ...other
  }) => (
    <img
      {...other}
      {...getSrcOrSrcset(src, sizes, srcsetWidths)}
      alt={description}
      style={{
        // @ts-expect-error This is weirdly resolving a type from reactNativeApp? safe to ignore for now
        objectFit: resizeMethod !== 'center' ? resizeMethod : 'none',
        objectPosition: resizeMethod === 'center' ? resizeMethod : 'auto',
      }}
      loading={eager ? 'eager' : 'lazy'}
      onLoad={(event): void => {
        if (onLoad)
          onLoad({
            width: event.currentTarget.width,
            height: event.currentTarget.height,
            uri: event.currentTarget.src,
          });
      }}
    />
  )
).withConfig({
  shouldForwardProp: (prop) => !NOT_FORWARDED_PROPS.includes(prop),
})<Props>(compose(margin, border));

export default Image;
