import React from 'react';

import { Connect, Provider } from '@rover/react-lib/src/components/utils/Redux';
import {
  effects as currentUserEffects,
  reducer as currentUserReducer,
} from '@rover/react-lib/src/redux/ducks/currentUser.duck';
import {
  actions,
  effects as emailVerificationEffects,
  reducer as emailVerification,
  selectors,
} from '@rover/react-lib/src/redux/ducks/emailVerification.duck';

import EmailVerificationPage from './EmailVerificationPage';

const selector = (state) => ({
  isSubmitting: selectors.getIsSubmitting(state),
  verifyStatus: selectors.getVerifyStatus(state),
  resendStatus: selectors.getResendStatus(state),
  errorMessage: selectors.getErrorMessage(state),
});

const effect = {
  verifyEmail: emailVerificationEffects.verifyEmail,
  resendCode: emailVerificationEffects.resendCode,
  logoutCurrentUser: currentUserEffects.logoutCurrentUser,
};

const EmailVerificationPageContainer = (): JSX.Element => (
  <Provider reducers={{ emailVerification, currentUserReducer }}>
    <Connect actions={actions} selector={selector} effects={effect}>
      {(redux) => <EmailVerificationPage {...redux} />}
    </Connect>
  </Provider>
);

export default EmailVerificationPageContainer;
