import dataLayer from '@rover/globals/dataLayer';
import Rover from '@rover/globals/Rover';

const setUpAdminToolbar = (): void => {
  const startTime = new Date().getTime();
  // Assume GTM tag is fired and give it 2s to load. Hide if not loaded.
  const interval = setInterval(() => {
    const afterTwoSeconds = new Date().getTime() - startTime > 2000;
    if (window.ATL_JQ_PAGE_PROPS) {
      clearInterval(interval);
    }
    if (!window.ATL_JQ_PAGE_PROPS && afterTwoSeconds) {
      document.getElementById('jira-issue-collector')?.remove();
      clearInterval(interval);
    }
  }, 150);

  dataLayer.push({
    event: 'load-jira-issue-collector',
    component: Rover.context.pageTeam,
  });

  const hideDropdown = (): void => {
    const dropdown = document.querySelector('#toolbar-more-dropdown');
    if (!dropdown) return;
    dropdown.classList.add('hidden');
  };

  const showDropdown = (): void => {
    const dropdown = document.querySelector('#toolbar-more-dropdown');
    if (!dropdown) return;
    dropdown.classList.remove('hidden');
    (dropdown as HTMLElement).focus();
  };

  const toggleDropdown = (): void => {
    const dropdown = document.querySelector('#toolbar-more-dropdown');
    if (!dropdown) return;
    if (dropdown.classList.contains('hidden')) {
      showDropdown();
    } else {
      hideDropdown();
    }
  };

  document.addEventListener('DOMContentLoaded', () => {
    document.querySelector('#toolbar-more-button')?.addEventListener('click', () => {
      toggleDropdown();
    });

    document.addEventListener('click', (e) => {
      if (document.querySelector('#toolbar-more-dropdown')?.contains(e.target as Node)) {
        return;
      }
      if (document.querySelector('#toolbar-more-button')?.contains(e.target as Node)) {
        return;
      }
      hideDropdown();
    });
  });
};

if (Rover.context.person?.isStaff || Rover.context.person?.isImpersonating) {
  setUpAdminToolbar();
}
