import Backbone from '@rover/globals/backbone';

export default Backbone.Model.extend({
  defaults: {
    start_date: null,
    monday: false,
    tuesday: false,
    wednesday: false,
    thursday: false,
    friday: false,
    saturday: false,
    sunday: false,
  },
  reset() {
    this.set(this.defaults);
  },
});
