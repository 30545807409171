import $ from '@rover/globals/jquery';
import Rover from '@rover/globals/Rover';
import _ from '@rover/globals/underscore';
import triggerEmbeddedEvent from '@rover/utilities/embedded';

const MAX_RATING = 5;

$(() => {
  'use strict';

  let validator;

  const starRatingErrorPlacement = function ($error, $element) {
    if ($element.hasClass('starrating') || $element.hasClass('labeledstarrating')) {
      $element = $element.closest('.form-group');
    }
    if ($element.attr('name') !== 'description') {
      $.validator.defaults.errorPlacement($error, $element);
    }
  };

  /*
   * Implement custom handling to jump to the first invalid
   * form field, even if it's one of our hidden star inputs.
   */
  const starRatingInvalidHandler = function (ev, validator) {
    const $firstErrorElement = $(validator.errorList[0].element);

    if ($firstErrorElement.hasClass('starrating')) {
      let _top = $firstErrorElement.closest('.form-group').offset().top;
      // Add a bit of top because the wrapped control will be
      // covered otherwise
      _top -= 150;
      $('html, body').animate(
        {
          scrollTop: _top,
        },
        'fast'
      );
    }
    $.validator.defaults.invalidHandler(ev, validator);
  };

  const petRatingFormValidator = {
    ignore: [],
    errorPlacement: starRatingErrorPlacement,
    invalidHandler: starRatingInvalidHandler,
  };

  $.validator.addMethod('requireAtLeastOneField', (value, element, fields) => {
    const $form = $(element).closest('form');
    const nonEmptyFields = _.reduce(
      fields,
      (memo, value) => memo + ($form.find(`[name=${value}]`).val() ? 1 : 0),
      0
    );
    return nonEmptyFields > 0;
  });

  const sitterRatingFormValidator = {
    ignore: [],
    errorPlacement: starRatingErrorPlacement,
    invalidHandler: starRatingInvalidHandler,
    rules: {
      overall: {
        required: true,
      },
    },
    messages: {
      overall: 'A star rating is required.',
    },
  };

  const initializeFormValidation = function () {
    // Pet ratings form handler
    if ($('.js-rate-pets-form').length > 0) {
      validator = $('.js-rate-pets-form').roverValidate(petRatingFormValidator);
    } else {
      validator = $('.js-rate-sitter-form').roverValidate(sitterRatingFormValidator);
    }
  };

  const initPopovers = function () {
    Rover.stickyPopover($('[data-toggle="sticky-popover"]'), {
      container: 'body',
      closeOnBodyClick: false,
      trigger: 'click',
    });
  };

  const initializeRatings = function () {
    $('.js-star-rating-widget').each(function () {
      const $this = $(this);
      const overallInputName = $this.attr('data-uid');
      const $overallInput = $(`[name=${overallInputName}]`);
      const toggleContainer = $('.rating-form-toggle-container');

      $this.raty({
        starType: 'input',
        starOn: 'rover-icon rover-icon-in-star star-rating-on',
        starOff: 'rover-icon rover-icon-in-star-o star-rating-off',
        cancel: false,
        half: false,
        score() {
          return $overallInput.val();
        },
        click(score) {
          if (toggleContainer) {
            toggleContainer.show();
          }

          $overallInput.val(score);
          // Trigger revalidation on click if the input is in
          // an error state.
          if ($overallInput.hasClass('error')) {
            validator.validate().element($overallInput);
          }
        },
      });
    });
  };

  const initializePreferenceToggles = function () {
    const toggleContainer = $('.rating-form-toggle-container');
    if (toggleContainer) {
      toggleContainer.hide();
    }
  };

  const initializeReviewTracking = function () {
    const sitterReviewForm = $('.js-rate-sitter-form');
    if (sitterReviewForm.length > 0) {
      sitterReviewForm.submit(function (e) {
        const rating = $(e.target).find('#id_overall_stars > input:last-child').val();
        if (sitterReviewForm.valid()) {
          triggerEmbeddedEvent('sitter-reviewed-by-owner');
          if (Number(rating) === MAX_RATING) {
            triggerEmbeddedEvent('review-five-stars');
          } else if (Number(rating) === 4) {
            triggerEmbeddedEvent('review-four-stars');
          }
        }
        e.preventDefault();
      });
    }
  };

  const fiveStarPetReview = function () {
    const petReviewForm = $('.js-rate-pets-form ');
    if (petReviewForm.length > 0) {
      petReviewForm.submit(function (e) {
        if (petReviewForm.valid()) {
          const numberOfPets = $('.js-star-rating-widget').length;
          for (let i = 0; i < numberOfPets; i++) {
            const rating = $(e.target).find(`#id_form-${i}-overall_stars > input:last-child`).val();
            if (Number(rating) === MAX_RATING) {
              triggerEmbeddedEvent('review-five-stars');
              break;
            }
          }
        }
        e.preventDefault();
      });
    }
  };

  const init = function () {
    initializePreferenceToggles();
    initializeRatings();
    initializeFormValidation();
    initPopovers();
    initializeReviewTracking();
    fiveStarPetReview();
  };

  init();
});
