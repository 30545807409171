export enum EmailVerificationEvents {
  // Verify Email events
  VERIFY_EMAIL_LOAD = 'email-verification-verify-page-load',
  VERIFY_EMAIL_ADD_CODE = 'email-verification-verify-add-code',
  VERIFY_EMAIL_VERIFY_BUTTON_CLICK = 'email-verification-verify-button-click',
  VERIFY_EMAIL_RESEND_CODE_BUTTON_CLICK = 'email-verification-verify-resend-code-button-click',
  VERIFY_EMAIL_CHANGE_EMAIL_BUTTON_CLICK = 'email-verification-verify-change-email-button-click', // discontinued
  VERIFY_EMAIL_SKIP_BUTTON_CLICK = 'email-verification-verify-skip-button-click',
  VERIFY_EMAIL_LOGOUT_BUTTON_CLICK = 'email-verification-verify-logout-button-click',

  // Update Email events
  UPDATE_EMAIL_LOAD = 'email-verification-update-page-load',
  UPDATE_EMAIL_ADD_EMAIL = 'email-verification-update-add-email',
  UPDATE_EMAIL_SAVE_BUTTON_CLICK = 'email-verification-update-save-button-click',
  UPDATE_EMAIL_CANCEL_BUTTON_CLICK = 'email-verification-cancel-button-click',
}
