import { extraErrorDataIntegration, getCurrentScope, init } from '@sentry/react';

import currentUser from '@rover/globals/currentUser';

import sentryBrowserFilterOptions from './sentryBrowserFilterOptions';

const PROD_SENTRY_DSN = 'https://9c4df485116d4051adee5543193eeac3@sentry.io/24119';
const DEV_SENTRY_DSN = 'https://663da0655fda48dd9e79d5d4b0d9d6bd@sentry.io/23944';
const dsn = process.env.NODE_ENV === 'production' ? PROD_SENTRY_DSN : DEV_SENTRY_DSN;

init({
  dsn,
  integrations: [extraErrorDataIntegration()],
  ...sentryBrowserFilterOptions,
});

getCurrentScope().setTag('team', window.Rover?.context?.pageTeam || 'unknown');

currentUser
  .waitForAuthenticatedUserLoaded()
  // eslint-disable-next-line promise/always-return
  .then((user) => {
    getCurrentScope().setUser({
      id: user.id,
    });
  })
  .catch(() => {
    // Fail silently because it just means the user isn't authenticated
  });
