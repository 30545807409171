import $ from '@rover/globals/jquery';

const init = (): any => {
  ($ as any).validator.addMethod(
    'creditcard_num',
    (value) => $.payment.validateCardNumber(value),
    'Enter a valid credit card number'
  );
  ($ as any).validator.addMethod(
    'creditcard_type',
    (value) => {
      const validCardTypes = [
        'visa',
        'mastercard',
        'amex',
        'discover',
        'visaelectron',
        'unionpay',
        'dinersclub',
        'jcb',
      ];
      const cardType = $.payment.cardType(value);
      return validCardTypes.indexOf(cardType) !== -1;
    },
    'Accepted card types are: AmEx, Discover, Visa, UnionPay, JCB, Diners Club, or MasterCard'
  );
  ($ as any).validator.addMethod(
    'creditcard_cvv',
    (value, element, ccType) => {
      if (ccType) return $.payment.validateCardCVC(value, ccType);
      return $.payment.validateCardCVC(value);
    },
    'Enter a valid CVV'
  );
  ($ as any).validator.addMethod(
    'creditcard_expiration',
    (value) => {
      const expiryVal = $.payment.cardExpiryVal(value);
      return $.payment.validateCardExpiry(expiryVal.month.toString(), expiryVal.year.toString());
    },
    'Enter a valid expiration date'
  );
  ($ as any).validator.setDefaults({
    showErrors(errorMap, errorList) {
      if (typeof errorList[0] !== 'undefined') {
        let $el: any = $(errorList[0].element);

        if ($el.hasClass('js-form-group-error-placement')) {
          $el = $el.closest('.form-group');
        }

        if (this.settings.focusInvalid) {
          if ($el.is(':visible')) {
            const position = $el.offset().top - 50;
            $('html, body').stop().animate(
              {
                scrollTop: position,
              },
              300
            );
          }
        }
      }

      this.defaultShowErrors();
    },
  });
};

export const getValidationParams = (ignore = []): any => ({
  ignore,

  errorPlacement($error, $element) {
    let $el = $element;

    if (!$el.is(':visible')) {
      // TODO: see https://roverdotcom.atlassian.net/browse/FE-1519
      if ($el.hasClass('chosen-select')) {
        $el = $el.siblings('.chosen-container');
      } else if ($el.closest('.form-group').find('.validation-hook').length === 1) {
        // Use the 'validation-hook' class to provide a specific place in the DOM to attach the validation popover
        $el = $el.closest('.form-group').find('.validation-hook');
      } else {
        $el = $el.closest('.form-group');
      }
    }

    if ($el.is(':visible')) {
      ($ as any).validator.defaults.errorPlacement($error, $el);
    }
  },

  // TODO: see https://roverdotcom.atlassian.net/browse/FE-1519
  invalidHandler(e, validator) {
    const $firstErrorEl = $(validator.errorList[0].element);

    if ($firstErrorEl.hasClass('chosen-select')) {
      const $scrollTarget: any = $firstErrorEl.closest('.form-group');
      $('html, body').animate(
        {
          scrollTop: $scrollTarget.offset().top,
        },
        'fast'
      );
    }

    ($ as any).validator.defaults.invalidHandler(e, validator);
  },
});

const exportedValidation = {
  init,
  getValidationParams,
};

export default exportedValidation;
