import dataLayer from '@rover/globals/dataLayer';
import $ from '@rover/globals/jquery';
import Rover from '@rover/globals/Rover';
import _ from '@rover/globals/underscore';
import { captureError } from '@rover/rsdk/src/modules/ErrorReporting';
import type { DataLayerEvent } from '@rover/types';
import fireCrossPageEvent from '@rover/utilities/fireCrossPageEvent';
import fireDataLayerEvent from '@rover/utilities/fireDataLayerEvent';

Rover.utils = Rover.utils || {};
let hasInitialized = false;

function initializeGATrackingEvents() {
  Rover.utils.initGAEvents(document);
}

function init() {
  /**
   * Set up safe to use scroll and resize events
   * @see - http://ejohn.org/blog/learning-from-twitter/
   */
  if (hasInitialized) {
    return;
  }

  hasInitialized = true;
  const $window = $(window);
  // Fire scroll event max once every 150ms
  $window.on(
    'scroll',
    _.throttle(() => {
      $window.trigger('safeScroll');
    }, 75)
  );
  // Fire resize event only after resizing has stopped for 250ms
  $window.on(
    'resize',
    _.debounce(() => {
      $window.trigger('safeResize');
    }, 250)
  );
  initializeGATrackingEvents();
}

function recordClickEventHandler(this: any) {
  const eventName = $(this).attr('data-record-click-event');

  if (eventName) {
    dataLayer.push({
      event: 'record-event',
      eventName,
    });
  }
}

export const makeEventFromElementDataset = (dataset: any): DataLayerEvent => {
  let eventMerge = {};

  if (dataset.eventMerge) {
    try {
      eventMerge = JSON.parse(dataset.eventMerge);
    } catch (err) {
      captureError(new Error(`Data Event Merge Error: ${err}`));
    }
  }
  return _.extend({ event: dataset.event }, eventMerge);
};

const handleDataEvent = (dataset: any): void => {
  const event = makeEventFromElementDataset(dataset);
  fireDataLayerEvent(event);
};

const handleNavigationEvent = (dataset: any): void => {
  const event = makeEventFromElementDataset(dataset);
  fireCrossPageEvent(`data-events-navigation: ${event.event}`, event);
};

Rover.utils.initGAEvents = (parentEl): void => {
  if (_.isUndefined(parentEl) || $(parentEl).length === 0) {
    return;
  }

  const parent = $(parentEl)[0]; // allow both normal and jquery elements

  // search for all data-track-event dom elements and register their events
  const events = parent.querySelectorAll('[data-track-event]');
  [].forEach.call(events, (el: any) => {
    const { dataset } = el;

    // For "on load" events, fire once as soon as the element appears on the page
    if (dataset?.eventType === 'load') {
      handleDataEvent(dataset);
      return;
    }

    // For "navigation-click" events, save event to local storage and fire on next page load
    if (dataset?.eventType === 'navigation-click') {
      $(el).on('click', () => {
        handleNavigationEvent(dataset);
      });
      return;
    }

    // For all other events, fire on the specified event type
    $(el).on(dataset.eventType, () => handleDataEvent(dataset));
  });
};

const exportedEvents = {
  init,
  recordClickEventHandler,
  initializeGATrackingEvents,
};
export default exportedEvents;
