import { t } from '@lingui/macro';

import $ from '@rover/globals/jquery';
import moment from '@rover/globals/moment';
import Rover from '@rover/globals/Rover';
import _ from '@rover/globals/underscore';
// WARNING: DF from datetimeInjected is not SSR compatible.
// For newer code, use const DF = getDateTimeFormatMapForLang(<lang>) from '@rover/shared/js/constants/i18n/datetime'
import DF from '@rover/shared/js/constants/i18n/datetime/datetimeInjected';
import getConversationOpk from '@rover/utilities/conversation';
import inject from '@rover/utilities/injector';
import getPersonOpk from '@rover/utilities/person';
import i18n from '@rover/utilities/translation';

import RequestableModel from './requestable';

const personOpk = inject('Rover.context.person.opk', '');

const ConversationModel = RequestableModel.extend({
  defaults: {
    dates: [],
  },
  initialize() {
    this.on(
      'change:dates',
      _.bind(() => {
        this.trigger('datesChanged');
      }, this)
    );
  },
  getOpk() {
    return getConversationOpk(this.get('url'));
  },
  getProviderOpk() {
    return getPersonOpk(this.get('provider').url);
  },
  getServiceType() {
    return this.get('_service_type');
  },
  getRelationship() {
    return this.get('relationship');
  },
  getDatesData() {
    return {
      start_date: this.get('start_date'),
      end_date: this.get('end_date'),
      dates: this.get('dates'),
    };
  },
  isEndDateExclusive() {
    const serviceType = this.getServiceType();
    if (!serviceType) {
      return true;
    }
    if (serviceType.is_end_date_exclusive === false) {
      return false;
    }
    return true;
  },
  supportsNonContiguousDates() {
    const serviceType = this.getServiceType();
    return !!serviceType && serviceType.supports_non_contiguous_dates;
  },
  supportsNumUnitsPerDay() {
    const serviceType = this.getServiceType();
    return !!serviceType && serviceType.supports_num_units_per_day;
  },
  requiresDates() {
    const serviceType = this.getServiceType();
    return !!serviceType && !serviceType.supports_start_end_date;
  },
  unitsAndDatesAsSentence() {
    let out = '';
    if (this.supportsNumUnitsPerDay()) {
      const numUnitsPerDay = this.getNumUnitsPerDay();
      out = i18n.plural({
        value: numUnitsPerDay,
        one: '# time per day',
        other: '# times per day',
      });
      out += ' ';
    }
    if (!this.supportsNonContiguousDates()) {
      const start = moment(this.get('start_date')).format(this.dateDisplayFormat);
      const end = moment(this.get('end_date')).format(this.dateDisplayFormat);
      out += i18n._(t`from ${start} to ${end}`);
    } else {
      out += i18n._(t`on ${this.datesList().join(', ')}`);
    }
    return out;
  },
  dropOff() {
    let out = '';

    if (this.supportsNumUnitsPerDay()) {
      return this.unitsAndDatesAsSentence();
    }
    if (!this.supportsNonContiguousDates()) {
      const start = moment(this.get('start_date')).format(DF.MONTH_DAY_WEEK_MED);
      out += i18n._(t`Drop off: ${start}`);
    } else {
      out += i18n._(t`on ${this.datesList().join(', ')}`);
    }
    return out;
  },
  from() {
    const start = moment(this.get('start_date')).format(DF.MONTH_DAY_WEEK_MED);
    return i18n._(t`From: ${start}`);
  },
  to() {
    const end = moment(this.get('end_date')).format(DF.MONTH_DAY_WEEK_MED);
    return i18n._(t`To: ${end}`);
  },
  pickUp() {
    if (this.supportsNonContiguousDates()) {
      return '';
    }

    const end = moment(this.get('end_date')).format(DF.MONTH_DAY_WEEK_MED);
    const out = i18n._(t`Pick up: ${end}`);
    return out;
  },
  datesList(format) {
    const dateFormat = format || this.dateDisplayFormat;
    return this.get('dates').map((date) => moment(date.date).format(dateFormat));
  },
  isOvernightBoarding() {
    return this.get('service_type') === 'overnight-boarding';
  },
  isOvernightTraveling() {
    return this.get('service_type') === 'overnight-traveling';
  },
  isDoggyDayCare() {
    return this.get('service_type') === 'doggy-day-care';
  },
  isDropIn() {
    return this.get('service_type') === 'drop-in';
  },
  isDogWalking() {
    return this.get('service_type') === 'dog-walking';
  },
  getDuration() {
    const startDate = moment(this.get('start_date'));
    const endDate = moment(this.get('end_date'));
    let endDateExclusive = null;

    if (this.supportsNonContiguousDates()) {
      return this.get('dates').length;
    }
    if (!this.isEndDateExclusive()) {
      endDateExclusive = endDate.add(1, 'days');
    } else {
      endDateExclusive = endDate;
    }

    return Math.max(1, endDateExclusive.diff(startDate, 'days'));
  },
  getNumUnitsPerDay() {
    const dates = this.get('dates');

    if (!this.supportsNumUnitsPerDay() || dates.length === 0) {
      return 1;
    }

    return dates[0].num_units;
  },
  saveNumUnitsPerDay(numUnitsPerDay, optimisticAttrUpdate) {
    const newDates = this.get('dates').map((date) => ({
      date: date.date,
      num_units: numUnitsPerDay,
    }));

    return this.saveDraftPricing({ dates: newDates }, optimisticAttrUpdate);
  },
  saveDates(dates, optimisticAttrUpdate) {
    const numUnitsToSet = this.getNumUnitsPerDay();

    if (!this.requiresDates()) {
      return null;
    }

    const newDates = dates.map((date) => ({
      date,
      num_units: numUnitsToSet,
    }));

    return this.saveDraftPricing({ dates: newDates }, optimisticAttrUpdate);
  },
  saveStartAndEndDate(startDate, endDate, optimisticAttrUpdate) {
    if (this.supportsNonContiguousDates()) {
      return null;
    }

    if (this.supportsNumUnitsPerDay()) {
      return this.saveDates(this.buildDerivedDates(startDate, endDate), optimisticAttrUpdate);
    }
    return this.saveDraftPricing(
      { start_date: startDate, end_date: endDate },
      optimisticAttrUpdate
    );
  },
  buildDerivedDates(startDate, endDate) {
    const { dateFormat } = this;
    const startDateM = moment(startDate, dateFormat);
    const endDateM = moment(endDate, dateFormat);
    const dates = [startDate];
    const diff = endDateM.diff(startDateM, 'days');

    // start_date and end_date are equal
    if (diff === 0) {
      return dates;
    }

    for (let i = 1; i <= diff; i += 1) {
      dates.push(moment(startDate, dateFormat).add(i, 'days').format(dateFormat));
    }

    return dates;
  },
  save(attrs, options) {
    // We NEVER want to set the provider_archived_status when saving because
    // if we do, we're telling the api that this is an archiving operation
    // Write your own archiving operation, if you wish.
    this.unset('provider_archived_status', { silent: true });

    return RequestableModel.prototype.save.call(this, attrs, options);
  },
  shadowSave(attributes) {
    // For some operations (i.e. create request, cancel request) the API
    // expects the client to set some model attributes to specific values,
    // call PUT, then it will return the model with updated attributes.
    //
    // There may be many Views listening to attributes on this model. If
    // we have to change one of those attributes to facilitate the the
    // specific API operation (like 'request') then some listeners may
    // be triggered even though we didn't set that attribute to a normal
    // state.
    //
    // To work around this trouble, we're creating a copy of the model
    // which includes all of that model's attributes, saving it, and
    // when that save succeeds, updating the original with the new
    // attributes.
    //
    // Alternatives to this approach include:
    // 1. Use this.set(attributes, {silent: true}) which will suppress
    //    triggers. If the subsequent save fails, however, the model
    //    could be left in an invalid state. Also, the Backbone docs
    //    say that you should probably never use {silent: True}
    // 2. Put a property on the Conversation model so that it can track
    //    when this save is in flight. All listeners on request should
    //    ignore changes while it is in flight. Once the save completes,
    //    disable the flag.

    const currentAttributes = this.attributes;
    const self = this;
    const shadowAttributes = { ...currentAttributes, ...attributes };
    const shadow = new ConversationModel(shadowAttributes);
    shadow.set('booking_add_ons', shadow.getActiveBookingAddOns());
    return shadow.save().then(
      (data) => {
        self.set(self.parse(data));
      },
      (err) => {
        let confirmation;
        if (err.status === 409) {
          // eslint-disable-next-line no-alert, no-restricted-globals
          confirmation = confirm(
            i18n._(
              'This request has been updated since you loaded this page. Press OK to see up-to-date details.'
            )
          );
        }

        if (confirmation) {
          // eslint-disable-next-line no-restricted-globals
          location.reload();
        }
        return err;
      }
    );
  },
  getLockedAddOns() {
    if (this.isRecurring) {
      return Promise.resolve([]);
    }

    if (personOpk !== this.getProviderOpk()) {
      return Promise.resolve([]);
    }

    if (this.get('locked_service_add_ons') !== undefined) {
      return Promise.resolve(this.get('locked_service_add_ons'));
    }

    return new Promise((resolve, reject) => {
      $.ajax({
        method: 'GET',
        url: this.get('urls').locked_service_add_on_url,
        success: (data) => {
          this.set({
            locked_service_add_ons: data.locked_service_add_ons,
          });
          resolve(data.locked_service_add_ons);
        },
        error: (err) => {
          reject(err);
        },
      });
    });
  },
  getLockedAddOnBySlug(slug) {
    return this.getLockedAddOns().then((lockedAddOns) => {
      return _.find(lockedAddOns, (lao) => lao.slug === slug);
    });
  },
  hasLockedAddOns() {
    return this.getLockedAddOns().then(lockedAddOns => {
      return (lockedAddOns || []).length > 0;
    }).catch(() => {
      return false;
    });
  },
  calculateNewLockedAddOns(bookingAddOnsOverwrite = []) {
    const bookingAddOns =
      bookingAddOnsOverwrite.length === 0 ? this.get('booking_add_ons') : bookingAddOnsOverwrite;

    return $.ajax({
      method: 'POST',
      url: this.get('urls').locked_service_add_on_calculator_url,
      data: JSON.stringify({
        conversation: this.get('url'),
        booking_add_ons: bookingAddOns,
      }),
      contentType: 'application/json',
    });
  },
  lockRates() {
    return this.calculateNewLockedAddOns().then((data) => {
      const request = {
        locked_service_add_ons: data.locked_service_add_ons,
      };
      $.ajax({
        method: 'POST',
        url: this.get('urls').locked_service_add_on_url,
        data: JSON.stringify(request),
        contentType: 'application/json',
      }).then((data) => {
        this.set({
          locked_service_add_ons: data,
        });
      });
    });
  },
  unlockRates() {
    return $.ajax({
      method: 'DELETE',
      url: this.get('urls').locked_service_add_on_url,
      contentType: 'application/json',
    }).then(() => {
      this.set({
        locked_service_add_ons: [],
      });
    });
  },
  getDraftPricing(options) {
    const uri = this.get('urls').pricing;
    let request = {
      service: this.get('service'),
      requester: this.get('requester').url,
      pets: _.pluck(this.get('pets'), 'url'),
      booking_add_ons: this.get('booking_add_ons'),
    };

    if (this.requiresDates()) {
      request.dates = this.get('dates');
    } else {
      request.start_date = this.get('start_date');
      request.end_date = this.get('end_date');
    }

    request = _.extend(request, options);

    return $.ajax({
      method: 'POST',
      url: uri,
      data: JSON.stringify(request),
      contentType: 'application/json',
    });
  },
  getPerDaySummary() {
    const numUnitsPerDay = this.getNumUnitsPerDay();
    const serviceType = this.getServiceType();
    let serviceTypeUnit = serviceType.alternative_rate_unit_translated;
    if (numUnitsPerDay > 1) {
      serviceTypeUnit = serviceType.alternative_rate_unit_plural;
    }

    if (serviceType.is_daytime_traveling) {
      const longAddOns = ['long-walk', 'long-drop-in'];
      const minCount =
        this.attributes.booking_add_ons.filter((sao) => longAddOns.includes(sao.slug)).length > 0
          ? 60
          : 30;
      if (this.attributes.dates.length > 1) {
        return i18n._(
          /* i18n: {0} will be num units per day, {1} will be num of minutes */
          t`${numUnitsPerDay} per day • ${minCount} min`
        );
      }
      return i18n._(
        /* i18n: {0} will be num units per day, {1} will be unit of service (walks/visits/etc), {2} will be num of minutes */
        t`${numUnitsPerDay} ${serviceTypeUnit} • ${minCount} min`
      );
    }

    if (this.attributes.dates.length > 1) {
      return i18n._(
        /* i18n: {0} will be num units per day */
        t`${numUnitsPerDay} per day`
      );
    }
    return i18n._(
      /* i18n: {0} will be num units per day, {1} will be unit of service (walks/visits/etc) */
      t`${numUnitsPerDay} ${serviceTypeUnit}`
    );
  },
});

export default ConversationModel;
