import Backbone from '@rover/globals/backbone';
import i18n from '@rover/utilities/translation';
import _ from '@rover/globals/underscore';
import { t } from '@lingui/macro';
import { SERVICE_ADD_ON_UNITS } from '@rover/shared/js/constants/service';

export default Backbone.Model.extend({
  supports_num_units_per_day() {
    return this.get('service_type').supports_num_units_per_day;
  },
  supports_recurring_billing() {
    return this.get('service_type').supports_recurring_billing;
  },
  is_end_date_exclusive() {
    return this.get('service_type').is_end_date_exclusive;
  },
  num_units_label() {
    return i18n._(t`How many ${this.num_units_description(999)}?`);
  },
  isHosting() {
    return this.get('service_type').location === 'provider_home';
  },
  isSDV() {
    return this.get('service_type').supports_service_delivery_verification;
  },
  units() {
    if (this.get('slug') === 'doggy-day-care') return 'day care';
    return i18n._(SERVICE_ADD_ON_UNITS[this.standard_rate_add_on().unit](999));
  },
  unit() {
    return i18n._(SERVICE_ADD_ON_UNITS[this.standard_rate_add_on().unit](1));
  },
  num_units_description(numUnits = 1) {
    return i18n._(
      t`${i18n._(SERVICE_ADD_ON_UNITS[this.standard_rate_add_on().unit](numUnits))} per day`
    );
  },
  standard_rate_add_on() {
    let srao = {};

    _.each(this.get('service_add_ons'), o => {
      if (o.slug === 'standard-rate') {
        srao = o;
      }
    });

    return srao;
  },
});
