import Alerts from '@rover/utilities/Alerts';
import Rover from '@rover/globals/Rover';
import Backbone from '@rover/globals/backbone';
import Mustache from '@rover/globals/Mustache';
import _ from '@rover/globals/underscore';
import $ from '@rover/globals/jquery';
import { apiUrl } from '@rover/utilities/apiUrl';

import ServiceButtonGroupView from './service_button_group_view';
import ServiceButtonGroupCollection from '../collections/service_button_group_collection';
import ServiceButtonGroupModel from '../models/service_button_group_model';
import currentUser from '@rover/globals/currentUser';
import { trapFocusWithinElement, removeFocusTrap } from '@rover/utilities/modals';

import { initIntlPhoneInput } from '@rover/react-lib/src/components/formFields/IntlPhoneInput/IntlPhoneInputUtils';
import '../models/dogs';

Rover.views = Rover.views || {};

Rover.views.RebookDialogView = Backbone.View.extend({
  /*
     *  Class variables
     */
  userPets: null,
  template: Mustache.template('js/app/stays/rebook'),
  $form: null,
  sender: null,
  receiver: null,
  service_url: null,
  person: currentUser,
  isSubmitting: false,

  /*
     *  Initialization functions
     */

  initialize(options) {
    _.bindAll(
      this,
      'preRenderSetup',
      'render',
      'getContext',
      '_fetchUserPets',
      'cancel',
      'submit',
      'conversationCreated'
    );

    const browsable_services = options.services.filter(s => s.service_type.browsable);

    this.services = new ServiceButtonGroupCollection(
      browsable_services.map(s => new ServiceButtonGroupModel(s))
    );

    this.userPets = new Rover.collections.DogCollection();

    // Pre-setup
    const that = this;
    this.options = options;
    this.url = options.url;
    this.selected_service = options.selected_service;
    this.sender = options.sender;
    this.receiver = options.receiver;
    this.provider = options.provider;
    this.requester = options.requester;
    this.service_url = options.service_url;
    this.extraContext = options.extraContext;
    this.preRenderSetup().done(() => {
      that.render();
    });
  },

  initServiceButtonGroupView() {
    return new ServiceButtonGroupView({
      services: {
        el: this.$('.js-service-selection'),
      },
      dates: {
        el: this.$('.stay-duration'),
        disableStart: !!this.getContext().startDate,
        startDate: this.getContext().startDate,
      },
      collection: this.services,
    }).render();
  },

  preRenderSetup() {
    const petRequests = this._fetchUserPets();
    return $.when(...petRequests);
  },

  postRenderSetup() {
    this.$form = this.$el.find('.js-rebook-form');
    this.$form.validate({
      ignore: ':hidden:not(.js-validate-element),' + '.js-service-dates-spec.hidden input',
    });

    const context = this.getContext();
    if (context.needsPhoneNumber) {
      this.$el.find('.js-phone-number-input').each(function() {
        initIntlPhoneInput(
          this,
          {
            countryCode: Rover.context.countryCode,
            locale: Rover.context.language,
            useHiddenInput: true,
          }
        );
      });
    }
  },

  _fetchUserPets() {
    const that = this;
    _.each(this.requester.pets, pet => {
      if (pet.active) {
        that.userPets.add(new Rover.models.Dog(pet));
      }
    });
    return [];
  },

  render() {
    const context = this.getContext();
    this.$el.html(this.template.render(context));
    this.previouslyActiveElement = document.activeElement;
    this.postRenderSetup();
    $('.modal-backdrop').remove();
    $(this.el)
      .find('.js-rebook-modal')
      .modal();
    this.tabListener = trapFocusWithinElement(this.$el);
    this.serviceView = this.initServiceButtonGroupView({
      startDate: context.startDate,
    });

    if (this.services.getBySlug(this.selected_service)) {
      this.services.setSelected(this.selected_service);
    } else {
      this.services.setSelected(this.services.at(0).get('slug'));
    }
  },

  getContext() {
    const pets = this.userPets.toJSON();
    pets[pets.length - 1].last = true;
    const context = {
      provider: this.provider,
      requester: this.requester,
      sender: this.sender,
      receiver: this.receiver,
      pets,
      service_url: this.service_url,
      action: this.url || apiUrl('conversations/'),
      needsPhoneNumber: this.sender.private.has_phone_number === 'False',
    };

    const services = this.services.toJSON();
    if (services.length > 1) {
      context.services = services;
      this.setServiceColWidth(context);
    }
    _.each(services, service => {
      const p = service.service_type.name.split(/\s+/);
      service.top_name = p[0];
      p.splice(0, 1);
      service.bottom_name = p.join(' ');
    });
    $.extend(context, this.extraContext || {});
    return context;
  },

  setServiceColWidth(context) {
    context.col_sm = Math.floor(12 / this.services.length);
    if (context.col_sm === 2) {
      context.col_sm = 15;
    }
  },

  cancel() {
    if (this.tabListener) removeFocusTrap(this.tabListener);
    $(this.el)
      .find('.js-rebook-modal')
      .modal('hide');
    if (this.previouslyActiveElement) this.previouslyActiveElement.focus();
  },

  submit() {
    // logic to prevent the rare case of double submission
    if (this.isSubmitting) {
      return false;
    }
    this.isSubmitting = true;
    try {
      this._ajaxSubmit();
    } catch (err) {
      // TODO - Consider sending this to Sentry
      this.isSubmitting = false;
      console.log(err);
      Alerts.showAlert('extending your stay');
    } finally {
      // We need to always prevent form submission since it will be done
      // by the browser and will return an error page from the
      // Browseable API (due to missing CSRF, which is required if you
      // send the request as application/x-www-form-urlencoded)
      return false;
    }
  },

  getPostData() {
    const context = this.getContext();
    let formData = this.$form.serializeObject(),
      data = {};
    data.pets = formData.pets || [];
    if (!data.pets.push) {
      data.pets = [data.pets];
    }
    data.content = formData.content;
    data.provider = formData.provider;
    data.requester = formData.requester;
    data.sender = formData.sender;
    data.source = 'web';
    data.service = this.services.getSelected().get('url');
    if (context.needsPhoneNumber) {
      data.phone_number = formData.phone_number;
    }
    data = _.extend(data, this.serviceView.getDatesData());
    return data;
  },

  _ajaxSubmit() {
    let data = this.getPostData(),
      that = this;
    this.$('.js-submit-rebook-request').btn('loading');

    $.ajax({
      url: this.serviceView.getContactUrl(),
      type: 'POST',
      data: JSON.stringify(data),
      contentType: 'application/json',
      success: that.conversationCreated,
      error(xhr) {
        const validator = that.$form.validate({
          ignore: [':hidden:not(.js-validate-element)'],
          errorPlacement($error, $element) {
            if ($element.hasClass('js-form-group-error-placement')) {
              $element = $element.closest('.form-group');
            }
            $.validator.defaults.errorPlacement($error, $element);
          },
        });
        validator.showErrors(xhr.responseJSON.error);
        that.$('.js-submit-rebook-request').btn('reset');
      },
    });
  },

  conversationCreated(conversation) {
    this.isSubmitting = false;
    window.location = `${conversation.web_url}?is_rebook`;
  },

  events: {
    'click .js-cancel': 'cancel',
    'click .js-modal-close': 'cancel',
    'submit .js-rebook-form': 'submit',
  },
});

// This view and its associated template (extend.mustache) appear to be unreachable.
// The view is referenced in conversations.js, but the click trigger (.js-show-rebook)
// is preempted by the same trigger in stays/past.js.
// Not removing it because this precedence could be due to the sometimes arbitrary order
// in which Webpack bundles files.
Rover.views.ExtendDialogView = Rover.views.RebookDialogView.extend({
  template: Mustache.template('js/app/stays/extend'),
  getPostData() {
    let data = Rover.views.RebookDialogView.prototype.getPostData.call(this),
      formData = this.$form.serializeObject();
    data.extended_stay_conversation = 1;
    data.original_conversation = formData.original_conversation;
    return data;
  },
});
