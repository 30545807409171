// Cross page events are events that are fired immediately before a page transition,
// for example when a "Submit" button is pressed. The danger is that either the
// page transition will be delayed by waiting on the event network call, or
// if the event is not awaited and the page tears itself down, the network call may
// not finish causing events to never be registered.

// Instead, the `fireCrossPageEvent` function puts an event into the browser's
// localStorage, then the `dispatchCrossPageEvents` function loads those events
// on the next page load (of any page) and calls `dataLayer.push`, making the event
// network call at that time.

import { analyticsEventToDataLayerEvent } from '@rover/rsdk/src/modules/Analytics';
import type { AnalyticsEvent } from '@rover/types/src/Analytics';
import { localStorage } from '@rover/utilities/storage';

import fireDataLayerEvent from './fireDataLayerEvent';

const CROSS_PAGE_EVENTS_NAMESPACE = 'cross-page-event:';

// This function should be called once on every page load to dispatch events that were queued on the previous page
export const dispatchCrossPageEvents = async (): Promise<void> => {
  const dataLayerPromises: Array<Promise<void>> = [];

  if (!localStorage) return;

  for (let i = 0; i < localStorage.length; i += 1) {
    const key = localStorage.key(i);
    if (!key?.startsWith(CROSS_PAGE_EVENTS_NAMESPACE)) continue; // eslint-disable-line no-continue

    const event = localStorage.getItem(key);
    if (!event) continue; // eslint-disable-line no-continue
    const storedCrossPageEvent = JSON.parse(event);
    localStorage.removeItem(key);

    if (storedCrossPageEvent) dataLayerPromises.push(fireDataLayerEvent(storedCrossPageEvent));
  }

  await Promise.all(dataLayerPromises);
};

// Use this function to queue up a cross page event, as a replacement for dataLayer.push
const fireCrossPageEvent = (key: string, event: Record<string, unknown>): void => {
  if (!localStorage) return;
  localStorage.setItem(`${CROSS_PAGE_EVENTS_NAMESPACE}${key}`, JSON.stringify(event));
};

/**
 * This function uses the "store" verbiage instead of "fire" because it more accurately
 * describes what the fireCrossPageEvent function is actually doing.
 */
export const storeCrossPageAnalyticsEvent = (analyticsEvent: AnalyticsEvent): void => {
  const dataLayerEvent = analyticsEventToDataLayerEvent(analyticsEvent);
  fireCrossPageEvent(analyticsEvent.eventName, dataLayerEvent);
};

export default fireCrossPageEvent;
