import httpContext from '@rover/rsdk/src/modules/Network/httpContext';
import { Http301Error } from '@rover/utilities/HttpError';

export { default as getQueryParams } from './getQueryParams';

export const addEventParamsToUrl = (
  url: string,
  gtmEventParameters: Array<{ [key: string]: string }>
): string => {
  let newUrl = url;

  if (gtmEventParameters) {
    for (let i = 0; i < gtmEventParameters.length; i += 1) {
      const params = gtmEventParameters[i];
      let paramsString = '';

      if (params) {
        Object.keys(params).forEach((key) => {
          const param = params[key];
          if (Array.isArray(param)) {
            const arr: string[] = param;

            if (arr.length) {
              arr.forEach((val) => {
                paramsString += paramsString ? '&' : '';
                paramsString += `${key}[]=${val}`;
              });
            } else {
              paramsString += paramsString ? '&' : '';
              paramsString += `bepEmptyList=${key}`;
            }
          } else {
            paramsString += paramsString ? '&' : '';
            paramsString += `${key}=${params[key]}`;
          }
        });

        if (paramsString) {
          newUrl += `${newUrl.split('?')[1] ? '&' : '?'}bep=${encodeURIComponent(paramsString)}`;
        }
      }
    }
  }

  return newUrl;
};

export function redirect(url: URL | string): void {
  const logger = httpContext.getLogger();
  const res = httpContext.getRes();

  if (res?.headersSent) {
    logger?.warn(
      `Attempted to call redirect("${url}") when headers were already sent.` +
        ` Are you trying to redirect twice in the same response?`
    );
    return;
  }

  throw new Http301Error(url);
}
