// https://developer.fastly.com/reference/vcl/variables/geolocation/geoip-continent-code/
// https://community.cloudflare.com/t/geo-for-continent-http-cf-ipcontinent/152463
export enum CONTINENT_CODE {
  AF = 'AF',
  AN = 'AN',
  AS = 'AS',
  EU = 'EU',
  NA = 'NA',
  OC = 'OC',
  SA = 'SA',
}

export enum CONTINENT_CODE_BY_NAME {
  AFRICA = 'AF',
  ANTARCTICA = 'AN',
  ASIA = 'AS',
  EUROPE = 'EU',
  NORTH_AMERICA = 'NA',
  OCEANIA = 'OC',
  SOUTH_AMERICA = 'SA',
}

// https://support.cloudflare.com/hc/en-us/articles/200168236-Configuring-Cloudflare-IP-Geolocation
// https://en.wikipedia.org/wiki/ISO_3166-1_alpha-2
export enum COUNTRY_CODE {
  AD = 'AD',
  AE = 'AE',
  AF = 'AF',
  AG = 'AG',
  AI = 'AI',
  AL = 'AL',
  AM = 'AM',
  AO = 'AO',
  AQ = 'AQ',
  AR = 'AR',
  AS = 'AS',
  AT = 'AT',
  AU = 'AU',
  AW = 'AW',
  AX = 'AX',
  AZ = 'AZ',
  BA = 'BA',
  BB = 'BB',
  BD = 'BD',
  BE = 'BE',
  BF = 'BF',
  BG = 'BG',
  BH = 'BH',
  BI = 'BI',
  BJ = 'BJ',
  BL = 'BL',
  BM = 'BM',
  BN = 'BN',
  BO = 'BO',
  BQ = 'BQ',
  BR = 'BR',
  BS = 'BS',
  BT = 'BT',
  BV = 'BV',
  BW = 'BW',
  BY = 'BY',
  BZ = 'BZ',
  CA = 'CA',
  CC = 'CC',
  CD = 'CD',
  CF = 'CF',
  CG = 'CG',
  CH = 'CH',
  CI = 'CI',
  CK = 'CK',
  CL = 'CL',
  CM = 'CM',
  CN = 'CN',
  CO = 'CO',
  CR = 'CR',
  CU = 'CU',
  CV = 'CV',
  CW = 'CW',
  CX = 'CX',
  CY = 'CY',
  CZ = 'CZ',
  DE = 'DE',
  DJ = 'DJ',
  DK = 'DK',
  DM = 'DM',
  DO = 'DO',
  DZ = 'DZ',
  EC = 'EC',
  EE = 'EE',
  EG = 'EG',
  EH = 'EH',
  ER = 'ER',
  ES = 'ES',
  ET = 'ET',
  FI = 'FI',
  FJ = 'FJ',
  FK = 'FK',
  FM = 'FM',
  FO = 'FO',
  FR = 'FR',
  GA = 'GA',
  GB = 'GB',
  GD = 'GD',
  GE = 'GE',
  GF = 'GF',
  GG = 'GG',
  GH = 'GH',
  GI = 'GI',
  GL = 'GL',
  GM = 'GM',
  GN = 'GN',
  GP = 'GP',
  GQ = 'GQ',
  GR = 'GR',
  GS = 'GS',
  GT = 'GT',
  GU = 'GU',
  GW = 'GW',
  GY = 'GY',
  HK = 'HK',
  HM = 'HM',
  HN = 'HN',
  HR = 'HR',
  HT = 'HT',
  HU = 'HU',
  ID = 'ID',
  IE = 'IE',
  IL = 'IL',
  IM = 'IM',
  IN = 'IN',
  IO = 'IO',
  IQ = 'IQ',
  IR = 'IR',
  IS = 'IS',
  IT = 'IT',
  JE = 'JE',
  JM = 'JM',
  JO = 'JO',
  JP = 'JP',
  KE = 'KE',
  KG = 'KG',
  KH = 'KH',
  KI = 'KI',
  KM = 'KM',
  KN = 'KN',
  KP = 'KP',
  KR = 'KR',
  KW = 'KW',
  KY = 'KY',
  KZ = 'KZ',
  LA = 'LA',
  LB = 'LB',
  LC = 'LC',
  LI = 'LI',
  LK = 'LK',
  LR = 'LR',
  LS = 'LS',
  LT = 'LT',
  LU = 'LU',
  LV = 'LV',
  LY = 'LY',
  MA = 'MA',
  MC = 'MC',
  MD = 'MD',
  ME = 'ME',
  MF = 'MF',
  MG = 'MG',
  MH = 'MH',
  MK = 'MK',
  ML = 'ML',
  MM = 'MM',
  MN = 'MN',
  MO = 'MO',
  MP = 'MP',
  MQ = 'MQ',
  MR = 'MR',
  MS = 'MS',
  MT = 'MT',
  MU = 'MU',
  MV = 'MV',
  MW = 'MW',
  MX = 'MX',
  MY = 'MY',
  MZ = 'MZ',
  NA = 'NA',
  NC = 'NC',
  NE = 'NE',
  NF = 'NF',
  NG = 'NG',
  NI = 'NI',
  NL = 'NL',
  NO = 'NO',
  NP = 'NP',
  NR = 'NR',
  NU = 'NU',
  NZ = 'NZ',
  OM = 'OM',
  PA = 'PA',
  PE = 'PE',
  PF = 'PF',
  PG = 'PG',
  PH = 'PH',
  PK = 'PK',
  PL = 'PL',
  PM = 'PM',
  PN = 'PN',
  PR = 'PR',
  PS = 'PS',
  PT = 'PT',
  PW = 'PW',
  PY = 'PY',
  QA = 'QA',
  RE = 'RE',
  RO = 'RO',
  RS = 'RS',
  RU = 'RU',
  RW = 'RW',
  SA = 'SA',
  SB = 'SB',
  SC = 'SC',
  SD = 'SD',
  SE = 'SE',
  SG = 'SG',
  SH = 'SH',
  SI = 'SI',
  SJ = 'SJ',
  SK = 'SK',
  SL = 'SL',
  SM = 'SM',
  SN = 'SN',
  SO = 'SO',
  SR = 'SR',
  SS = 'SS',
  ST = 'ST',
  SV = 'SV',
  SX = 'SX',
  SY = 'SY',
  SZ = 'SZ',
  TC = 'TC',
  TD = 'TD',
  TF = 'TF',
  TG = 'TG',
  TH = 'TH',
  TJ = 'TJ',
  TK = 'TK',
  TL = 'TL',
  TM = 'TM',
  TN = 'TN',
  TO = 'TO',
  TR = 'TR',
  TT = 'TT',
  TV = 'TV',
  TW = 'TW',
  TZ = 'TZ',
  UA = 'UA',
  UG = 'UG',
  UM = 'UM',
  US = 'US',
  UY = 'UY',
  UZ = 'UZ',
  VA = 'VA',
  VC = 'VC',
  VE = 'VE',
  VG = 'VG',
  VI = 'VI',
  VN = 'VN',
  VU = 'VU',
  WF = 'WF',
  WS = 'WS',
  YE = 'YE',
  YT = 'YT',
  ZA = 'ZA',
  ZM = 'ZM',
  ZW = 'ZW',
}

export enum COUNTRY_CODE_BY_NAME {
  AFGHANISTAN = 'AF',
  ALAND_ISLANDS = 'AX',
  ALBANIA = 'AL',
  ALGERIA = 'DZ',
  AMERICAN_SAMOA = 'AS',
  ANDORRA = 'AD',
  ANGOLA = 'AO',
  ANGUILLA = 'AI',
  ANTARCTICA = 'AQ',
  ANTIGUA_AND_BARBUDA = 'AG',
  ARGENTINA = 'AR',
  ARMENIA = 'AM',
  ARUBA = 'AW',
  AUSTRALIA = 'AU',
  AUSTRIA = 'AT',
  AZERBAIJAN = 'AZ',
  BAHAMAS = 'BS',
  BAHRAIN = 'BH',
  BANGLADESH = 'BD',
  BARBADOS = 'BB',
  BELARUS = 'BY',
  BELGIUM = 'BE',
  BELIZE = 'BZ',
  BENIN = 'BJ',
  BERMUDA = 'BM',
  BHUTAN = 'BT',
  BOLIVARIAN_REPUBLIC_OF_VENEZUELA = 'VE', // Venezuela
  BONAIRE_SINT_EUSTATIUS_AND_SABA = 'BQ',
  BOSNIA_AND_HERZEGOVINA = 'BA',
  BOTSWANA = 'BW',
  BOUVET_ISLAND = 'BV',
  BRAZIL = 'BR',
  BRITISH_INDIAN_OCEAN_TERRITORY = 'IO',
  BRITISH_VIRGIN_ISLANDS = 'VG',
  BRUNEI_DARUSSALAM = 'BN',
  BULGARIA = 'BG',
  BURKINA_FASO = 'BF',
  BURUNDI = 'BI',
  CABO_VERDE = 'CV', // Cape Verde
  CAMBODIA = 'KH',
  CAMEROON = 'CM',
  CANADA = 'CA',
  CAYMAN_ISLANDS = 'KY',
  CENTRAL_AFRICAN_REPUBLIC = 'CF',
  CHAD = 'TD',
  CHILE = 'CL',
  CHINA = 'CN',
  CHRISTMAS_ISLAND = 'CX',
  COCOS_ISLANDS = 'CC',
  COLOMBIA = 'CO',
  COMOROS = 'KM',
  CONGO = 'CG',
  COOK_ISLANDS = 'CK',
  COSTA_RICA = 'CR',
  COTE_DIVOIRE = 'CI', // Ivory Coast
  CROATIA = 'HR',
  CUBA = 'CU',
  CURACAO = 'CW',
  CYPRUS = 'CY',
  CZECHIA = 'CZ', // Czech Republic
  DEMOCRATIC_PEOPLES_REPUBLIC_OF_KOREA = 'KP', // North Korea
  DEMOCRATIC_REPUBLIC_OF_THE_CONGO = 'CD', // Congo, DRC
  DENMARK = 'DK',
  DJIBOUTI = 'DJ',
  DOMINICA = 'DM',
  DOMINICAN_REPUBLIC = 'DO',
  ECUADOR = 'EC',
  EGYPT = 'EG',
  EL_SALVADOR = 'SV',
  EQUATORIAL_GUINEA = 'GQ',
  ERITREA = 'ER',
  ESTONIA = 'EE',
  ESWATINI = 'SZ', // Swaziland
  ETHIOPIA = 'ET',
  FALKLAND_ISLANDS = 'FK',
  FAROE_ISLANDS = 'FO',
  FEDERATED_STATES_OF_MICRONESIA = 'FM', // Micronesia
  FIJI = 'FJ',
  FINLAND = 'FI',
  FRANCE = 'FR',
  FRENCH_GUIANA = 'GF',
  FRENCH_POLYNESIA = 'PF',
  FRENCH_SOUTHERN_TERRITORIES = 'TF',
  GABON = 'GA',
  GAMBIA = 'GM',
  GEORGIA = 'GE',
  GERMANY = 'DE',
  GHANA = 'GH',
  GIBRALTAR = 'GI',
  GREECE = 'GR',
  GREENLAND = 'GL',
  GRENADA = 'GD',
  GUADELOUPE = 'GP',
  GUAM = 'GU',
  GUATEMALA = 'GT',
  GUERNSEY = 'GG',
  GUINEA = 'GN',
  GUINEA_BISSAU = 'GW',
  GUYANA = 'GY',
  HAITI = 'HT',
  HEARD_ISLAND_AND_MCDONALD_ISLANDS = 'HM',
  HOLY_SEE = 'VA', // Vatican City
  HONDURAS = 'HN',
  HONG_KONG = 'HK',
  HUNGARY = 'HU',
  ICELAND = 'IS',
  INDIA = 'IN',
  INDONESIA = 'ID',
  IRAQ = 'IQ',
  IRELAND = 'IE',
  ISLAMIC_REPUBLIC_OF_IRAN = 'IR', // Iran
  ISLE_OF_MAN = 'IM',
  ISRAEL = 'IL',
  ITALY = 'IT',
  JAMAICA = 'JM',
  JAPAN = 'JP',
  JERSEY = 'JE',
  JORDAN = 'JO',
  KAZAKHSTAN = 'KZ',
  KENYA = 'KE',
  KIRIBATI = 'KI',
  KUWAIT = 'KW',
  KYRGYZSTAN = 'KG',
  LAO_PEOPLES_DEMOCRATIC_REPUBLIC = 'LA', // Laos
  LATVIA = 'LV',
  LEBANON = 'LB',
  LESOTHO = 'LS',
  LIBERIA = 'LR',
  LIBYA = 'LY',
  LIECHTENSTEIN = 'LI',
  LITHUANIA = 'LT',
  LUXEMBOURG = 'LU',
  MACAO = 'MO',
  MADAGASCAR = 'MG',
  MALAWI = 'MW',
  MALAYSIA = 'MY',
  MALDIVES = 'MV',
  MALI = 'ML',
  MALTA = 'MT',
  MARSHALL_ISLANDS = 'MH',
  MARTINIQUE = 'MQ',
  MAURITANIA = 'MR',
  MAURITIUS = 'MU',
  MAYOTTE = 'YT',
  MEXICO = 'MX',
  MONACO = 'MC',
  MONGOLIA = 'MN',
  MONTENEGRO = 'ME',
  MONTSERRAT = 'MS',
  MOROCCO = 'MA',
  MOZAMBIQUE = 'MZ',
  MYANMAR = 'MM',
  NAMIBIA = 'NA',
  NAURU = 'NR',
  NEPAL = 'NP',
  NETHERLANDS = 'NL',
  NEW_CALEDONIA = 'NC',
  NEW_ZEALAND = 'NZ',
  NICARAGUA = 'NI',
  NIGER = 'NE',
  NIGERIA = 'NG',
  NIUE = 'NU',
  NORFOLK_ISLAND = 'NF',
  NORTH_MACEDONIA = 'MK',
  NORTHERN_MARIANA_ISLANDS = 'MP',
  NORWAY = 'NO',
  OMAN = 'OM',
  PAKISTAN = 'PK',
  PALAU = 'PW',
  PANAMA = 'PA',
  PAPUA_NEW_GUINEA = 'PG',
  PARAGUAY = 'PY',
  PERU = 'PE',
  PHILIPPINES = 'PH',
  PITCAIRN = 'PN',
  PLURINATIONAL_STATE_OF_BOLIVIA = 'BO', // Bolivia
  POLAND = 'PL',
  PORTUGAL = 'PT',
  PREVIOUSLY_REPRESENTED_BRITISH_ANTARCTIC_TERRITORY = 'BQ',
  PROVINCE_OF_CHINA_TAIWAN = 'TW', // Taiwan
  PUERTO_RICO = 'PR',
  QATAR = 'QA',
  REPUBLIC_OF_KOREA = 'KR', // South Korea
  REPUBLIC_OF_MOLDOVA = 'MD',
  Réunion = 'RE',
  ROMANIA = 'RO',
  RUSSIAN_FEDERATION = 'RU', // Russia
  RWANDA = 'RW',
  SAINT_BARTHELEMY = 'BL',
  SAINT_HELENA_ASCENSION_AND_TRISTAN_DA_CUNHA = 'SH',
  SAINT_KITTS_AND_NEVIS = 'KN',
  SAINT_LUCIA = 'LC',
  SAINT_MARTIN = 'MF',
  SAINT_PIERRE_AND_MIQUELON = 'PM',
  SAINT_VINCENT_AND_THE_GRENADINES = 'VC',
  SAMOA = 'WS',
  SAN_MARINO = 'SM',
  SAO_TOME_AND_PRINCIPE = 'ST',
  SAUDI_ARABIA = 'SA',
  SENEGAL = 'SN',
  SERBIA = 'RS',
  SEYCHELLES = 'SC',
  SIERRA_LEONE = 'SL',
  SINGAPORE = 'SG',
  SINT_MAARTEN = 'SX',
  SLOVAKIA = 'SK',
  SLOVENIA = 'SI',
  SOLOMON_ISLANDS = 'SB',
  SOMALIA = 'SO',
  SOUTH_AFRICA = 'ZA',
  SOUTH_GEORGIA_AND_THE_SOUTH_SANDWICH_ISLANDS = 'GS',
  SOUTH_SUDAN = 'SS',
  SPAIN = 'ES',
  SRI_LANKA = 'LK',
  STATE_OF_PALESTINE = 'PS',
  SUDAN = 'SD',
  SURINAME = 'SR',
  SVALBARD_AND_JAN_MAYEN = 'SJ',
  SWEDEN = 'SE',
  SWITZERLAND = 'CH',
  SYRIAN_ARAB_REPUBLIC = 'SY', // Syria
  TAJIKISTAN = 'TJ',
  THAILAND = 'TH',
  TIMOR_LESTE = 'TL',
  TOGO = 'TG',
  TOKELAU = 'TK',
  TONGA = 'TO',
  TRINIDAD_AND_TOBAGO = 'TT',
  TUNISIA = 'TN',
  TURKEY = 'TR',
  TURKMENISTAN = 'TM',
  TURKS_AND_CAICOS_ISLANDS = 'TC',
  TUVALU = 'TV',
  UGANDA = 'UG',
  UKRAINE = 'UA',
  UNITED_ARAB_EMIRATES = 'AE',
  UNITED_KINGDOM_OF_GREAT_BRITAIN_AND_NORTHERN_IRELAND = 'GB', // United Kingdom
  UNITED_REPUBLIC_OF_TANZANIA = 'TZ',
  UNITED_STATES_MINOR_OUTLYING_ISLANDS = 'UM',
  UNITED_STATES_OF_AMERICA = 'US',
  URUGUAY = 'UY',
  US_VIRGIN_ISLANDS = 'VI',
  UZBEKISTAN = 'UZ',
  VANUATU = 'VU',
  VIET_NAM = 'VN', // Vietnam
  WALLIS_AND_FUTUNA = 'WF',
  WESTERN_SAHARA = 'EH',
  YEMEN = 'YE',
  ZAMBIA = 'ZM',
  ZIMBABWE = 'ZW',
}
