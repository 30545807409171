import animateScrollTo from 'animated-scroll-to';

type Options = {
  // @see https://www.npmjs.com/package/animated-scroll-to#options
  offset?: number;
  // default: 0
  speed?: number;
  // default: 500
  minDuration?: number;
  // default: 250
  maxDuration?: number;
  // default: 1500
  element?: any;
  // default: window
  cancelOnUserAction?: boolean;
  // default: true
  passive?: boolean;
  // default: true
  horizontal?: boolean;
  // default: true
  onComplete?: (...args: any[]) => any; // default: function() {}
};

const scrollToElement = (elem: string, options: Options = {}) => {
  const el: any = document.querySelector(`${elem}`);

  if (el) {
    animateScrollTo(el, options);
  }
};

export default scrollToElement;
