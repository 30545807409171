import Rover from '@rover/globals/Rover';
import ModalView from '../../components/rover_modal';

Rover.views.ContactBackupsModal = ModalView.extend({
  initialize(options) {
    ModalView.prototype.initialize.call(this, options); // super()
    _.bindAll(this, 'togglePrimaryButton');
    this.$el.find('.js-modal-primary-action').click(() => this.options.onSave());
  },
  togglePrimaryButton() {
    const selector = this.$el.find('.js-modal-primary-action');
    selector.attr('disabled', 'true');
    selector.children('i').toggleClass('hidden');
  },
});
