import forOwn from 'lodash-es/forOwn';
import unescape from 'lodash-es/unescape';

import { camelizeString } from '@rover/rsdk/src/modules/Network/object-utilities';

import $ from '../jquery';
import Rover from '../Rover';
// Do this all very quickly, don't wait for document ready
window.dataLayer = window.dataLayer || []; // Google Tag Manager (GTM)

type CurrentUserApiType = Record<
  string,
  boolean | Record<string, boolean | string | number | null | Record<string, string>>
>;

export default class CurrentUser {
  id: string;

  url: string;

  apiUrl: string;

  authenticated?: boolean;

  isSitter: boolean;

  listsAllServices: boolean;

  firstName: string;

  lastName: string;

  email: string;

  isActiveSssuApplicantWithSelectedServices: boolean;

  smallPhotoUrl: string;

  unreadMessageCount: number;

  hasDogs: boolean;

  shouldPromptForPets: boolean;

  zip: string;

  timeZone: string;

  isNewCustomer: boolean;

  opk: string;

  userLoadedHandlers: Array<(data: unknown) => void>;

  userAuthenticatedHandlers: Array<() => void>;

  userAnonymousHandlers: Array<() => void>;

  constructor() {
    this.url = Rover?.urls?.currentPerson;
    this.id = '';
    // Properties
    this.apiUrl = '';
    this.authenticated = undefined;
    this.isSitter = false;
    this.listsAllServices = false;
    this.firstName = '';
    this.lastName = '';
    this.email = '';
    this.isActiveSssuApplicantWithSelectedServices = false;
    this.smallPhotoUrl = '';
    this.unreadMessageCount = 0;
    this.hasDogs = false;
    this.shouldPromptForPets = false;
    this.zip = '';
    this.timeZone = '';
    this.isNewCustomer = false;
    this.opk = '';
    this.userLoadedHandlers = [];
    this.userAuthenticatedHandlers = [];
    this.userAnonymousHandlers = [];
    // Fetch user data
    this.fetch();
  }

  fetch(): void {
    $.getJSON(this.url, (data) => {
      this.authenticated = data.authenticated;

      if (this.authenticated) {
        this.copyApiData(data);
      }

      // Run callbacks registered
      this.userLoadedHandlers.forEach((h) => h(this));

      if (this.authenticated === true) {
        this.userAuthenticatedHandlers.forEach((h) => h());
      } else if (this.authenticated === false) {
        this.userAnonymousHandlers.forEach((h) => h());
      }
    });
  }

  copyApiData(data: CurrentUserApiType): void {
    forOwn(data.current_user, (value, key) => {
      const fieldTranslations = {
        postal_code: 'zip',
        is_active_service_provider: 'isSitter',
      };
      const unescapeFields = ['first_name', 'last_name'];

      let newKey;
      if (Object.keys(fieldTranslations).includes(key)) {
        newKey = fieldTranslations[key];
      } else {
        newKey = camelizeString(key);
      }

      this[newKey] = unescapeFields.includes(key) ? unescape(value) : value;
    });
  }

  /**
   * Runs the callback provided if the user is setup.
   * Otherwise, registers the callback to be called
   * when the user has been fully setup
   */
  waitForUserLoaded(): Promise<CurrentUser> {
    return new Promise((resolve) => {
      if (this.authenticated === undefined) {
        this.userLoadedHandlers.push(() => resolve(this));
      } else {
        resolve(this);
      }
    });
  }

  /**
   * Runs the callback provided if the user is authenticated already.
   * Otherwise, registers the callback to be called when the user
   * has been fully setup and the user is authenticated
   */
  waitForAuthenticatedUserLoaded(): Promise<CurrentUser> {
    return new Promise((resolve, reject) => {
      if (this.authenticated === true) {
        resolve(this);
      } else {
        this.userAuthenticatedHandlers.push(() => resolve(this));
        this.userAnonymousHandlers.push(() => reject(this)); // eslint-disable-line prefer-promise-reject-errors
      }
    });
  }

  /**
   * Runs the callback provided if the user is anonymous.
   * Otherwise, registers the callback to be called when the user
   * has been fully setup and the user is anonymous
   */
  waitForAnonymousUserLoaded(): Promise<CurrentUser> {
    return new Promise((resolve, reject) => {
      if (this.authenticated === false) {
        resolve(this);
      } else {
        this.userAnonymousHandlers.push(() => resolve(this));
        this.userAuthenticatedHandlers.push(() => reject(this)); // eslint-disable-line prefer-promise-reject-errors
      }
    });
  }
}
