import React, { SyntheticEvent, useEffect, useRef, useState } from 'react';
import { t } from '@lingui/macro';
import styled from 'styled-components';

import { Button, Flex, Paragraph, Text } from '@rover/kibble/core';
import ImageHeader from '@rover/kibble/patterns/ImageHeader';
import { DSTokenMap } from '@rover/kibble/styles';
import Input from '@rover/react-lib/src/components/formFields/Input';
import { PageLayout, VerticalLayout } from '@rover/react-lib/src/components/layouts';
import Icon from '@rover/react-lib/src/components/typography/Icon';
import Form from '@rover/react-lib/src/components/utils/Form';
import { EmailVerificationEvents } from '@rover/react-lib/src/constants/emailVerification.constants';
import { useI18n } from '@rover/rsdk/src/modules/I18n';
import { EmailVerification, EmailVerificationStatus } from '@rover/types';
import fireDataLayerEvent from '@rover/utilities/fireDataLayerEvent';
import inject from '@rover/utilities/injector';
import { redirect } from '@rover/utilities/url';

type Props = {
  headerText: string;
  imageHeaderContent: string;
  formLabel: string;
  placeholderText: string;
  image: string;
  verifyButtonText: string;
  verifyingButtonText: string;
  resendButtonText: string;
  skipButtonText: string;
  verifiedText: string;
  verifyStatus: number | null;
  isSubmitting: boolean;
  resendCode: (refresh?: boolean) => void;
  verifyEmail: (arg0: EmailVerification) => void;
  errorMessage: string;
  isBlockingModal: boolean;
  emailVerificationStatus: EmailVerificationStatus;
  logoutCurrentUser: (arg0: { nextLocation: string; ref?: string }) => void;
};

const StyledPageLayout = styled(PageLayout)`
  max-width: 560px;
`;

// eslint-disable-next-line rover/prefer-kibble-components
const StyledModalLayout = styled.div`
  margin: ${DSTokenMap.SPACE_4X} auto;
  padding: 0 ${DSTokenMap.SPACE_4X};
  max-width: 560px;
`;

// eslint-disable-next-line rover/prefer-kibble-components
const StyledText = styled(Text)`
  padding-top: ${DSTokenMap.SPACE_1X};
  color: ${DSTokenMap.TEXT_COLOR_SUCCESS.toString()};
  font-weight: bold;
`;

const StyledIcon = styled(Icon)`
  font-size: ${DSTokenMap.DISPLAY_400_FONT_SIZE};
  color: ${DSTokenMap.TEXT_COLOR_SUCCESS.toString()};
  font-weight: bold;
`;

const VerifyEmail = (props: Props): JSX.Element => {
  const {
    headerText,
    imageHeaderContent,
    formLabel,
    placeholderText,
    image,
    verifyButtonText,
    verifyingButtonText,
    resendButtonText,
    skipButtonText,
    verifiedText,
    verifyStatus,
    isSubmitting,
    resendCode,
    verifyEmail,
    emailVerificationStatus,
    errorMessage,
    isBlockingModal,
    logoutCurrentUser,
  } = props;
  const { i18n } = useI18n();
  const previousUrl = inject('Rover.context.previousUrl', '');
  const nextUrl = inject('Rover.context.nextUrl', '');

  const [isVerifying, setIsVerifying] = useState(false);
  const [textInputClicked, setTextInputClicked] = useState(false);
  const [isDirty, setIsDirty] = useState(false);
  const [isVerificationSuccessful, setIsVerificationSuccessful] = useState(false);

  const currentCode = useRef('');

  const RESEND_TIME = 10;
  const intervalCountdown: { current: ReturnType<typeof setInterval> | undefined } = useRef();
  const [resendCountdown, setResendCountdown] = useState(0);
  const [resendText, setResendText] = useState(resendButtonText);
  const [showAlphanumError, setShowAlphanumError] = useState(false);
  const alphanumericalError = i18n._(
    t`This code is incorrect. The code is case sensitive and must only contain alphanumeric characters (A–Z and 0–9). Check your email and try again.`
  );
  const logoutText = i18n._(t`Log out`);
  const Layout = (isBlockingModal ? StyledModalLayout : StyledPageLayout) as React.ElementType;

  const decreaseResendCountdown = (): void => {
    setResendCountdown((prev) => {
      if (prev <= 1 && intervalCountdown.current) {
        clearInterval(intervalCountdown.current);
      }
      return prev - 1;
    });
  };

  const startResendCountdown = (): void => {
    setResendCountdown(RESEND_TIME);
    intervalCountdown.current = setInterval(decreaseResendCountdown, 1000);
  };

  const triggerGenericEvents = (
    eventName: string,
    error?: string | null,
    code?: string,
    submitVerifyStatus?: boolean
  ): void => {
    const submitStatus =
      submitVerifyStatus !== undefined
        ? { submitStatus: submitVerifyStatus ? 'success' : 'fail' }
        : {};
    fireDataLayerEvent({
      event: eventName,
      flowSource: previousUrl,
      flow: emailVerificationStatus,
      ...(error && error.length > 0 ? { errorMessage: error } : {}),
      ...(code ? { code } : {}),
      ...submitStatus,
      observability_tags: {
        flow: emailVerificationStatus,
        ...submitStatus,
      },
    });
  };

  const triggerModalAknowledge = (): void => {
    // eslint-disable-next-line rover/no-platform-specific-globals-or-imports
    isBlockingModal && $(document).trigger('blocking-modal-acknowledged');
  };

  useEffect(() => {
    resendCode();
    startResendCountdown();
    triggerGenericEvents(EmailVerificationEvents.VERIFY_EMAIL_LOAD);
    return () => {
      if (intervalCountdown.current) {
        clearInterval(intervalCountdown.current);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (currentCode.current === '') {
      return;
    }
    setIsVerificationSuccessful(verifyStatus === 200);
    triggerGenericEvents(
      EmailVerificationEvents.VERIFY_EMAIL_VERIFY_BUTTON_CLICK,
      errorMessage,
      currentCode.current,
      verifyStatus === 200
    );
  }, [verifyStatus]);

  useEffect(() => {
    let timer: ReturnType<typeof setTimeout> | undefined;
    if (isVerificationSuccessful) {
      timer = setTimeout(() => {
        if (isBlockingModal) triggerModalAknowledge();
        else redirect(previousUrl);
      }, 1000);
    }
    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVerificationSuccessful]);

  useEffect(() => {
    if (!isSubmitting) {
      setIsVerifying(false);
    }
  }, [isSubmitting]);

  useEffect(() => {
    if (resendCountdown > 0) {
      setResendText(i18n._(t`Send new code in ${resendCountdown} seconds`));
    } else {
      setResendText(resendButtonText);
    }
  }, [resendCountdown, resendButtonText, i18n]);

  const handleSubmit = (code: string, e?: SyntheticEvent<HTMLButtonElement>): void => {
    if (e && e.preventDefault) e.preventDefault();
    setIsVerifying(true);
    currentCode.current = code;
    verifyEmail({ code: code.trim(), emailVerificationStatus });
  };

  const onResendCode = (onChange): void => {
    onChange({ code: '' });
    setIsDirty(false);
    resendCode(true);
    startResendCountdown();
    triggerGenericEvents(EmailVerificationEvents.VERIFY_EMAIL_RESEND_CODE_BUTTON_CLICK);
  };

  const onSkip = (): void => {
    triggerGenericEvents(EmailVerificationEvents.VERIFY_EMAIL_SKIP_BUTTON_CLICK);
    !isBlockingModal && redirect(previousUrl);
  };

  const isAlphanumeric = (code: string | undefined): void => {
    if (code && !!/[^a-zA-Z0-9]/i.test(code)) {
      setShowAlphanumError(true);
      return;
    }
    setShowAlphanumError(false);
  };

  const shouldShowAlphanumError = (): string | undefined => {
    if (showAlphanumError) {
      return alphanumericalError;
    }
    return undefined;
  };

  const shouldShowSkipButton = (): boolean => {
    if (emailVerificationStatus === EmailVerificationStatus.COMPULSORY || isBlockingModal)
      return false;
    return true;
  };

  const shouldShowLogoutButton = (): boolean => {
    if (emailVerificationStatus === EmailVerificationStatus.COMPULSORY || isBlockingModal)
      return true;
    return false;
  };

  return (
    <Layout>
      <ImageHeader
        heading={headerText}
        headingSize="400"
        image={image}
        description={i18n._(t`Paper airplane doodle`)}
        resizeMethod="contain"
      >
        <Paragraph>{imageHeaderContent}</Paragraph>
      </ImageHeader>
      <Form initialData={{ code: '' }} onSubmit={({ code }) => code && handleSubmit(code)}>
        {(form) => (
          <>
            <Input
              name="code"
              label={formLabel}
              placeholder={placeholderText}
              disabled={isSubmitting}
              validationError={errorMessage || shouldShowAlphanumError()}
              validationType="inline"
              onChange={(code) => {
                setIsDirty(true);
                isAlphanumeric(code);
                form.onChange({
                  code,
                });
                if (!textInputClicked) {
                  setTextInputClicked(true);
                  triggerGenericEvents(EmailVerificationEvents.VERIFY_EMAIL_ADD_CODE);
                }
              }}
              value={form.data.code}
            />
            {isVerificationSuccessful ? (
              <Flex alignItems="start" justifyContent="center">
                <StyledIcon icon="rover-icon-thin-check" />
                <StyledText>{verifiedText}</StyledText>
              </Flex>
            ) : (
              <VerticalLayout>
                <Button
                  onClick={form.onSubmit}
                  loading={isVerifying}
                  disabled={isSubmitting || !isDirty || showAlphanumError}
                  variant="primary"
                  mt="1x"
                  mb="4x"
                  fullWidth
                  data-qa-id="submit"
                >
                  {isVerifying ? verifyingButtonText : verifyButtonText}
                </Button>
                <Button
                  onClick={() => onResendCode(form.onChange)}
                  loading={resendCountdown > 0}
                  disabled={isSubmitting || resendCountdown > 0}
                  variant="flat"
                  mb="4x"
                  fullWidth
                  data-qa-id="resend"
                  data-testid="email-verification-resend"
                >
                  {resendText}
                </Button>
                {shouldShowSkipButton() && (
                  <Button
                    onClick={onSkip}
                    disabled={isSubmitting}
                    variant="flat"
                    fullWidth
                    data-qa-id="skip"
                  >
                    {skipButtonText}
                  </Button>
                )}
                {shouldShowLogoutButton() && (
                  <Button
                    onClick={() => {
                      logoutCurrentUser({ nextLocation: nextUrl });
                      triggerGenericEvents(
                        EmailVerificationEvents.VERIFY_EMAIL_LOGOUT_BUTTON_CLICK
                      );
                    }}
                    disabled={isSubmitting}
                    variant="flat"
                    fullWidth
                    data-qa-id="logout"
                  >
                    {logoutText}
                  </Button>
                )}
              </VerticalLayout>
            )}
          </>
        )}
      </Form>
    </Layout>
  );
};
export default VerifyEmail;
