import { t } from '@lingui/macro';
import jQuery from 'jquery';

import i18n from '@rover/utilities/translation';

(function extendValidatorMessages($: any) {
  $.extend($.validator.messages, {
    required: i18n._('This field is required.'),
    remote: i18n._('Please fix this field.'),
    email: i18n._('Enter a valid email address.'),
    url: i18n._('Please enter a valid URL.'),
    date: i18n._('Please enter a valid date.'),
    dateISO: i18n._('Please enter a valid date (ISO).'),
    number: i18n._('Please enter a valid number.'),
    digits: i18n._('Please enter only digits.'),
    creditcard: i18n._('Please enter a valid credit card number.'),
    equalTo: i18n._('Please enter the same value again.'),
    accept: i18n._('Please enter a value with a valid extension.'),
    maxlength: (maxLength) => i18n._(t`Please enter no more than ${maxLength} characters.`),
    minlength: (minLength) => i18n._(t`Please enter at least ${minLength} characters.`),
    rangelength: ([minLength, maxLength]) =>
      i18n._(t`Please enter a value between ${minLength} and ${maxLength} characters long.`),
    range: ([minValue, maxValue]) =>
      i18n._(t`Please enter a value between ${minValue} and ${maxValue}.`),
    max: (maxValue) => i18n._(t`Please enter a value less than or equal to ${maxValue}.`),
    min: (minValue) => i18n._(t`Please enter a value greater than or equal to ${minValue}.`),
  });
})(jQuery); // eslint-disable-line no-undef
