import Rover from '@rover/globals/Rover';
import Backbone from '@rover/globals/backbone';
import Mustache from '@rover/globals/Mustache';
import moment from '@rover/globals/moment';
import _ from '@rover/globals/underscore';
import $ from '@rover/globals/jquery';
import Week from '@rover/utilities/Week';

window.Rover = window.Rover || {};
Rover.views = Rover.views || {};

(function() {
  /**
   * Wrap _refreshDatepicker
   * Without this wrapper, the page scroll to the top anytime
   * a user selects a date.
   */
  const fn = _.bind($.datepicker._refreshDatepicker, $.datepicker);
  $.datepicker._refreshDatepicker = function(target) {
    const st = $(window).scrollTop();
    const ret = fn(target);
    $(window).scrollTop(st);
    return ret;
  };
})();

(function(ns) {
  'use strict';

  ns.DatesPicker = Backbone.View.extend({
    template: Mustache.template('new_design/common/datespicker'),

    initialize(opts) {
      _.bindAll(this, '_onDateSelect', '_onModelChange');
      this.model.on('change', this._onModelChange);
      this._context = {
        label: opts.label,
        slug: opts.slug,
      };
      // these need to be passed here since importing anything in this file will break our current test setup
      this.dateFormat = opts.dateFormat;
      this.momentFormat = opts.momentFormat;
      this.momentLogFormat = opts.momentLogFormat;
    },

    render() {
      this.$el.html(this.template.render(_.extend(this._context, this.model.attributes)));
      this._$input = this.$('.js-dates');
      this.$hiddenInput = this.$('input[type="hidden"]');
      this._initMultiDatesPicker();
      this._clearErrors();
      this._renderDates();
      return this;
    },

    _renderDates() {
      const dates = this.model.get('dates');
      this._updateDatesLog(dates);
    },

    _hasErrors() {
      return this.$el.closest('.form-group').hasClass('has-error');
    },

    _clearErrors() {
      this.$el.closest('.form-group').removeClass('has-error');
    },

    _initMultiDatesPicker() {
      const opts = {
        minDate: 0,
        onSelect: this._onDateSelect,
        dateFormat: this.dateFormat,
        dayNamesMin: [0, 1, 2, 3, 4, 5, 6].map(n => Week.upperShortName(n - Week.firstDayOfWeek())),
        monthNames: moment.months(),
        monthNamesShort: moment.monthsShort(),
      };
      if (this.model.get('dates').length) {
        opts.addDates = this.model.get('dates');
      }
      this._$input.multiDatesPicker(opts);
    },

    _onDateSelect(dateStr, datePicker) {
      this.model.set('dates', this._$input.multiDatesPicker('getDates'));
      this.$el.trigger('selected.datespicker');
    },

    _onModelChange() {
      this._renderDates();
    },

    _updateDatesLog(dates) {
      dates = _.map(dates, d => moment(d, this.momentFormat).format(this.momentLogFormat));
      const $log = this.$('.js-date-log');
      $log.text(dates.join(', '));
      if (dates.length) {
        $log.show();
      } else {
        $log.hide();
      }

      // Update the hidden input
      this.$hiddenInput.val(dates.join(', '));
      if (dates.length && this._hasErrors()) {
        this.$hiddenInput.valid();
      }
    },
  });
})(Rover.views);
