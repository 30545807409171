function getPersonOpk(url): string {
  if (!url) {
    return '';
  }

  const match = url.match(/people\/([a-zA-Z0-9]+)\//);

  if (!match) {
    return '';
  }

  return match[1] || '';
}

export default getPersonOpk;
