import $ from '@rover/globals/jquery';
import Rover from '@rover/globals/Rover';

import ModalView from './rover_modal';

(() => {
  function getModalContent(conversation): JQuery.Promise<any> {
    return $.get(conversation.urls.connect_account_booking_modal);
  }

  function showModal(modalContent, buttonView): JQuery.Promise<unknown, unknown, unknown> {
    const modalData = modalContent.modal_data;

    const modal = new ModalView({
      title: modalData.title,
      content: modalData.body,
      okText: modalData.primary_action,
      showExtraButton: !!modalData.secondary_action,
      extraText: modalData.secondary_action,
      extraType: 'secondary',
      cancelText: false,
      showFooter: true,
      padding: false,
      extraPullLeft: false,
    });
    modal.open();

    // and then wait for the acknowledgement
    const d = $.Deferred();

    modal.on('ok', () => {
      const receivePaymentsUrl = '/account/profile/receive-payments/';
      const shouldOpenInNewTab = !!modalData.secondary_action;

      if (shouldOpenInNewTab) {
        window.open(receivePaymentsUrl, '_blank');
      } else {
        window.location.href = receivePaymentsUrl;
      }
    });

    modal.on('extra', () => {
      modal.close();
      d.resolve();
    });

    modal.on('hidden', () => {
      modal.remove();
      buttonView.model.set('isDisabled', false);
    });

    return d.promise();
  }

  function doSCABookingAdvisory(
    conversation,
    buttonView
  ): JQuery.Promise<unknown, unknown, unknown> {
    return getModalContent(conversation).then((modalContent) => {
      if (modalContent.display_modal) {
        return showModal(modalContent, buttonView);
      }
      return $.Deferred().resolve();
    });
  }

  Rover.connectAccountBookingModal = {
    doSCABookingAdvisory,
  };
})();
