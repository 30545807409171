/* Wrapper for Google maps behavior that will automatically load the script
 * if it hasn't been loaded already.
 */
import Rover from '@rover/globals/Rover';
import { isMobileBrowser } from '@rover/utilities/device';

const DEFAULT_GOOGLE_MAPS_CHANNEL = 'web';

class Maps {
  constructor() {
    this.onMapLoadedCallbacks = [];
    this.setChannel();
    this.LoadingStatus = {
      UNLOADED: 'unloaded',
      LOADING: 'loading',
      LOADED: 'loaded',
    };
    this.loadStatus = this.LoadingStatus.UNLOADED;
    this.geocoder = null;
    this.autocompleteService = null;
    this.mapIsLoaded = false;
    this.mapLoading = false;
  }

  setChannel(channel) {
    if (channel) {
      this.channel = channel;
      return;
    }

    const route = window.location.pathname;
    const c = route.startsWith('/members') ? 'web-mempro' : DEFAULT_GOOGLE_MAPS_CHANNEL;

    this.channel = `${isMobileBrowser() ? 'mobile-' : ''}${c}`;
  }

  onMapLoaded() {
    this.geocoder = new google.maps.Geocoder();
    this.autocompleteService = new google.maps.places.AutocompleteService();
    this.placesServiceStatus = google.maps.places.PlacesServiceStatus;
    this.googleMaps = google.maps;
    this.loadStatus = this.LoadingStatus.LOADED;
    let callback = this.onMapLoadedCallbacks.pop();
    while (callback) {
      callback();
      callback = this.onMapLoadedCallbacks.pop();
    }
  }

  loadMaps() {
    if (this.loadStatus !== this.LoadingStatus.UNLOADED) return;
    // Cover cases where a Django view or something outside of the scope of this lib may have already loaded maps
    if (window && window.google && window.google.maps) {
      this.onMapLoaded();
      return;
    }
    this.loadStatus = this.LoadingStatus.LOADING;
    const script = document.createElement('script');
    script.type = 'text/javascript';
    document.getElementsByTagName('head')[0].appendChild(script);
    script.async = true;
    script.src = `https://maps.googleapis.com/maps/api/js?client=${
      Rover.settings.googleMapsClientId
    }&libraries=places&v=3&channel=${this.channel}&callback=window.globalState.maps.onMapLoaded`;
  }

  addHandler(handler) {
    if (this.loadStatus === this.LoadingStatus.LOADED) {
      handler();
    } else {
      this.onMapLoadedCallbacks.push(handler);
    }
    this.loadMaps();
  }

  loadGoogleMapsApi() {
    return new Promise(resolve => {
      this.addHandler(() => {
        resolve(google.maps);
      });
    });
  }

  geocode(data, callback) {
    const handler = () => {
      this.geocoder.geocode(data, callback);
    };
    this.addHandler(handler);
  }

  getPlacePredictions(data, callback) {
    const handler = () => {
      this.autocompleteService.getPlacePredictions(data, callback);
    };
    this.addHandler(handler);
  }
}

window.globalState = window.globalState || {};
window.globalState.maps = window.globalState.maps || new Maps();

export default window.globalState.maps;
