import { union } from '@rover/shared/js/utils/regex';

import { COUNTRY_CODE } from './geo.constants';

// use these for i18n
export const ACTIVE_COUNTRY_CODES = [
  COUNTRY_CODE.US,
  COUNTRY_CODE.CA,
  COUNTRY_CODE.GB,
  COUNTRY_CODE.DE,
  COUNTRY_CODE.ES,
  COUNTRY_CODE.FR,
  COUNTRY_CODE.IT,
  COUNTRY_CODE.NL,
  COUNTRY_CODE.NO,
  COUNTRY_CODE.SE,
  COUNTRY_CODE.DK,
  COUNTRY_CODE.IE,
];

// use these for routes
export const ACTIVE_COUNTRY_URL_PREFIXES = [
  'us',
  'ca',
  'uk',
  'de',
  'es',
  'fr',
  'it',
  'nl',
  'no',
  'se',
  'dk',
  'ie',
] as const;

export type CountryUrlPrefix = (typeof ACTIVE_COUNTRY_URL_PREFIXES)[number];

export const COUNTRY_URL_PREFIXES_REGEX_PATTERN = union(...ACTIVE_COUNTRY_URL_PREFIXES);
export const COUNTRY_URL_PREFIXES_REGEX = new RegExp(`^(${COUNTRY_URL_PREFIXES_REGEX_PATTERN})$`);

export const HOMEPAGE_URL_REGEX = new RegExp(`^(/(${COUNTRY_URL_PREFIXES_REGEX_PATTERN})/?)$|^/$`);

export function isHomepageUrl(url: string): boolean {
  return HOMEPAGE_URL_REGEX.test(url);
}

export function isCountryUrlPrefix(countryUrlPrefix: string): countryUrlPrefix is CountryUrlPrefix {
  return COUNTRY_URL_PREFIXES_REGEX.test(countryUrlPrefix);
}

export function getCountryUrlPrefix(path?: string): CountryUrlPrefix | null {
  const countryUrlPrefix = path?.split('/')[1] ?? '';
  if (isCountryUrlPrefix(countryUrlPrefix)) {
    return countryUrlPrefix;
  }
  return null;
}

export const US_COUNTRY_URL_PREFIX: CountryUrlPrefix = 'us';
export const CA_COUNTRY_URL_PREFIX: CountryUrlPrefix = 'ca';
