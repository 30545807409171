import React from 'react';
import { t } from '@lingui/macro';

import verifyEmailSvg from '@rover/react-lib/src/images/account/email-verification/verify-email.svg?url';
import { useI18n } from '@rover/rsdk/src/modules/I18n';
import type { EmailVerification } from '@rover/types';
import { EmailVerificationStatus } from '@rover/types';
import inject from '@rover/utilities/injector';

import VerifyEmail from './components/VerifyEmail';

type Props = {
  isSubmitting: boolean;
  verifyStatus: number | null;
  resendStatus: number | null;
  errorMessage: string;
  verifyEmail: (arg0: EmailVerification) => void;
  resendCode: (refresh?: boolean) => void;
  logoutCurrentUser: (arg0: { nextLocation: string; ref?: string }) => void;
};

const EmailVerificationPage = (props: Props): JSX.Element => {
  const { i18n } = useI18n();

  const userEmail = inject('Rover.context.currentEmailAddress', '');
  const isBlockingModal = inject('Rover.context.isBlockingModal', false);
  const emailVerificationStatus = inject('Rover.context.emailVerificationStatus', '');

  const headerText =
    emailVerificationStatus === EmailVerificationStatus.COMPULSORY || isBlockingModal
      ? t`Verify your email to set up your Rover account`
      : t`Verify your email to secure your account`;
  const imageHeaderContent =
    emailVerificationStatus === EmailVerificationStatus.COMPULSORY || isBlockingModal
      ? t`Enter the verification code Rover sent to ${userEmail}. Check your spam folder if you don't see it. You will not be able to book with Rover until you complete this step.`
      : t`We just sent a verification code to ${userEmail}. Check your spam folder if you don't see it in your inbox`;
  const formLabel = t`Verification code`;
  const placeholderText = t`Enter the code from email`;
  const verifyButtonText = t`Verify your email`;
  const verifyingButtonText = t`Verifying email`;
  const resendButtonText = t`Send new code`;
  const skipButtonText = t`Skip for now`;
  const verifiedText = t`Email verified!`;

  return (
    <VerifyEmail
      headerText={i18n._(headerText)}
      imageHeaderContent={i18n._(imageHeaderContent)}
      formLabel={i18n._(formLabel)}
      placeholderText={i18n._(placeholderText)}
      image={verifyEmailSvg}
      verifyButtonText={i18n._(verifyButtonText)}
      verifyingButtonText={i18n._(verifyingButtonText)}
      resendButtonText={i18n._(resendButtonText)}
      skipButtonText={i18n._(skipButtonText)}
      verifiedText={i18n._(verifiedText)}
      isBlockingModal={isBlockingModal}
      emailVerificationStatus={emailVerificationStatus}
      {...props}
    />
  );
};

export default EmailVerificationPage;
