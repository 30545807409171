import $ from '@rover/globals/jquery';
import cookie from '../vendor/jquery.cookie'; // this is used below as $.cookie, don't remove
import Backbone from '@rover/globals/backbone';

const GlobalAnnouncementsView = Backbone.View.extend({
  events: {
    'click .js-dismiss': 'onDismiss',
    'keydown .js-dismiss': 'onDismissButtonKeypress',
  },
  initialize() {
    this.$dismiss = this.$('.js-dismiss');
    this.currentIndex = 0;
    this.resetActiveAnnouncement();
  },
  getActiveAnnouncement() {
    return this.getAnnouncements().first();
  },
  getAnnouncements() {
    return this.$('.js-announcement');
  },
  resetActiveAnnouncement() {
    const announcements = this.getAnnouncements();
    const totalAnnouncements = announcements.length;
    let activeAnnouncement = null;

    // Remove the bar if there are no announcements left
    if (!totalAnnouncements) {
      this.remove();
    }

    // Make the first announcement active
    announcements.addClass('hidden');
    activeAnnouncement = this.getActiveAnnouncement();
    activeAnnouncement.removeClass('hidden');

    // Show or hide the dismiss button
    if (activeAnnouncement.data('is-dismissable')) {
      this.$dismiss.removeClass('hidden');
    } else {
      this.$dismiss.addClass('hidden');
    }

    // Set the global announcement bar classes
    this.addAnnouncementBarClass(activeAnnouncement.data('css-classes'));
  },

  onDismiss() {
    const activeAnnouncement = this.getActiveAnnouncement();
    if (activeAnnouncement.data('is-dismissed-cookie')) {
      // Hide this announcement on reload via cookie value
      // TODO: Rely on dismiss PATCH in DEV-19696
      $.cookie(activeAnnouncement.data('is-dismissed-cookie'), '1', {
        expires: 365,
        path: '/',
      });
    }
    this.removeAnnouncementBarClass(activeAnnouncement.data('css-classes'));
    activeAnnouncement.remove();
    this.resetActiveAnnouncement();
  },

  onDismissButtonKeypress(e) {
    if (e.key === ' ' || e.key === 'Enter') {
      e.preventDefault();
      this.onDismiss();
    }
  },

  addAnnouncementBarClass(klass) {
    this.$el.addClass(klass);
  },
  removeAnnouncementBarClass(klass) {
    this.$el.removeClass(klass);
  },
});

if ($('.js-global-announcement-bar').length) {
  const view = new GlobalAnnouncementsView({
    el: $('.js-global-announcement-bar'),
  });
}
