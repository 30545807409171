import type { AnalyticsEvent } from '@rover/types/src/Analytics';
import fireDataLayerEvent from '@rover/utilities/fireDataLayerEvent';

import analyticsEventToDataLayerEvent from './analyticsEventToDataLayerEvent';

const emitAnalyticsEvent = (analyticsEvent: AnalyticsEvent): void => {
  const dataLayerEvent = analyticsEventToDataLayerEvent(analyticsEvent);

  fireDataLayerEvent(dataLayerEvent);
};

export default emitAnalyticsEvent;
