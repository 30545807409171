import Rover from '@rover/globals/Rover';
import Backbone from '@rover/globals/backbone';
import _ from '@rover/globals/underscore';

// WARNING: DF from datetimeInjected is not SSR compatible.
// For newer code, use const DF = getDateTimeFormatMapForLang(<lang>) from '@rover/shared/js/constants/i18n/datetime'
import DF from '@rover/shared/js/constants/i18n/datetime/datetimeInjected';
import moment from 'moment';

(function() {
  function ISOFormat(dateStr) {
    return moment(dateStr, [DF.DATE_SHORT, DF.DATE_ISO]).format(DF.DATE_ISO);
  }

  Rover.models.DateRange = Backbone.Model.extend({
    defaults: {
      start_date: '',
      end_date: '',
      frequency: 'onetime',
    },

    iso_start_date() {
      const date = this.get('start_date');
      return date ? ISOFormat(date) : date;
    },

    iso_end_date() {
      const date = this.get('end_date');
      return date ? ISOFormat(date) : date;
    },

    toJSON() {
      const data = Backbone.Model.prototype.toJSON.call(this);
      // Make sure the end date is always greater than or equal to
      // the start date
      if (this.iso_end_date() < this.iso_start_date()) {
        data.end_date = data.start_date;
      }
      return data;
    },
  });

  Rover.models.MultiDate = Backbone.Model.extend({
    defaults: {
      dates: [],
    },

    iso_dates() {
      return _.map(this.get('dates'), ISOFormat);
    },

    setDays(days) {
      const dates = _.uniq(_.map(days, _.bind(this._dateFromDayStr, this)));
      dates.sort();
      this.set('dates', dates);
    },

    _dateFromDayStr(day) {
      // Find the moment.js day number for 'day'
      let dayNumber = _.reduce(
        [/^su/i, /^m/i, /^tu/i, /^w/i, /^th/i, /^f/i, /^sa/i],
        (prev, current, index) => (day.search(current) > -1 ? index : prev),
        -1
      );
      // Make sure we don't return days in the past
      if (dayNumber < moment().day()) {
        dayNumber += 7;
      }
      return moment()
        .day(dayNumber)
        .format(DF.DATE_SHORT);
    },
  });
})();
