import _ from '@rover/globals/underscore';
import Alerts from '@rover/utilities/Alerts';
import Backbone from '@rover/globals/backbone';
import { getValidationParams } from '@rover/utilities/validation';

import $ from '@rover/globals/jquery';
// WARNING: DF from datetimeInjected is not SSR compatible.
// For newer code, use const DF = getDateTimeFormatMapForLang(<lang>) from '@rover/shared/js/constants/i18n/datetime'
import DF from '@rover/shared/js/constants/i18n/datetime/datetimeInjected';

/**
 * Wrapper to disable the submit button before validation
 */
$.fn.roverValidate = function roverValidate(options) {
  return this.each((idx, elt) => {
    const $form = $(elt);

    function handleSubmit() {
      $form
        .find(':submit')
        .not('.js-prevent-validate-disabled')
        .prop('disabled', true);
    }

    $form.on('submit', handleSubmit);
    $form.validate(options);
  });
};

// Reset the selects to have their selected value
// This is for back button support for selects
const initializeSelects = function() {
  $('select').each(function() {
    const $this = $(this);
    const $selected_options = $this.find('option[selected]');

    if (!$selected_options.length) {
      return;
    }

    if ($this.attr('multiple')) {
      $this.val(
        $selected_options.map(function() {
          return $(this).val();
        })
      );
    } else {
      $this.val($selected_options.val());
    }
  });
};

const initializeSwitcherToggles = () => {
  $('.js-switcher-control').on('click', (event) => {
    const $this = $(event.target);
    const $input = $(`#${$this.data('switcher-for')}`);
    if ($input.is(':disabled')) {
      event.preventDefault();
      return false;
    }
    $input.prop('checked', !$input.prop('checked')).trigger('change');
    return true;
  });
};

// Reset any previously disabled submit buttons
// NOTE: If we ever disable a submit button on load, we need to
//       make this more specific
const resetDisabledForms = function() {
  $('.js-auto-disable-form [type=submit]:disabled').prop('disabled', false);
  // If this is called with a form object as this, reset that form.
  if ($(this).is('form')) {
    const $submitBtn = $(this).find('[type=submit]:disabled');
    if ($submitBtn.attr('data-loading-text')) {
      $submitBtn.btn('reset');
    } else {
      $submitBtn.prop('disabled', false);
    }
  }
};

// Disable the submit button on submit of the form for all
// forms that do not have the data-validate attribute
const handleAutoDisableSubmit = function() {
  let $form = $(this),
    $submitBtn = $form.find('[type=submit]');

  if ($submitBtn.attr('data-loading-text')) {
    $submitBtn.btn('loading');
  } else {
    $submitBtn.prop('disabled', true);
  }
  $form.attr('data-is-valid', 'true');
};

// Fix chrome bug where hitting enter will submit a form where
// the submit button is disabled
// http://code.google.com/p/chromium/issues/detail?id=37402
const handleDocumentSubmitEvent = function() {
  const $form = $(this);
  if ($form.attr('data-submitted') !== 'true' && $form.attr('data-is-valid') === 'true') {
    $form.attr('data-submitted', 'true');
    return true;
  } else if ($form.attr('data-submitted') === 'true') {
    return false;
  }
  return true;
};

$.fn.autoDisableAjaxForm = function(options) {
  options = options || {};
  const mainBeforeSubmit = options.beforeSubmit || null;
  options.beforeSubmit = function(arr, $form, options) {
    if ($form.is(':not([data-validate=true])')) {
      handleAutoDisableSubmit.call($form);
    } else if (!handleDocumentSubmitEvent.call($form)) {
      return false;
    }
    if (mainBeforeSubmit && mainBeforeSubmit(arr, $form, options) === false) {
      resetDisabledForms.call($form);
      return false;
    }
  };
  const mainError = options.error;
  options.error = function(response, status, xhr, $form) {
    resetDisabledForms.call($form);
    if (mainError) {
      mainError(response, status, xhr, $form);
    }
  };
  const mainSuccess = options.success;
  options.success = function(response, status, xhr, $form) {
    if (mainSuccess) {
      mainSuccess(response, status, xhr, $form);
    }
    resetDisabledForms.call($form);
  };

  return this.each(function() {
    $(this).ajaxForm(options);
  });
};

$.fn.autoDisableAndValidateAjaxForm = function(options) {
  options = options || {};

  return this.each(function() {
    $(this).validate();

    const mainBeforeSubmit = options.beforeSubmit || null;
    options.beforeSubmit = function(arr, $form, options) {
      if (!$form.valid()) {
        return false;
      }
      if (mainBeforeSubmit && mainBeforeSubmit(arr, $form, options) === false) {
        resetDisabledForms.call($form);
        return false;
      }
      return true;
    };

    $(this).autoDisableAjaxForm(options);
  });
};

/**
 * Use Bootstrap Popovers for the jQuery validation, as well as the
 * native Bootstrap validation, by adding `has-error` to the `.form-group`
 */
const setValidationDefaults = function() {
  if (!$.isFunction($.validator)) {
    return;
  }

  const originalDefaults = _.clone($.validator.defaults);

  $.validator.setDefaults({
    errorPlacement($error, $el) {
      const text = $error.text();

      // If there's a new error, show it
      if (text && text !== $el.data('currentText')) {
        $el.popover('destroy');
        $el.data('currentText', text);
        $el.popover({
          content: text,
          trigger: 'manual',
          placement: this.errorPopoverPlacement || 'top',
          template:
            '<div class="popover error-popover"><div class="arrow"></div><div class="popover-content"></div></div>',
        });
        $el.popover('show');
      } else if (!text) {
        // If there's no error message, remove popup
        $el.data('currentText', '');
        $el.popover('destroy');
      }
    },

    success($element) {
      $element.popover('destroy');
    },

    invalidHandler(e, validator) {
      $(validator.currentForm)
        .find('[type=submit]')
        .prop('disabled', false);
    },

    highlight(element, errorClass, validClass) {
      let $el = $(element),
        $formGroups = $el.parents('.form-group');

      $formGroups.addClass('has-error');

      originalDefaults.highlight.call(this, element, errorClass, validClass);
    },

    unhighlight(element, errorClass, validClass) {
      let $el = $(element),
        $formGroups = $el.parents('.form-group');

      originalDefaults.unhighlight.call(this, element, errorClass, validClass);

      // If the form-group contains no other errors,
      // clear its error state
      $formGroups.each((i, formGroup) => {
        const $group = $(formGroup);
        if (!$group.find('.error').length) {
          $group.removeClass('has-error');
        }
      });
    },
  });
};

/**
 * Override focusInvalid to work with our non-visible inputs.
 *
 * Add a data-validator-error-focus=".selector"  tag to your inputs
 * and it will focus on the .closest('.selector') when it's the last
 * error
 *
 */
$.validator.prototype.focusInvalid = function() {
  if (this.settings.focusInvalid) {
    try {
      const $lastActive = $(
        this.findLastActive() || (this.errorList.length && this.errorList[0].element) || []
      );

      if ($lastActive.attr('data-validator-error-focus')) {
        $('html, body').animate(
          {
            scrollTop:
              $lastActive.closest($lastActive.attr('data-validator-error-focus')).offset().top - 70,
          },
          100
        );

        return;
      }

      $lastActive
        .filter(':visible')
        .focus()
        // manually trigger focusin event; without it, focusin handler isn't called, findLastActive won't have anything to find
        .trigger('focusin');
    } catch (e) {
      // ignore IE throwing errors when focusing hidden elements
    }
  }
};

/**
 * A validation ruleset for our pet age 'year' + 'month' input group
 * that makes sure we get either a month or a year that is a non-negative int
 * less than a fixed max.
 *
 * Requires inputs with classes 'js-dog-birthday-year' and 'js-dog-birthday-month' inside of a
 * 'js-dog-birthday-inputs' wrap.
 */
$.validator.addClassRules({
  'js-dog-birthday-year': {
    min: 0,
    max: 99,
    pet_age_year: true,
  },
  'js-dog-birthday-month': {
    min: 0,
    max: 240,
    pet_age_month: true,
  },
});

/*
 * Allow validation of a subset of form elements.
 * See http://stackoverflow.com/questions/2636512/validate-subset-of-a-form-using-jquery-validate-plugin
 */
$.validator.prototype.subset = function(container) {
  let ok = true;
  const self = this;
  $(container)
    .find(':input')
    .not(this.settings.ignore)
    .each(function() {
      if (!self.element($(this))) {
        ok = false;
      }
    });
  return ok;
};

/** *
 * Custom jQuery validators
 *** */
$.validator.addMethod('min_date', (value, element, param) => Date.parse(value) >= param);

// Custom validators for our pet age input group
$.validator.addMethod(
  'pet_age_year',
  function(value, element) {
    let $input = $(element),
      $formGroup = $input.closest('.js-dog-birthday-inputs'),
      $inputs = $formGroup.find('input');
    return $.validator.methods.require_from_group.call(this, value, element, [1, $inputs]);
  },
  'Please enter an age in years and/or months.'
);

// Alias of pet_age_year but without the error message
$.validator.addMethod('pet_age_month', $.validator.methods.pet_age_year, false);

const showSuccess = function(response, status, xhr, $form) {
  let successAlertText = $form.attr('data-success-alert-text'),
    hideshow = $form.attr('data-hideshow');
  if (successAlertText) {
    Alerts.showToast({
      severity: 'success',
      message: successAlertText,
      container: $form.find('.js-success-alert'),
    });
  } else if (hideshow) {
    $($form.attr('data-hideshow-hide')).hide();
    $($form.attr('data-hideshow-show')).show();
  }
};

const addBindings = () => {
  $('.js-auto-disable-form:not([data-validate=true])').on('submit', handleAutoDisableSubmit);
  $(document).on('submit', '.js-auto-disable-form', handleDocumentSubmitEvent);
  $('.js-auto-ajax-form').autoDisableAjaxForm({
    success: showSuccess,
  });

  // Auto enable Crispy Forms DateRangePicker
  $(() => {
    $('.js-crispy-daterangepicker').daterangepicker({
      startElementSelector: '.js-start-date',
      endElementSelector: '.js-end-date',
      dateFormat: DF.DATE_PICKER,
    });
  });

  // Auto enable Crispy Forms TimePicker
  $('.js-crispy-time-picker').timepicker({
    scrollDefault: 'now',
    showOnReadonly: true,
    timeFormat: DF.TIME_PICKER,
  });

  // Auto enable Cripsy Forms validation
  $('.js-crispy-validate-form').roverValidate(getValidationParams(':hidden:not(.chosen-select)'));

  // Auto enable USPhoneNumberFieldWithClientSideValidation mask
  $('.js-crispy-phone-number-input').formatter({
    pattern: '{{999}}-{{999}}-{{9999}}',
  });

  // Auto enable SocialSecurityFieldWithClientSideValidation mask
  $('.js-crispy-ssn-input').formatter({
    pattern: '{{999}}-{{99}}-{{9999}}',
  });
};

const addStickitHandlers = function() {
  Backbone.Stickit.addHandler([
    {
      selector: '.js-checkbox-bind-to-int-list',
      events: ['change'],
      update($el, val, model, options) {
        // There are multiple checkboxes so we need to go through them and check
        // any that have value attributes that match what's in the array of `val`s.
        val = val || [];
        if (typeof val === 'string') {
          val = [parseInt(val, 10)];
        }

        $el.each((i, el) => {
          const checkbox = $(el);
          const checked = _.contains(val, parseInt(checkbox.val(), 10));
          checkbox.prop('checked', checked);
        });
      },
      getVal($el) {
        let val;
        val = _.reduce(
          $el,
          (memo, el) => {
            const checkbox = $(el);
            if (checkbox.prop('checked')) {
              memo.push(parseInt(checkbox.val(), 10));
            }
            return memo;
          },
          []
        );
        return val;
      },
    },
  ]);

  Backbone.Stickit.addHandler([
    {
      selector: '.js-checkbox-bind-to-list',
      events: ['change'],
      update($el, val, model, options) {
        // There are multiple checkboxes so we need to go through them and check
        // any that have value attributes that match what's in the array of `val`s.
        val = val || [];
        if (typeof val === 'string') {
          val = [val];
        }

        $el.each((i, el) => {
          const checkbox = $(el);
          const checked = _.contains(val, checkbox.val());
          checkbox.prop('checked', checked);
        });
      },
      getVal($el) {
        let val;
        val = _.reduce(
          $el,
          (memo, el) => {
            const checkbox = $(el);
            if (checkbox.prop('checked')) {
              memo.push(checkbox.val());
            }
            return memo;
          },
          []
        );
        return val;
      },
    },
  ]);

  Backbone.Stickit.addHandler([
    {
      selector: '.js-stickit-data-value',
      events: ['click'],
      update($el, val) {
        // apply active only to the one with the correct
        // value
        $el.removeClass('active');
        $el.filter(`[data-value="${val}"]`).addClass('active');
      },
      getVal($el, event) {
        return $(event.currentTarget).attr('data-value');
      },
    },
  ]);
};

const initHideShow = function() {
  $(document).on('click', '.js-crispy-hide-show-trigger', function(e) {
    let $trigger = $(this),
      hide_text = $trigger.attr('data-hide-text'),
      show_text = $trigger.attr('data-show-text'),
      $wrap = $trigger.parent().siblings('.js-crispy-hide-show-content');

    e.preventDefault();

    if ($wrap.is(':visible')) {
      $trigger
        .attr('aria-expanded', false)
        .html(show_text);
      $wrap.hide();
    } else {
      $trigger
        .attr('aria-expanded', true)
        .html(hide_text);
      $wrap.show();
    }
  });
};

const init = function() {
  setValidationDefaults();
  addBindings();
  addStickitHandlers();
  initializeSelects();
  initializeSwitcherToggles();
  resetDisabledForms();
  initHideShow();
};

init();
