import type { Query } from '@rover/utilities/query';

const getQueryParams = (queryString?: string): Query => {
  let queryStringToProcess = queryString;

  if (!queryStringToProcess && window) {
    queryStringToProcess = window.location.search;
  }

  const params = new URLSearchParams(queryStringToProcess);

  return Array.from(params).reduce((queryParams, [key, paramString]) => {
    const paramsArray = params.getAll(key);
    const value = paramsArray.length > 1 ? paramsArray : paramString;
    return { ...queryParams, [key]: value };
  }, {});
};

export default getQueryParams;
