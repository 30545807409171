import Backbone from '@rover/globals/backbone';
import Rover from '@rover/globals/Rover';
import jQuery from '@rover/globals/jquery';
import _ from '@rover/globals/underscore';

import { apiUrl } from '@rover/utilities/apiUrl';

(function(Rover, $) {
  Rover.models.Image = Backbone.Model.extend({
    idAttribute: 'url',
    urlRoot: apiUrl('images/'),
    defaults: {
      thumb: null,
      image: null,
      caption: null,
      index: null,
    },
    url() {
      if (this.isNew()) return this.urlRoot;
      return this.get('url');
    },
  });

  Rover.models.LegacyImage = Backbone.Model.extend({
    defaults: {
      id: null,
      thumb: null,
      image: null,
      caption: null,
      index: null,
    },
  });

  Rover.models.ImageCollection = Backbone.Collection.extend({
    model: Rover.models.Image,
    initialize(models, opts) {
      // eslint-disable-next-line no-redeclare
      var opts = opts || {};
      this.objectType = opts.objectType || null;
      this.objectOpk = opts.objectOpk || null;

      // Allow specifying the collection URL on instantiation
      if (opts.url) {
        this.url = opts.url;
      }
      return this;
    },
    parse(resp, options) {
      return resp.results;
    },
    create(opts) {
      // eslint-disable-next-line no-redeclare
      var opts = _.extend(
          {
            url: this.url,
            addToCollection: true,
          },
          opts
        ),
        success = opts.success,
        self = this;

      opts.success = function(model) {
        if (opts.addToCollection) self.add(model);
        if (success) success(model);
      };

      this.uploadS3Image(opts);
    },
    validateFileSize(file) {
      if (file.size >= Rover.settings.imageUploadMaxSize) {
        return `File size too large. Got ${file.size}. Maximum is ${
          Rover.settings.imageUploadMaxSize
        }.`;
      }
    },
    uploadS3Image(opts) {
      var self = this,
        // eslint-disable-next-line no-redeclare
        opts = _.extend(
          {
            authorizeUrl: Rover.urls.imageUploadAuthorize,
          },
          opts
        ),
        $input = $('<input>')
          .attr('type', 'file')
          .fileupload(_.extend({}, opts.fileupload)),
        file = opts.file;

      // Handle uploading the file to the signed S3 url
      // and calling a callback on success
      // The original method's opts.error will be called
      // on error
      const uploadToS3 = function(file, url, cb) {
        $input.fileupload('send', {
          url,
          files: [file],
          type: 'PUT',
          multipart: false,
          beforeSend(xhr, data) {
            xhr.setRequestHeader('Content-Type', file.type);
            xhr.setRequestHeader('Content-Disposition', 'inline');
          },
          success(result, textStatus, jqXHR) {
            cb();
          },
          error: opts.error,
        });
      };

      // 1. Get a signed s3 url
      // 2. Upload our file to the signed s3 url
      // 3. POST the url to our collection root url to register it
      // 4. Add the created model to this collection and fire success
      $.ajax({
        method: 'POST',
        url: opts.authorizeUrl,
        data: {
          file_name: file.name,
          content_type: file.type,
          object_type: this.objectType,
          object_opk: this.objectOpk,
        },
        beforeSend: _.bind(function(xhr) {
          const error = this.validateFileSize(file);
          if (!error) return;
          opts.error(xhr, error, '');
          return false;
        }, this),
        success(authorizeData) {
          const s3Url = authorizeData.upload_url;
          uploadToS3(file, s3Url, () => {
            $.ajax({
              method: 'POST',
              url: self.url,
              data: {
                image_url: s3Url,
              },
              success(modelData) {
                const Model = self.model;
                const model = new Model(modelData);
                if (opts.success) opts.success(model);
              },
              error: opts.error,
            });
          });
        },
        error: opts.error,
      });
    },
  });

  Rover.collections.PersonImageCollection = Rover.models.ImageCollection.extend({
    url: `${Rover.urls.personImageListAPI}?page_size=2000`,
  });

  Rover.collections.PetImageCollection = Rover.models.ImageCollection.extend({});
})(Rover, jQuery);
