import { t } from '@lingui/macro';

import APIError from '@rover/react-lib/src/apis/APIError';
import DefaultAPIErrorHandler from '@rover/react-lib/src/apis/DefaultAPIErrorHandler';
import { getAxiosInstance } from '@rover/rsdk/src/modules/Network/axios';
import { snakeify } from '@rover/rsdk/src/modules/Network/object-utilities';
import { EmailVerificationStatus as EmailVerificationStatusEnum } from '@rover/types';
import { fullApiUrl } from '@rover/utilities/apiUrl';
import inject from '@rover/utilities/injector';

const emailVerificationUrl = fullApiUrl(`account/email-verification/code/redeem/`);
const emailChangeUrl = fullApiUrl(`account/email-update/`);
const resendEmailVerificationCodeUrl = fullApiUrl(`account/email-verification/code/generate/`);

export const verifyEmail = async (
  code: string,
  emailVerificationStatus?: string | undefined
): Promise<void> => {
  const ai = getAxiosInstance();
  const refresh =
    emailVerificationStatus === EmailVerificationStatusEnum.COMPULSORY ? { refresh: false } : {};
  const postData = snakeify({ code, ...refresh }, true);

  try {
    const url = inject('Rover.urls.emailVerificationAPI', '') || emailVerificationUrl;
    await ai.post(url, postData);
  } catch (error: any) {
    const { response } = error;
    const message =
      (response && response.data && response.data.message) ||
      t`Something went wrong. Please try again.`;
    const status = (response && response.data && response.data.status) || 500;
    throw new APIError(message, status);
  }
};

export const updateEmail = async (email: string): Promise<void> => {
  const ai = getAxiosInstance();

  try {
    const url = inject('Rover.urls.emailChangeRequestAPI', '') || emailChangeUrl;
    const postData = snakeify({ email }, true);

    await ai.post(url, postData);
  } catch (error: any) {
    const { response } = error;
    const message =
      (response && response.data && response.data.message) ||
      t`There was an error while changing the email.`;
    const status = (response && response.data && response.data.status) || 500;
    throw new APIError(message, status);
  }
};

export const resendCode = async (refreshValue?: boolean): Promise<void> => {
  const ai = getAxiosInstance();

  try {
    const url =
      inject('Rover.urls.resendEmailVerificationCodeAPI', '') || resendEmailVerificationCodeUrl;

    const postData = { refresh: refreshValue };

    await ai.post(url, postData);
  } catch (error: unknown) {
    throw DefaultAPIErrorHandler(
      error as Error,
      t`There was an error while generating email verification code.`
    );
  }
};
