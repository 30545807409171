import dataLayer from '@rover/globals/dataLayer';
import $ from '@rover/globals/jquery';
import Rover from '@rover/globals/Rover';

import { FacebookUtils, SocialUtils } from './utils';

// Facebook hooks
FacebookUtils.setUpFBAsyncInit();

// Dynamically load social network SDKs
SocialUtils.loadSocialScripts();

Rover.facebook = Rover.facebook || {};
Rover.googlePlus = Rover.googlePlus || {};

// A function that will be called by every social action callback
// function with its social network and action. Example
//
// 'facebook', 'share'
//
// Specific pages can implement this function to perform
// page-specific actions
Rover.trackSocial = (socialNetwork, action, contentType, objectId) => {
  if (objectId && contentType) {
    $.ajax({
      type: 'POST',
      url: Rover.urls.analyticsTrackShare,
      data: JSON.stringify({
        action,
        content_type: contentType || null,
        object_id: objectId || null,
        social_network: socialNetwork,
      }),
      contentType: 'application/json; charset=utf-8',
      dataType: 'json',
      success(data) {},
      failure(err) {},
    });
  }
};

Rover.postShareCallback = (socialNetwork, action, response) => {
  Rover.trackSocial(socialNetwork, action);

  // Allow for page-specific behavior after a share
  // (e.g. scrapsbook.js pops up a "thanks for sharing" modal)
  Rover.pageSpecificPostShareCallback = Rover.pageSpecificPostShareCallback || (() => {});
  Rover.pageSpecificPostShareCallback(socialNetwork, action, response);
};

// Callback for plus one events. Has to be global.
Rover.googlePlus.plusOneClick = (response) => {
  if (response.state === 'on') {
    Rover.postShareCallback('g_plus', 'share');
  }
};

// Log in via Facebook's FB API, returning Facebook auth credentials
Rover.facebook.login = (successCallback, errCallback, scope) => {
  FacebookUtils.isInitialized().then((FB) => {
    const errorCallback = $.isFunction(errCallback)
      ? errCallback
      : FacebookUtils.defaultFBLoginError;

    FB.login(
      (response) => {
        if (response.status === 'connected') {
          // Reset any DOM state related to connecting
          $('.js-need-connect-to-share').addClass('hide');

          Rover.facebook.connected = true;

          if ($.isFunction(successCallback)) {
            successCallback({
              access_token: response.authResponse.accessToken,
              signed_request: response.authResponse.signedRequest,
              user_id: response.authResponse.userID,
            });
          }
        } else {
          if (response.status === 'not_authorized') {
            dataLayer.push({
              event: 'account-sign-in-facebook',
              submitStatus: 'fail',
              errorMessage: 'not_authorized',
            });
          } else {
            dataLayer.push({
              event: 'account-sign-in-facebook',
              submitStatus: 'fail',
              errorMessage: 'facebook-login-error',
            });
          }

          errorCallback(response);
        }
      },
      // Specify which permissions we're requesting: email address
      { scope: scope || 'email' }
    );
  });
};

Rover.facebook.signup = (successCallback, errCallback) => {
  const errorCallback = $.isFunction(errCallback)
    ? errCallback
    : FacebookUtils.defaultFBSignupError;
  const wrappedSuccessCallback = (authData) => {
    if ($.isFunction(successCallback)) {
      dataLayer.push({
        event: 'account-sign-up-facebook-sign-up',
        submitStatus: 'success',
      });
      dataLayer.push({
        event: 'sign_up',
        method: 'facebook_js',
        // TODO - if these fire, find a way to get person_opk / account_zip
      });
      successCallback(authData);
    }

    const $form = $('.js-facebook-register');
    $('[name=access_token]', $form).val(authData.access_token);
    $('[name=signed_request]', $form).val(authData.signed_request);
    $('[name=facebook_id]', $form).val(authData.user_id);
    $form.attr('href', $('input[name=next]').first().val());
    $form.submit();
  };

  Rover.facebook.login(wrappedSuccessCallback, errorCallback);
};

// UI element click/submit bindings
// Facebook connect/signup/share
$(document).on('click', '.FBui', FacebookUtils.clickHandlers.handleFBuiClick);
$(document).on(
  'click',
  '.js-connect-facebook',
  FacebookUtils.clickHandlers.handleConnectButtonClick
);

// Pinterest - open a popup window
$(document).on('click', '.pin-it-button', SocialUtils.handlePinItClick);

// Photo sharing
$(document).on('click', '.js-social-share-photo-button', SocialUtils.handleSocialSharePhoto);
