import Backbone from '@rover/globals/backbone';

export default Backbone.Collection.extend({
  setSelected(slug) {
    const selected = this.getSelected();
    const toSelect = this.getBySlug(slug);

    if (selected) {
      selected.set('selected', false);
    }

    if (toSelect) {
      toSelect.set('selected', true);
    } else {
      this.first().set('selected', true);
    }
  },
  getSelected() {
    return this.findWhere({ selected: true });
  },
  getBySlug(slug) {
    return this.findWhere({ slug });
  },
});
