import type { DataLayerEvent } from '@rover/types';
import type { AnalyticsEvent } from '@rover/types/src/Analytics';

import ObservabilityMetadata from '../ObservabilityMetadata';

const analyticsEventToDataLayerEvent = (analyticsEvent: AnalyticsEvent): DataLayerEvent => {
  const { concept, locale, team, uiComponentName, uiName } = ObservabilityMetadata.data();
  const tags = {
    ...analyticsEvent.observabilityTags,
    ...(concept && { concept }),
    ...(locale && { locale }),
    ...(team && { team }),
    ...(uiComponentName && { uiComponentName }),
    ...(uiName && { uiName }),
  };

  // All actual event data is wrapped in the 'eventData' property / GTM variable
  const eventData = {
    // eventName will be extracted and used as the actual Event Name
    eventName: analyticsEvent.eventName,
    // properties specific to this event
    ...analyticsEvent.eventProperties,
    // only send observability tags if we have some defined
    ...(Object.keys(tags).length > 0 ? { observability_tags: tags } : {}),
    // always send to the eventstream backend - use emitMetric to send observability_only: true
    observability_only: false,
  };

  return {
    // Send the event to the appropriate GTM tag for
    // the Event's designated Consent Management purpose
    event: `analytics-event-${analyticsEvent.consentPurpose}`,
    // NOTE:  we are stringifying this because for a given GTM variable, sending
    // another event MERGES the new value of eventData with the old value,
    // if the value is of type object.  Didn't ask for this helpful "feature",
    // so we stringify here, to reset the variable each time.
    eventData: JSON.stringify(eventData),
  };
};

export default analyticsEventToDataLayerEvent;
