// Handle popovers like we used to, but using new bootstrap
// http://stackoverflow.com/questions/7703878/how-can-i-hold-twitter-bootstrap-popover-open-until-my-mouse-moves-into-it
import $ from '@rover/globals/jquery';
import Rover from '@rover/globals/Rover';

const popoverTemplate = (className = '') => `
    <div class="popover ${className}" onmouseover="clearTimeout(Rover.timeoutObj);$(this).mouseleave(function() {$(this).popover('hide');});">
        <div class="arrow"></div>
        <div class="popover-inner">
            <h3 class="popover-title"></h3>
            <div class="popover-content">
                <p></p>
            </div>
        </div>
    </div>`;

Rover.timeoutObj = false;
Rover.stickyPopover = function stickyPopover(selector, { className, ...option }) {
  const $elts = $(selector);
  if (!$elts.length) {
    return;
  }

  const options = $.extend(
    {
      template: option.template ? option.template : popoverTemplate(className),
      html: true,
      container: '.js-popover-container',
      trigger: 'manual',
      closeOnBodyClick: true,
    },
    option
  );

  function onBodyClicked(e) {
    const $target = $(e.target);
    if (options.closeOnBodyClick) {
      $('.popover').not($target.parent()).not($target).popover('hide');
    }
  }

  // eslint-disable-next-line func-names
  $(selector).each(function () {
    const self = this;
    const $popover = $(this).popover('destroy').popover(options);

    let touched = false;
    let showing = false;
    const showPopover = () => {
      $(self).popover('show');
      showing = true;
    };
    const hidePopover = () => {
      $(self).popover('hide');
      showing = false;
    };

    $popover
      .off('touchstart')
      .on('touchstart', () => {
        // prevents double open/flash
        touched = true;
        setTimeout(() => {
          touched = false;
        }, 150);

        if (showing) hidePopover();
        else showPopover();
      })
      .off('mouseenter')
      .on('mouseenter', () => {
        // prevents double open/flash
        if (touched) return;
        showPopover();
      })
      .off('mouseleave')
      .on('mouseleave', () => {
        Rover.timeoutObj = setTimeout(hidePopover, 50);
      })
      .off('focus')
      .on('focus', () => {
        if (!showing) showPopover();
      })
      .off('blur')
      .on('blur', () => {
        if (showing) hidePopover();
      })
      .off('keypress')
      .on('keypress', (e) => {
        if (e.key !== 'Enter' && e.key !== ' ') return;
        e.preventDefault();
        if (showing) hidePopover();
        else showPopover();
      })
      .off('keydown')
      .on('keydown', (e) => {
        if (e.key !== 'Escape') return;
        if (showing) hidePopover();
      });

    $popover.off('show.bs.popover').on('show.bs.popover', () => {
      $('body').on('click', onBodyClicked);

      $(self)
        .off('hide.bs.popover')
        .on('hide.bs.popover', () => {
          $('body').off('click', onBodyClicked);
          $(self).off('hide.bs.popover');
        });
    });
  });
};
