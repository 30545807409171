import $ from '@rover/globals/jquery';
import Rover from '@rover/globals/Rover';
import _ from '@rover/globals/underscore';
import dataLayer from '@rover/globals/dataLayer';

import i18n from '@rover/utilities/translation';
import ModalView from '../components/rover_modal';

import '../backbone/views/archive_modal_view';

$(() => {
  let $conversation = null;
  let saveOptions = null;
  let conversationDetailUrl = null;
  const SOURCE_HEADER = Rover.api.getSourceHeader(
    $('.js-book-or-archive-actions').length > 0 ? 'web/inbox-pending' : 'web/conversation-detail'
  );

  const saveAndReload = function(options) {
    save(options, () => {
      location.reload(true);
    });
  };

  const saveAndStay = function(options) {
    save(options, showArchiveSuccessModal);
  };

  const save = function(options, onSuccess) {
    saveOptions = options;

    if (
      typeof options.shouldAcceptNonSpayed !== 'undefined' ||
      typeof options.shouldAcceptNonNeutered !== 'undefined'
    ) {
      $.ajax({
        url: Rover.urls.serviceHostingPreferences,
        method: 'PUT',
        contentType: 'application/json',
        data: JSON.stringify({
          accepts_unspayed_females: options.shouldAcceptNonSpayed,
          accepts_non_neutered_males: options.shouldAcceptNonNeutered,
        }),
        success() {
          archive(onSuccess);
        },
        error: onSaveError,
      });
    } else {
      archive(onSuccess);
    }
  };

  var onSaveError = function() {
    const failureModal = new ModalView({
      title: i18n._('Failure'),
      content: i18n._('Your preferences could not be updated. Please try again in a few moments.'),
      showFooter: true,
      disableCancel: true,
      okCloses: true,
    });
    failureModal.open();
  };

  var archive = function(onSuccess) {
    $.ajax({
      url: conversationDetailUrl,
      method: 'PUT',
      contentType: 'application/json',
      headers: SOURCE_HEADER,
      data: JSON.stringify({
        provider_archived_status: saveOptions.reason ? { reason: saveOptions.reason } : null,
      }),
      success: onSuccess,
      error: showArchiveErrorModal,
    });
    window.archive_modal.togglePrimaryButton(false, true);
  };

  var showArchiveSuccessModal = function() {
    const confirmationModal = new ModalView({
      title: i18n._('Success'),
      content: i18n._('Your conversation has been archived'),
      showFooter: true,
      cancelText: i18n._('Undo'),
      okCloses: true,
    });

    confirmationModal.on('cancel', () => {
      undoArchive();
    });

    window.archive_modal.close();
    confirmationModal.open();
    toggleUndoButton(true);
  };

  var showArchiveErrorModal = function() {
    const failureModal = new ModalView({
      title: i18n._('Failure'),
      content: i18n._('Your conversation couldn’t be archived. Please try again in a few moments.'),
      showFooter: true,
      disableCancel: true,
      okCloses: true,
    });

    failureModal.open();
    toggleUndoButton(false);
  };

  var undoArchive = function(onSuccess) {
    $.ajax({
      url: conversationDetailUrl,
      method: 'PUT',
      contentType: 'application/json',
      headers: SOURCE_HEADER,
      data: JSON.stringify({
        provider_archived_status: null,
      }),
      success() {
        if (onSuccess) {
          onSuccess();
        } else {
          toggleUndoButton(false);
        }
      },
      error: showArchiveUndoErrorModal,
    });
  };

  var showArchiveUndoErrorModal = function() {
    const failureModal = new ModalView({
      title: i18n._('Failure'),
      content: i18n._('Unable to undo archiving. Please try again in a few moments.'),
      showFooter: true,
      disableCancel: true,
      okCloses: true,
    });
    failureModal.open();
    $('.js-cta-unarchive-conversation').btn('reset');
  };

  var toggleUndoButton = function(show) {
    $conversation.find('.js-archive-complete').toggle(show);
    $conversation.find('.js-move-to-archive').toggle(!show);
  };

  const showModalHandler = modalArgs => event => {
    const archiveConversationBtn = $(event.currentTarget);
    $conversation = archiveConversationBtn.closest('.js-conversation-container');
    const currentOpk = $conversation.attr('data-opk');
    conversationDetailUrl = $conversation.data('conversation-detail-url');

    const eventData = _.extend(
      {},
      { event: archiveConversationBtn.data('event') },
      archiveConversationBtn.data('event-merge')
    );
    dataLayer.push(eventData);

    window.archive_modal = new Rover.views.ArchiveModalView({
      title: i18n._('Archive this request'),
      content: $('.js-archive-modal'),
      showFooter: true,
      cancelText: null,
      okText: i18n._('Save'),
      conversationDetailUrl,
      ...modalArgs,
    });

    window.archive_modal.open();
  };

  $('.js-cta-archive-conversation').on(
    'click',
    showModalHandler({
      okIcon: 'fr-spin rover-icon rover-icon-in-spinner archive-modal-loading-icon',
      onSave: saveAndReload,
      okCloses: false,
    })
  );

  $('.js-move-to-archive').on(
    'click',
    showModalHandler({
      okCloses: true,
      onSave: saveAndStay,
    })
  );

  $('.js-undoarchive').on('click', () => {
    undoArchive();
  });

  $('.js-cta-unarchive-conversation').on('click', () => {
    const unarchiveConversationBtn = $(event.currentTarget);
    unarchiveConversationBtn.btn('loading');
    $conversation = unarchiveConversationBtn.closest('.js-conversation-container');
    conversationDetailUrl = $conversation.data('conversation-detail-url');
    undoArchive(() => {
      location.reload();
    });
  });
});
