import type Url from 'domurl';

declare global {
  interface Window {
    Url: Url<any>;
  }
}

if (typeof window.Url === 'undefined') {
  // eslint-disable-next-line
  const Url = require('domurl');

  window.Url = Url;
}

export default window.Url;
