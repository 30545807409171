import { plural, t } from '@lingui/macro';

import $ from '@rover/globals/jquery';
import Rover from '@rover/globals/Rover';
import _ from '@rover/globals/underscore';
import { capitalizeFirstLetter } from '@rover/utilities/string';
import i18n from '@rover/utilities/translation';

import ConversationModel from './conversation';
import RequestableModel from './requestable';

Rover.models.RecurringBillingModel = RequestableModel.extend({
  isRecurring: true,
  initialize() {
    this.on('change:days change:start_date', () => {
      this.trigger('datesChanged');
    });
  },
  getOpk() {
    return this.getActiveConversation().getOpk();
  },
  getActiveConversation() {
    return new ConversationModel(this.get('active_conversation'));
  },
  getServiceType() {
    return this.getActiveConversation().getServiceType();
  },
  getRelationship() {
    return this.getActiveConversation().getRelationship();
  },
  getState() {
    return this.getActiveConversation().getState();
  },
  getDatesData() {
    return {
      start_date: this.get('start_date'),
      days: this.get('days'),
    };
  },
  unitsAndDatesAsSentence() {
    let out = '';
    const days = this.daysListMap((day) => day).join(', ');
    if (this.getActiveConversation().supportsNumUnitsPerDay()) {
      const numUnitsPerDay = this.getNumUnitsPerDay();
      out = plural({
        value: numUnitsPerDay,
        one: '# time per day',
        other: '# times per day',
      });
    }

    return i18n._(t`${out} weekly on ${days}`);
  },
  daysAsSentence() {
    return this.daysListMap((day) => day).join(', ');
  },
  daysMap(func) {
    return _.map(this.get('days'), func);
  },
  dayI18n(day) {
    return i18n._(capitalizeFirstLetter(day));
  },
  daysListMap() {
    return this.daysMap((day) => this.dayI18n(day.day).slice(0, 3));
  },
  daysList() {
    return this.daysListMap((day) => day.slice(0, 3));
  },
  getNumUnitsPerDay() {
    const days = this.get('days');

    if (days.length === 0) {
      return 1;
    }

    return days[0].num_units;
  },
  getPerDaySummary() {
    const numUnitsPerDay = this.getNumUnitsPerDay();
    const daysAsSentence = this.daysAsSentence();
    if (this.getServiceType().is_daytime_traveling) {
      const longAddOns = ['long-walk', 'long-drop-in'];
      const minuteCount =
        this.attributes.booking_add_ons.filter((sao) => longAddOns.includes(sao.slug)).length > 0
          ? '60'
          : '30';
      return i18n._(
        /* i18n: {0} wil be num units per day, {1} will be num of minutes, {2} will be list of week days */
        t`${numUnitsPerDay} per day • ${minuteCount} min • repeats weekly on ${daysAsSentence}`
      );
    }
    return i18n._(
      /* i18n: {0} wil be num units per day, {1} will be list of week days */
      t`${numUnitsPerDay} per day • repeats every ${daysAsSentence}`
    );
  },
  saveNumUnitsPerDay(numUnitsPerDay, optimisticAttrUpdate) {
    const newDays = this.daysMap((day) => ({
      day: day.day,
      num_units: numUnitsPerDay,
    }));

    return this.saveDraftPricing({ days: newDays }, optimisticAttrUpdate);
  },
  shadowSave(attributes) {
    // For some operations (i.e. create request, cancel request) the API
    // expects the client to set some model attributes to specific values,
    // call PUT, then it will return the model with updated attributes.
    //
    // There may be many Views listening to attributes on this model. If
    // we have to change one of those attributes to facilitate the the
    // specific API operation (like 'request') then some listeners may
    // be triggered even though we didn't set that attribute to a normal
    // state.
    //
    // To work around this trouble, we're creating a copy of the model
    // which includes all of that model's attributes, saving it, and
    // when that save succeeds, updating the original with the new
    // attributes.
    //
    // Alternatives to this approach include:
    // 1. Use this.set(attributes, {silent: true}) which will suppress
    //    triggers. If the subsequent save fails, however, the model
    //    could be left in an invalid state. Also, the Backbone docs
    //    say that you should probably never use {silent: True}
    // 2. Put a property on the Conversation model so that it can track
    //    when this save is in flight. All listeners on request should
    //    ignore changes while it is in flight. Once the save completes,
    //    disable the flag.
    const shadowAttributes = {
      ...this.attributes,
      ...attributes,
    };
    const shadow = new Rover.models.RecurringBillingModel(shadowAttributes);
    shadow.set('booking_add_ons', shadow.getActiveBookingAddOns());
    return shadow.save().then(
      (data) => {
        this.set(data);
      },
      (err) => {
        let confirmation;
        const nonFieldErrors = err.responseJSON.non_field_errors || [];
        if (err.status === 409) {
          // eslint-disable-next-line no-restricted-globals, no-alert
          confirmation = confirm(
            i18n._(
              'This request has been updated since you loaded this page. Press OK to see up-to-date details.'
            )
          );
        } else if (err.status === 400) {
          if (err.responseJSON?.error_type[0] === 'start_date_in_past') {
            // eslint-disable-next-line no-restricted-globals, no-alert
            confirmation = confirm(err.responseJSON?.error_description[0]);
          } else if (nonFieldErrors.includes('free bookings are not allowed')) {
            // eslint-disable-next-line no-restricted-globals, no-alert
            confirmation = confirm(i18n._('Free bookings are not allowed.'));
          }
        }

        if (confirmation) {
          // eslint-disable-next-line no-restricted-globals
          location.reload();
        }
        return err;
      }
    );
  },
  getDraftPricing(options) {
    const uri = this.get('urls').pricing;
    const request = {
      service: this.get('service'),
      requester: this.get('requester').url,
      pets: _.pluck(this.get('pets'), 'url'),
      days: this.get('days'),
      start_date: this.get('start_date'),
      booking_add_ons: this.get('booking_add_ons'),
      ...options,
    };

    return $.ajax({
      method: 'POST',
      url: uri,
      data: JSON.stringify(request),
      contentType: 'application/json',
    });
  },
  savePricingDataPostBooking(data) {
    let requestSet = false;
    if (this.get('request') === null) {
      this.set('request', true);
      requestSet = true;
    }

    return this.savePricingData(data).fail((err) => {
      if (requestSet) this.set('request', null);
      this.handleError(err);
    });
  },
  hasPaymentIssue() {
    // If a recurring billing conversation has a payment issue it should be retried via the retry-checkout page
    // that handles the conversation directly rather than the recurring billing model.
    return false;
  },
});
