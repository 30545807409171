import Rover from '@rover/globals/Rover';
import Backbone from '@rover/globals/backbone';
import Mustache from '@rover/globals/Mustache';
import moment from '@rover/globals/moment';
import $ from '@rover/globals/jquery';
import _ from '@rover/globals/underscore';
// WARNING: DF from datetimeInjected is not SSR compatible.
// For newer code, use const DF = getDateTimeFormatMapForLang(<lang>) from '@rover/shared/js/constants/i18n/datetime'
import DF from '@rover/shared/js/constants/i18n/datetime/datetimeInjected';

window.Rover = window.Rover || {};
Rover.views = Rover.views || {};

(function(ns) {
  'use strict';

  ns.DatePicker = Backbone.View.extend({
    elementSelector: '.js-date',

    template: Mustache.template('new_design/common/datepicker'),

    initialize(options) {
      _.bindAll(this, '_onModelChange', '_onDateSelect');
      this.field = options.field || 'date';
      this.model.on(`change:${this.field}`, this._onModelChange);
      this._context = {
        label: options.label,
        slug: options.slug,
        dateText: options.dateText || 'Date',
      };
      this.datePickerOptions = Object.assign(
        {
          dateFormat: DF.DATE_PICKER,
        },
        options.datePickerOptions || {}
      );
    },

    render() {
      let $element,
        opts = $.extend(
          {
            onSelect: this._onDateSelect,
            changeMonth: false,
            numberOfMonths: 1,
            defaultStartDate: '+0d',
            minDate: new Date(),
          },
          this.datePickerOptions
        );

      const context = _.extend(this._context, {
        date: this.model.get(this.field),
      });
      this.$el.html(this.template.render(context));
      $element = this.$(this.elementSelector);
      $element.modalFriendlyDatepicker(opts);
      this._clearErrors();
      return this;
    },

    _clearErrors() {
      this.$el.closest('.form-group').removeClass('has-error');
    },

    _onModelChange() {
      this.render();
    },

    _onDateSelect() {
      const date = this.$(this.elementSelector).datepicker('getDate');
      this.$el.trigger('selected.datepicker');
      this.$(':input').trigger('blur');
      this.model.set(this.field, moment(date).format(DF.DATE_SHORT));
    },
  });
})(Rover.views);
