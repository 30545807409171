import $ from '@rover/globals/jquery';

$(() => {
  if (!$('body').hasClass('js-account-notifications')) {
    return;
  }
  $(document).on('change', '.js-notify-opt-out', function() {
    const $el = $('.js-granular-notify-controls');
    if ($(this).is(':checked')) {
      $el.fadeOut();
    } else {
      $el.fadeIn();
    }
  });
  $(document).on('change', '.js-quiet-hours', function() {
    const $el = $('.js-quiet-hours-row');
    if ($(this).is(':checked')) {
      $el.fadeIn();
    } else {
      $el.fadeOut();
    }
  });
});
