import Rover from '@rover/globals/Rover';
import Backbone from '@rover/globals/backbone';

Rover.models = Rover.models || {};
(function() {
  Rover.models.Service = Backbone.Model.extend({
    idAttribute: 'url',
    initialize(options) {
      this.url = options.url;
    },
  });
})();

Rover.models.ServiceModel = Backbone.Model.extend({
  url() {
    return this.instanceUrl;
  },
  initialize(options) {
    this.instanceUrl = options.url;
  },
});
