import Clipboard from 'clipboard';

export { capitalizeFirstLetter } from './capitalizeFirstLetter';

export const possessive = (str: string): string => {
  if (str.slice(-1).toLowerCase() === 's') {
    return `${str}'`;
  }

  return `${str}'s`;
};

type Selector = string | Element | NodeListOf<Element>;

export const copyTextToClipboard = (
  triggerSelector: Selector,
  successCallback: (e: Clipboard.Event) => void
): void => {
  let callbackForSuccess = successCallback;
  if (typeof successCallback !== 'function') callbackForSuccess = () => {};
  const board = new Clipboard(triggerSelector);
  board.on('success', callbackForSuccess);
  board.on('error', () => {});
};

/**
 * Converts a string to sentence case. Note that this will lowercase all characters except the first one,
 * including for proper nouns.
 *
 * e.g. "Hello World" -> "Hello world"
 */
export const sentenceCase = (str: string): string => {
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};

const exportedString = {
  possessive,
  copyTextToClipboard,
  sentenceCase,
};

export default exportedString;
