import moment from 'moment';
import Week from '@rover/utilities/Week';

$.fn.modalFriendlyDatepicker = function(options) {
  let datepickerArgs = arguments,
    self = $.fn.modalFriendlyDatepicker;

  // If this is a method call, pass it straight
  // through to $.datepicker
  if (_.isString(options)) {
    return this.datepicker.apply(this, datepickerArgs);
  }

  return this.each(function() {
    // Modify options with new positioning algorithm
    options = options || {};
    options = Object.assign({}, options, {
      beforeShow: self.getBeforeShow(options.beforeShow),
      // Set calendar first day from Rover.context for localisation
      firstDay: Week.firstDayOfWeek(),
      /*
        jQuery ui datepicker adds value of the first day to the values of weekday represented in the below
        and when the first day is 1(for EU users) the week will start from Tuesday
      */

      dayNamesMin: [0, 1, 2, 3, 4, 5, 6].map(n => Week.upperShortName(n - Week.firstDayOfWeek())),
      monthNames: moment.months(),
      monthNamesShort: moment.monthsShort(),
    });
    // Instantiate datepicker with new algorithm
    $(this).datepicker(options);
  });
};

/**
 * Creates a $.datepicker beforeShow function
 * that updates the positioning algorithm to work well in
 * scrollable modals
 *
 * @param {Fn} originalBeforeShow
 */
$.fn.modalFriendlyDatepicker.getBeforeShow = function(originalBeforeShow) {
  const self = this;
  const beforeShow = function(input, instance) {
    let $input = $(input),
      $modal = $input.closest('.modal-dialog'),
      $datePicker = $('#ui-datepicker-div');

    // If input is in a modal
    if ($modal.length && !$modal.parent().hasClass('mobile-friendly-non-modal-datepicker')) {
      let datepickerW = self.getWidth($datePicker),
        datepickerPosition = $input.position(),
        modalW = $modal.outerWidth(),
        modalL = $modal.offset().left,
        inputT = $input.offset().top,
        inputL = $input.offset().left,
        inputH = $input.outerHeight();

      // If datepicker will go outside modal to the right, pull it back
      datepickerPosition.left -= self.getLeftAdjustment(datepickerW, modalW, modalL, inputL);

      // Push datepicker below input
      datepickerPosition.top = inputT + inputH;
      datepickerPosition.left += inputL;

      // Set position styles after jQuery UI has set its styles
      self.setPosition($datePicker, datepickerPosition.left, datepickerPosition.top);
    }

    // Call supplied beforeShow callback
    if (_.isFunction(originalBeforeShow)) {
      originalBeforeShow(input, instance);
    }
  };

  return beforeShow;
};

/**
 * Overrides the datepicker position
 * @param {jQuery obj} $datePicker
 * @param {Obj} pos Object with left and right keys
 */
$.fn.modalFriendlyDatepicker.setPosition = function($datePicker, left, top) {
  // Set styles after jQuery UI has set its styles
  _.defer(() => {
    if (
      Rover.userAgent.isMobile &&
      $datePicker
        .parent()
        .find('input')
        .is('.js-end-date')
    ) {
      $datePicker.css({
        left: 'inherit',
        right: '0',
        top,
        position: 'absolute',
      });
    } else {
      $datePicker.css({
        left,
        top,
        position: 'absolute',
      });
    }
  });
};

/**
 * Gets the width of the datepicker.
 *
 * @param  {jQuery obj} $datePicker
 * @return {Int}        Width in px of the datepicker
 */
$.fn.modalFriendlyDatepicker.getWidth = function($datePicker) {
  $datePicker.css({ position: 'absolute', display: 'block', top: '-1000px' });
  const dpWidth = $datePicker.outerWidth();
  $datePicker.css('display', 'none');
  return dpWidth;
};

/**
 * Gets the left adjustment needed for the datepicker, given
 * that it might go outside the modal if the unput is too close to the edge.
 *
 * @param  {Int} datepickerW
 * @param  {Int} modalW
 * @param  {Int} modalL Left offset of modal relative to the document
 * @param  {Int} inputL Left offset of input relative to the document
 * @return {Int}        Pixels datepicker must move to the left to remain in modal
 */
$.fn.modalFriendlyDatepicker.getLeftAdjustment = function(datepickerW, modalW, modalL, inputL) {
  let spaceAvailable = modalW + modalL - inputL,
    notEnoughRoom = datepickerW > spaceAvailable;
  return notEnoughRoom ? datepickerW - spaceAvailable : 0;
};
