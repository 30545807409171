/* global
    $:true,
    _:true,
    Backbone:true,
    Rover:true
*/

// eslint-disable-next-line filenames/match-regex
(() => {
  Rover = Rover || {};
  Rover.views = Rover.views || {};

  const HeaderNavBarView = Backbone.View.extend({
    el: '.js-header-navbar',

    initialize() {
      this.$toggleBtn = $('.js-show-our-services');
      this.$ourServices = $('.js-our-services--dropdown');
      // Default tab indices to -1
      this.$el.find('.js-our-services-item a').attr('tabIndex', -1);
      // Set data-qa-id for testing
      this.$ourServices
        .find('a')
        .first()
        .attr('data-qa-id', 'home-services-bar-links-dog-boarding');
    },

    events: {
      'click .js-show-our-services': 'toggleAllServices',
      'keydown ul.js-authenticated-header li.dropdown': 'handleMenuKeydown',
      'keydown ul.language-selector li.dropdown': 'handleLangMenuKeydown',
    },

    handleMenuKeydown(e) {
      // allow spacebar to toggle the dropdown when the target is the menu toggle button
      if (e.target.id === 'logged-in-user-dropdown-menu' && e.keyCode === 32) {
        e.preventDefault();
        $('#logged-in-user-dropdown-menu').dropdown('toggle');
      }
      // up & down arrow keys, adapted from vendor/bootstrap.js where it wasn't working right ¯\_(ツ)_/¯
      if (e.keyCode === 38 || e.keyCode === 40) {
        e.preventDefault();
        const menuItems = $('ul.js-authenticated-header li.dropdown').find(
          '.dropdown-toggle, ul>li:not(.divider) a'
        );
        let focusedItemIndex = menuItems.index(menuItems.filter(':focus'));
        if (e.keyCode === 38 && focusedItemIndex > 0) focusedItemIndex -= 1; // up
        if (e.keyCode === 40 && focusedItemIndex < menuItems.length - 1) focusedItemIndex += 1; // down
        menuItems.eq(focusedItemIndex).focus();
      }
    },

    handleLangMenuKeydown(e) {
      // allow spacebar to toggle the dropdown when the target is the menu toggle button
      if (e.target.id.includes('navbar-language-selector') && e.keyCode === 32) {
        e.preventDefault();
        $(`#${e.target.id}`).dropdown('toggle');
      }
      // up & down arrow keys, adapted from vendor/bootstrap.js where it wasn't working right ¯\_(ツ)_/¯
      if (e.keyCode === 38 || e.keyCode === 40) {
        e.preventDefault();
        const menuItems = $('ul.language-selector li.dropdown').find(
          '.dropdown-toggle, ul>li:not(.divider) a'
        );
        let focusedItemIndex = menuItems.index(menuItems.filter(':focus'));
        if (e.keyCode === 38 && focusedItemIndex > 0) focusedItemIndex -= 1; // up
        if (e.keyCode === 40 && focusedItemIndex < menuItems.length - 1) focusedItemIndex += 1; // down
        menuItems.eq(focusedItemIndex).focus();
      }
    },

    toggleAllServices(e) {
      e.preventDefault();
      if (this.$el.hasClass('show-all-services')) {
        this.hideAllServices();
      } else {
        this.showAllServices();
      }
    },

    showAllServices() {
      this.$el.addClass('show-all-services');
      this.$ourServices.css({
        transform: `translateY(${$('.navbar-header').outerHeight()}px)`,
        visibility: 'visible',
      });
      this.$toggleBtn.attr('aria-expanded', true);
      this.$el.find('.js-our-services-item a').attr('tabIndex', 0);
    },

    hideAllServices() {
      this.$el.removeClass('show-all-services');
      this.$ourServices.css({
        transform: 'translateY(-100%)',
        visibility: 'hidden',
      });
      this.$toggleBtn.attr('aria-expanded', false);
      this.$el.find('.js-our-services-item a').attr('tabIndex', -1);
    },
  });
  Rover.views.HeaderNavBarView = new HeaderNavBarView();
})();

// When the navbar is sticky, toggle the class name `js-navbar-is-sticky` on <body>.
// This supports CSS that needs to target the navbar only when it is actively sticky.
(() => {
  const { body } = document;

  const stickyNavbarIsEnabled = () => body.classList.contains('sticky-navbar');

  const navbarIsSticky = () =>
    // If we have scrolled at all,
    window.pageYOffset > 0 &&
    // and we have scrolled enough for the navbar to have moved,
    // then the navbar is sticky.
    window.pageYOffset === document.querySelector('.js-navbar-container').offsetTop;

  // Do not use `.toggle(...)` - IE11 implements it incorrectly:
  // https://developer.microsoft.com/en-us/microsoft-edge/platform/issues/11865865/
  const toggleClass = (element, className, shouldToggle) =>
    element.classList[shouldToggle ? 'add' : 'remove'](className);

  const toggleStickyNavbarClassName = () =>
    requestAnimationFrame(() => toggleClass(body, 'js-navbar-is-sticky', navbarIsSticky()));

  if (stickyNavbarIsEnabled()) {
    const millisecondsPerFrame = 1000 / 60;

    // Throttle calls to limit unnecessary layouts and paints
    const throttledToggle = _.throttle(toggleStickyNavbarClassName, millisecondsPerFrame * 12);

    // Calculate initial state
    throttledToggle();
    // Recalculate on each scroll
    $(document).on('scroll', throttledToggle);
    // Recalculate in case of breakpoint changes
    $(window).on('resize', throttledToggle);
  }
})();
