/**
 * The blocking modal mechinism is quite fragile and confusing, so here is a bit of an
 * explanation.
 *
 * 1. We load from the API a check to see if any blocking modals should display
 * 2. If they should, we load _an entirely new Django rendered HTML page for the modal's contents_ (including new JS script tags and all)
 * 3. We then append the entire new document to the existing page (with no iFrame isolation or anything)
 *
 * This is pretty dangerous for a few reasons, but primarily because we are loading an entirely new page's worth of
 * JS that _could_ interact with the existing page in unpredictable ways. A recent issue we have seen with this
 * architecture is fixed below (*) in which we were loading a second Webpack runtime that broke the parent runtime.
 * A second fix (+) was for a bug that caused blocking modals to infinitely recurse and load themselves over and
 * over again.
 *
 * All this to say, be careful in here and test changes thoroughly.
 */
import Backbone from '@rover/globals/backbone';
import $ from '@rover/globals/jquery';
import Rover from '@rover/globals/Rover';
import _ from '@rover/globals/underscore';
import { mountEmailVerification } from '@rover/react-lib/src/pages/account/EmailVerificationPage/';
import { closeDialog } from '@rover/utilities/embedded';

import ModalView from './rover_modal';

((document) => {
  const blockingModalPubSub = {};
  _.extend(blockingModalPubSub, Backbone.Events);

  function getModalContent(result) {
    // Reject if there is no blocking_modal or blocking_modal.web_url
    if (!result || !result.blocking_modal?.web_url) {
      return $.Deferred().reject().promise();
    }

    if ($(document.body).hasClass(`js-disable-blocking-modal-${result.blocking_modal.slug}`)) {
      return $.Deferred().reject().promise();
    }

    return $.get(result.blocking_modal.web_url).then((content) => ({
      // (*) This "replace" removes the script tag from the blocking modal page
      // that contains the webpack "runtime" script. If this new script is loaded
      // it will create a second webpack runtime that breaks the runtime of the
      // parent page.
      modalContent: content.replace(/src="[^"]*runtime[^"]*"/gm, ''),
      slug: result.blocking_modal.slug,
      title: result.blocking_modal.title,
    }));
  }

  function determineResponseType(action) {
    // If the API response from a blocking modal acknowledgment is empty, the
    // response will cause jQuery to throw a 'parsererror', which prevents the
    // modal from closing. To solve, if the action contains any of these
    // strings, return undefined, otherwise return 'json'
    const nonJsonActions = ['blocking-modal/community-guidelines'];
    return nonJsonActions.some((jsonAction) => action.includes(jsonAction)) ? undefined : 'json';
  }

  function showModal({ modalContent, slug, title }) {
    const backboneModal = new ModalView({
      content: modalContent,
      title,
      dialogClasses: `modal-blocking-modal modal-blocking-modal-${slug}`,
      disableCancel: true,
      animate: true,
      showFooter: false,
      applyScrollFix: false,
    });
    backboneModal.on('shown', () => {
      blockingModalPubSub.trigger('shown');
    });
    backboneModal.open();

    if (slug === 'email-verification-blocking-modal') {
      mountEmailVerification();
    }

    // and then wait for the acknowledgement
    const d = $.Deferred();
    $(document).on('blocking-modal-acknowledged', () => {
      backboneModal.close();
      d.resolve();
    });

    return d.promise();
  }

  function handleBlockingModal() {
    // (+) This prevents infinite blocking modal recursion.
    // Since the blocking modal's content is a fully new Django-based
    // HTML page, it has its own blocking modal loading code.
    // Without this, it will load the same blocking modal and continuially
    // render the new modal inside itself forever. This disables new blocking
    // modal loading while a blocking modal is open.
    Rover.disable_blocking_modal = true;
    return $.get(Rover.urls.blockingModalAPI).then(getModalContent).then(showModal);
  }

  function triggerBlockingModalAcknowledged(result) {
    $(document).trigger('blocking-modal-acknowledged');
    Rover.disable_blocking_modal = false;

    // The mobile apps are listening for this event
    closeDialog();

    if (result.redirect_url) {
      window.location.href = result.redirect_url;
    }
  }

  function acknowledgeBlockingModalForm($target) {
    return $.post(
      $target.attr('action'),
      $target.serialize(),
      triggerBlockingModalAcknowledged,
      determineResponseType($target.attr('action'))
    )
      .done(triggerBlockingModalAcknowledged)
      .fail((data) => {
        // Don't want anything except the TOS modal to try this
        if (data.responseJSON && data.responseJSON.error) {
          $target
            .find('.error-container')
            .html(`<div class="rover-alert-warn alert">${data.responseJSON.error}</div>`);
        } else if (process.env.JS_ENV_CLIENT && data.status === 404) {
          // When the modal is already accepted, the server will return a 404
          // Refresh the page
          window.location.reload();
        }
      });
  }

  function init() {
    const acknowledgeDebounced = _.debounce(acknowledgeBlockingModalForm, 3000, true);
    $(document).on('submit', '.js-blocking-modal-acknowledgement-form', (event) => {
      event.preventDefault();
      acknowledgeDebounced($(event.target || event.srcElement));
    });
  }

  Rover.blocking_modals = {
    handleBlockingModal,
    pubSub: blockingModalPubSub,
  };
  init();
})(document);
