import renderRootElement from '@rover/react-lib/src/utils/renderRootElement';

import EmailVerificationPageContainer from './EmailVerificationPageContainer';

const REACT_EMAIL_VERIFICATION_APP_SELECTOR = '#react-email-verification-app';

const renderEmailVerification = (): void => {
  // eslint-disable-next-line rover/no-platform-specific-globals-or-imports
  if (document.querySelector(REACT_EMAIL_VERIFICATION_APP_SELECTOR)) {
    renderRootElement(EmailVerificationPageContainer, REACT_EMAIL_VERIFICATION_APP_SELECTOR);

    if (module.hot) {
      module.hot.accept('./EmailVerificationPageContainer', () => {
        renderRootElement(
          // eslint-disable-next-line @typescript-eslint/no-var-requires
          require('./EmailVerificationPageContainer').default,
          REACT_EMAIL_VERIFICATION_APP_SELECTOR
        );
      });
    }
  }
};

renderEmailVerification();

export const mountEmailVerification = (): boolean => {
  if (process.env.JS_ENV_CLIENT) {
    renderEmailVerification();
    return true;
  }
  return false;
};

export default EmailVerificationPageContainer;
