/* eslint-disable no-underscore-dangle */
import Rover from '@rover/globals/Rover';
import Backbone from '@rover/globals/backbone';
import Mustache from '@rover/globals/Mustache';
import _ from '@rover/globals/underscore';
import $ from '@rover/globals/jquery';
import moment from 'moment';
// WARNING: DF from datetimeInjected is not SSR compatible.
// For newer code, use const DF = getDateTimeFormatMapForLang(<lang>) from '@rover/shared/js/constants/i18n/datetime'
import DF from '@rover/shared/js/constants/i18n/datetime/datetimeInjected';
import i18n from '@rover/utilities/translation';

Rover.views = Rover.views || {};

Rover.views.DateRangePicker = Backbone.View.extend({
  startElementSelector: '.js-start-date',
  endElementSelector: '.js-end-date',
  template: Mustache.template('new_design/common/daterangepicker'),

  initialize(options) {
    _.bindAll(this, '_onModelChange', '_onDateSelect');
    this.options = options;
    this.model.on('change', this._onModelChange);
    this._context = {
      label: options.label,
      slug: options.slug,
      startText: i18n._('Start Date'),
      endText: i18n._('End Date'),
    };
    this.datePickerOptions = Object.assign(
      {
        dateFormat: DF.DATE_PICKER,
      },
      options.datePickerOptions || {}
    );
  },

  render() {
    const opts = $.extend(
      {
        startElementSelector: this.startElementSelector,
        endElementSelector: this.endElementSelector,
        onSelect: this._onDateSelect,
      },
      this.datePickerOptions
    );

    const { start_date, end_date } = this.model.attributes;
    const formattedDates = {};

    if (moment.isMoment(start_date) && moment.isMoment(end_date)) {
      formattedDates.start_date = start_date.format(DF.DATE_SHORT);
      formattedDates.end_date = end_date.format(DF.DATE_SHORT);
    }

    const context = _.extend(
      this._context,
      Object.keys(formattedDates).length > 0 ? formattedDates : this.model.attributes
    );

    this.$el.html(this.template.render(context));
    this.$el.daterangepicker(opts);

    if (this.options) {
      if (this.options.disableStart) this.disableStart();
      if (this.options.disableEnd) this.disableEnd();
    }

    this._clearErrors();
    return this;
  },

  disableStart() {
    this.$(this.startElementSelector).datepicker('option', 'disabled', true);
    this.$(this.endElementSelector).datepicker(
      'option',
      'minDate',
      this.$(this.startElementSelector).datepicker('getDate')
    );
  },

  disableEnd() {
    this.$(this.endElementSelector).datepicker('option', 'disabled', true);
    this.$(this.startElementSelector).datepicker(
      'option',
      'maxDate',
      this.$(this.endElementSelector).datepicker('getDate')
    );
  },

  _clearErrors() {
    this.$el.closest('.form-group').removeClass('has-error');
  },

  _onModelChange() {
    this.render();
  },

  _onDateSelect() {
    const startDate = this.$(this.startElementSelector).datepicker('getDate');
    const endDate = this.$(this.endElementSelector).datepicker('getDate');
    this.$el.trigger('selected.daterangepicker');
    this.$(':input').trigger('blur');
    this.model.set({
      // If the start date hasn't been set yet then `startDate` will be `null`
      // This causes moment to return "Invalid date" and that text getting
      // put into the date field even if the user hasn't interacted with the
      // element yet. Setting it to undefined if the `startDate` is falsey just
      // prevents that from happening. It won't be falsey if a valid date has
      // been selected.
      start_date: startDate ? moment(startDate) : undefined,
      end_date: moment(endDate),
    });
  },
});

Rover.views.ServiceDateRangePicker = Rover.views.DateRangePicker.extend({
  initialize(options) {
    Rover.views.DateRangePicker.prototype.initialize.call(this, options);

    // The following is pretty ugly.  It'd be nice to have these
    // as part of the API response for the service type model
    if (options.slug === 'overnight-boarding') {
      this._context = _.extend(this._context, {
        startText: i18n._('Drop Off'),
        endText: i18n._('Pick up'),
      });
    }
  },
});
