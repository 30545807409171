import APIError from '@rover/react-lib/src/apis/APIError';
import {
  resendCode as regenerateCode,
  updateEmail as update,
  verifyEmail as verify,
} from '@rover/react-lib/src/apis/emailVerification.api';
import type {
  ActionCreator,
  Duck,
  DuckOptions,
  EffectCreator,
  SelectorCreator,
} from '@rover/react-lib/src/lib/redux-duck';
import { createDuck } from '@rover/react-lib/src/lib/redux-duck';
import type { EmailVerification } from '@rover/types';

type Actions = {
  resendCodePending: ActionCreator<void>;
  resendCodeSuccess: ActionCreator<void>;
  resendCodeFailure: ActionCreator<{
    message: string;
    status: number;
  }>;
  verifyEmailPending: ActionCreator<void>;
  verifyEmailSuccess: ActionCreator<void>;
  verifyEmailFailure: ActionCreator<{
    message: string;
    status: number;
  }>;
  updateEmailPending: ActionCreator<void>;
  updateEmailSuccess: ActionCreator<void>;
  updateEmailFailure: ActionCreator<{
    message: string;
    status: number;
  }>;
  resetState: ActionCreator<void>;
};

type Effects = {
  resendCode: EffectCreator<boolean | undefined>;
  verifyEmail: EffectCreator<EmailVerification>;
  updateEmail: EffectCreator<string>;
};

type Selectors = {
  getIsSubmitting: SelectorCreator<boolean>;
  getVerifyStatus: SelectorCreator<number | null>;
  getResendStatus: SelectorCreator<number | null>;
  getUpdateStatus: SelectorCreator<number | null>;
  getErrorMessage: SelectorCreator<string>;
};

type State = {
  errorMessage: string;
  resendStatus: number | null;
  verifyStatus: number | null;
  updateStatus: number | null;
  submitting: boolean;
};

type Options = DuckOptions<State, Actions, Effects, Selectors>;

export const options: Options = {
  name: 'emailVerification',
  initialState: {
    errorMessage: '',
    resendStatus: null,
    verifyStatus: null,
    updateStatus: null,
    submitting: false,
  },
  actions: {
    resendCodePending: () => (state) => ({ ...state, submitting: true }),
    resendCodeSuccess: () => (state) => {
      return {
        ...state,
        submitting: false,
        errorMessage: '',
        resendStatus: 200,
      };
    },
    resendCodeFailure:
      ({ message, status }) =>
      (state) => ({
        ...state,
        submitting: false,
        errorMessage: message,
        resendStatus: status,
      }),
    verifyEmailPending: () => (state) => ({ ...state, errorMessage: '', submitting: true }),
    verifyEmailSuccess: () => (state) => ({
      ...state,
      errorMessage: '',
      submitting: false,
      verifyStatus: 200,
    }),
    verifyEmailFailure:
      ({ message, status }) =>
      (state) => ({
        ...state,
        submitting: false,
        errorMessage: message,
        verifyStatus: status,
      }),
    updateEmailPending: () => (state) => ({ ...state, submitting: true }),
    updateEmailSuccess: () => (state) => ({
      ...state,
      errorMessage: '',
      submitting: false,
      updateStatus: 200,
    }),
    updateEmailFailure:
      ({ message, status }) =>
      (state) => ({
        ...state,
        submitting: false,
        errorMessage: message,
        updateStatus: status,
      }),
    resetState: () => () => ({
      submitting: false,
      errorMessage: '',
      resendStatus: null,
      verifyStatus: null,
      updateStatus: null,
    }),
  },
  effects: {
    resendCode: (refresh?: boolean) => async (dispatch, getState, duckActions) => {
      dispatch(duckActions.resendCodePending());
      try {
        await regenerateCode(refresh);
        dispatch(duckActions.resendCodeSuccess());
      } catch (error) {
        const { message, status } = error as APIError;
        dispatch(duckActions.resendCodeFailure({ message, status }));
      }
    },
    verifyEmail:
      ({ code, emailVerificationStatus }: EmailVerification) =>
      async (dispatch, getState, duckActions) => {
        dispatch(duckActions.verifyEmailPending());

        try {
          await verify(code, emailVerificationStatus);
          dispatch(duckActions.verifyEmailSuccess());
        } catch (error) {
          const { message, status } = error as APIError;
          dispatch(duckActions.verifyEmailFailure({ message, status }));
        }
      },
    updateEmail: (email: string) => async (dispatch, getState, duckActions) => {
      dispatch(duckActions.updateEmailPending());
      try {
        await update(email);
        dispatch(duckActions.updateEmailSuccess());
      } catch (error) {
        const { message, status } = error as APIError;
        dispatch(duckActions.updateEmailFailure({ message, status }));
      }
    },
  },
  selectors: {
    getIsSubmitting: (getState, createSelector) =>
      createSelector([getState], (state) => {
        return state.submitting;
      }),
    getVerifyStatus: (getState, createSelector) =>
      createSelector([getState], (state) => {
        return state.verifyStatus;
      }),
    getResendStatus: (getState, createSelector) =>
      createSelector([getState], (state) => {
        return state.resendStatus;
      }),
    getUpdateStatus: (getState, createSelector) =>
      createSelector([getState], (state) => {
        return state.updateStatus;
      }),
    getErrorMessage: (getState, createSelector) =>
      createSelector([getState], (state) => {
        return state.errorMessage;
      }),
  },
};

export const { actions, selectors, effects, reducer } = createDuck(options) as Duck<
  State,
  Actions,
  Effects,
  Selectors
>;
