import currentUser from '@rover/globals/currentUser';
import dataLayer from '@rover/globals/dataLayer';
import Url from '@rover/globals/domurl';
import $ from '@rover/globals/jquery';
import Mustache from '@rover/globals/Mustache';
import Rover from '@rover/globals/Rover';
import retry from '@rover/rsdk/src/modules/Network/retry';
// eslint-disable-next-line no-unused-vars
import auth from '@rover/utilities/auth'; // This is used below as Rover.utils.logout, don't remove
import inject from '@rover/utilities/injector';

// We are using this flag to prevent init() from firing more than once.
// Currently both header.js and application.js are calling init(). But we only want the
// one that comes in first
let hasInitialized = false;

export default {
  renderAuthenticatedHeader() {
    const { isSitter, isActiveSssuApplicantWithSelectedServices } = currentUser;
    const supportsReferral = inject('Rover.context.supportsReferral');
    const formattedReferralGiftcardAmount = inject('Rover.context.formattedReferralGiftcardAmount');
    const formattedReferralOwnerToOwnerReward = inject(
      'Rover.context.formattedReferralOwnerToOwnerReward'
    );
    const formattedEmpmCouponAmount = inject('Rover.context.formattedEmpmCouponAmount');
    const rolloutSitterPromotionM0EntryPoints = inject(
      'Rover.context.rolloutSitterPromotionM0EntryPoints'
    );

    /* eslint-disable no-param-reassign */
    const truncateWithEllipsis = (string, maxLength) => {
      const ellipsis = '...';
      if (string.length > maxLength) {
        const truncateTo = maxLength - ellipsis.length;
        string = string.slice(0, truncateTo) + ellipsis;
      }
      return string;
    };
    /* eslint-enable no-param-reassign */

    const appendURLRef = (url, ref = 'header') => {
      /* TODO: Use internal URL handling */
      const u = new Url(url);
      u.query.ref = ref;
      return u.toString();
    };

    const {
      urls: { profile, providerHub },
    } = Rover;
    const profileUrl = isActiveSssuApplicantWithSelectedServices ? providerHub : profile;

    const headerContext = {
      isSitter,
      isActiveSssuApplicantWithSelectedServices,
      isMobile: Rover.userAgent.isMobile,
      firstName: truncateWithEllipsis(currentUser.firstName, 12),
      smallPhotoUrl: currentUser.smallPhotoUrl,
      unreadMessageCount: currentUser.unreadMessageCount,

      // Used by referral bottom row on mobile menu
      // See logged_in_user.mustache
      staticSvgPresent: Rover.context.staticSvgPresent,
      // See logged_in_user.mustache
      staticSvgChat: Rover.context.staticSvgChat,
      urlReferAFriend: appendURLRef(Rover.urls.referAFriend, 'header-menu'),
      staticSvgMegaphone: Rover.context.staticSvgMegaphone,
      urlPromoteYourProfile: appendURLRef(Rover.urls.promoteYourProfile, 'header-menu'),

      urlOverview: appendURLRef(Rover.urls.overview),
      urlDashboard: Rover.urls.dashboard,
      urlProfile: profileUrl,
      urlInbox: Rover.urls.inbox,
      urlCalendar: appendURLRef(Rover.urls.calendar),
      // eslint-disable-next-line no-script-url
      urlLogout: 'javascript:Rover.utils.logout("header")',
      urlDogPhotos: Rover.urls.dogPhotos,
      urlRebook: appendURLRef(Rover.urls.rebook),
      urlHelp: Rover.urls.help,

      hasDog: currentUser.hasDogs,
      petsList: Rover.urls.petsList,
      galleryPhotos: Rover.urls.galleryPhotos,

      showReferralLink: !isSitter && supportsReferral,

      formattedReferralGiftcardAmount,
      formattedReferralOwnerToOwnerReward,
      showPromoteYourProfileLink: isSitter && rolloutSitterPromotionM0EntryPoints,
      formattedEmpmCouponAmount,
    };
    if (Rover.urls.settings) {
      headerContext.urlNotifications = Rover.urls.settings;
    }
    const rendered = Mustache.template('new_design/header/logged_in_user').render(headerContext);

    if (!isSitter) {
      $('.js-become-a-sitter').removeClass('hide');
      $('.js-give-twenty-get-twenty').removeClass('hide');
    } else if (!currentUser.listsAllServices) {
      $('.js-list-more-services').removeClass('hide');
    }
    if (isSitter) {
      $('.js-invite-a-friend').removeClass('hide');
    }
    const $anonymousHeader = $('.js-anonymous-header');

    $anonymousHeader.html(rendered);

    if (document.querySelector('#react-notifications-center')) {
      // Import here so we know the react-notifications-center div
      // has been rendered from the moustache template
      // and also so the bundle and its dependencies don't
      // load for unauthenticated users who don't need any of it anyway
      retry(() => import('@rover/react-lib/src/pages/header/NotificationsCenter'));
    }

    Rover.utils.initGAEvents($anonymousHeader);

    // If referral is not supported in country configuration, hide referral link in header.
    // Also this code depends on the fact that Authenticated.init() comes later than
    // Auth.init() in js/application.js initialization code.
    if (!supportsReferral) {
      $('.js-header-referral').addClass('hide');
    }
  },

  setKetchUserIdentity() {
    // Send Ketch the information required for authenticated users.
    // In React this is done in src/frontend/react-app/src/client/scripts/ketch.script.ts
    dataLayer.push({ event: '', account_id: currentUser.opk });
    dataLayer.push({ event: '', email: currentUser.emailHash });
  },

  showHaveDogPrompt() {
    if (currentUser.shouldPromptForPets && !$.cookie('have_dog_prompt')) {
      $('.js-have-dog-prompt').modal('show').on('hide.bs.modal', this.supressHaveDogPrompt);
    }
  },

  supressHaveDogPrompt() {
    $.cookie('have_dog_prompt', true, { expires: 3, path: '/' });
  },

  confirmNoDogs() {
    $.ajax(Rover.urls.hasDogs, {
      type: 'POST',
      data: {
        does_not_have_dogs: true,
      },
      success() {
        $('.js-have-dog-prompt .js-prompt').addClass('hidden');
        $('.js-have-dog-prompt .js-thanks').removeClass('hidden');
        window.setTimeout(() => {
          $('.js-have-dog-prompt').modal('hide');
        }, 3000);
      },
    });
  },

  confirmHasDogs() {
    this.suppressHaveDogPrompt();
    return true;
  },

  /* Datalayer Event tracking methods */
  trackAppDownloadLinkClick(e) {
    const app = $(e.currentTarget).attr('data-which');
    const where = $(e.currentTarget).attr('data-where');
    if (where === 'conversation') {
      dataLayer.push({ event: 'conversation-app-download-clicked', app });
    }
  },

  trackReferralCtaClick(e) {
    const ctaId = e.currentTarget.id;
    const campaignId = Rover.context.personReferralCampaign;
    dataLayer.push({
      event: 'referral-cta-click',
      campaignid: campaignId,
      referral_cta: ctaId,
    });
  },

  trackPromoteYourProfileCtaClick() {
    dataLayer.push({
      event: 'more-menu-click-sitter-promo-navigation',
    });
  },

  trackReferralCtaView() {
    const observer = new IntersectionObserver(
      (entries, obs) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const ctaId = entry.target.id;
            dataLayer.push({
              event: 'referral-cta-view',
              referral_cta: ctaId,
            });
            obs.unobserve(entry.target); // Only emiting once per cta view
          }
        });
      },
      { root: null } // On intersection with viewport
    );

    $('.js-referral-cta').each((index, cta) => {
      observer.observe(cta);
    });
  },

  addBindings() {
    $('.js-app-download-link').on('click', this.trackAppDownloadLinkClick);
    $('.js-no-dog-link').on('click', this.confirmNoDogs);
    $('.js-has-dog-link').on('click', this.confirmHasDogs);
    $('.js-referral-cta').on('click', this.trackReferralCtaClick);
    $('.js-promote-your-profile-cta').on('click', this.trackPromoteYourProfileCtaClick);
  },

  subInit() {
    this.setKetchUserIdentity();
    // In order to have referral CTA links tracked correctly, we need to have
    // the addBindings method run after renderAuthenticatedHeader
    this.renderAuthenticatedHeader();
    this.addBindings();
    this.showHaveDogPrompt();
    this.trackReferralCtaView();
  },

  init() {
    if (hasInitialized) {
      return;
    }
    hasInitialized = true;
    currentUser
      .waitForAuthenticatedUserLoaded()
      .then(() => this.subInit())
      .catch(() => {});
  },
};
