import $ from '@rover/globals/jquery';
import Rover from '@rover/globals/Rover';
import _ from '@rover/globals/underscore';

import ModalView from '../../components/rover_modal';
import ConversationModel from '../../models/conversation';

Rover.views.ArchiveModalView = ModalView.extend({
  initialize(options) {
    ModalView.prototype.initialize.call(this, options); // super()
    this.initBindings();
    this.initConversation();
    this.initPrimaryButton();
  },
  initBindings() {
    _.bindAll(this, 'togglePrimaryButton');
    _.bindAll(this, 'toggleNeuter');
    _.bindAll(this, 'toggleSpay');
  },
  initConversation() {
    const self = this;

    this.conversation = new ConversationModel({
      url: this.options.conversationDetailUrl,
    });

    const promise = this.conversation.fetch();

    $.when(promise).then(() => {
      self.serviceType = self.conversation.get('service_type');
      self.initPreferences();
      self.initReasons();
      self.$el.find('.js-requester-short-name').text(self.conversation.get('requester').short_name);
      self.$el.find('.js-archive-reason-message').text('');
    });
  },
  initReasons() {
    const self = this;
    const { $el } = this;

    $el.find('.js-archived-reasons').change(function () {
      self.archivedReason = $(this).val();
      const isBadFit = $(this).val() === 'dog_not_good_fit';

      if (isBadFit && !self.isTravelingService()) {
        const isPrimaryEnabled = !self.options.showNeuteredOpts && !self.options.showSpayedOpts;
        self.toggleNeuter(self.options.showNeuteredOpts);
        self.toggleSpay(self.options.showSpayedOpts);
        self.togglePrimaryButton(isPrimaryEnabled);
      } else {
        self.toggleNeuter(false);
        self.toggleSpay(false);
        self.togglePrimaryButton(true);
      }

      const message = $(this).find(':selected').attr('data-message');

      // Hide error message for not_available reason if conversation duration is > 4 days
      if (self.archivedReason === 'not_available') {
        const { dates } = self.conversation.getDatesData();
        if (dates.length > 4) {
          return;
        }
      }
      $el.find('.js-archive-reason-message').text(message);
    });
  },
  initPreferences() {
    const self = this;
    const { options } = this;

    $.get(Rover.urls.serviceHostingPreferences, (data) => {
      const acceptsNonNeutered = data.accepts_non_neutered_males;
      const acceptsNonSpayed = data.accepts_unspayed_females;
      const hasNonNeutered = self.hasNonNeuteredMale(self.conversation.get('pets'));
      const hasNonSpayed = self.hasNonSpayedFemale(self.conversation.get('pets'));

      options.showNeuteredOpts = hasNonNeutered && acceptsNonNeutered !== false; // is true or null
      options.showSpayedOpts = hasNonSpayed && acceptsNonSpayed !== false; // is true or null
      self.$el.find('.js-archived-reasons').removeAttr('disabled');
    }).fail(() => {
      options.showNeuteredOpts = false;
      options.showSpayedOpts = false;
      self.$el.find('.js-archived-reasons').removeAttr('disabled');
    });

    $('.js-neuter-options label').click(function () {
      options.shouldAcceptNonNeutered = $(this).find('input').val() === 'yes';

      if (
        (options.showSpayedOpts && options.shouldAcceptNonSpayed !== undefined) ||
        !options.showSpayedOpts
      ) {
        self.togglePrimaryButton(true);
      }
    });

    $('.js-spay-options label').click(function () {
      options.shouldAcceptNonSpayed = $(this).find('input').val() === 'yes';

      if (
        (options.showNeuteredOpts && options.shouldAcceptNonNeutered !== undefined) ||
        !options.showNeuteredOpts
      ) {
        self.togglePrimaryButton(true);
      }
    });
  },
  initPrimaryButton() {
    const self = this;

    this.$el.find('.js-modal-primary-action').click(() => {
      self.options.onSave({
        reason: self.archivedReason,
        shouldAcceptNonSpayed: self.options.shouldAcceptNonSpayed,
        shouldAcceptNonNeutered: self.options.shouldAcceptNonNeutered,
      });
    });
  },
  hasNonNeuteredMale(pets) {
    for (let i = 0; i < pets.length; i += 1) {
      const pet = pets[i];

      if (pet.gender === 'm' && !pet.spayed_neutered) {
        return true;
      }
    }

    return false;
  },
  hasNonSpayedFemale(pets) {
    for (let i = 0; i < pets.length; i += 1) {
      const pet = pets[i];

      if (pet.gender === 'f' && !pet.spayed_neutered) {
        return true;
      }
    }

    return false;
  },
  render() {
    ModalView.prototype.render.call(this); // super()
    this.$el.find('.js-archive-modal').show();
    this.togglePrimaryButton(false);
    this.renderReasons();
  },
  renderReasons() {
    const { $el } = this;
    $el.find('.js-archived-reasons > option:not([disabled])').remove();
    $el.find('.js-archived-reasons > option').prop('selected', true);

    $.get(Rover.urls.providerArchivedReasons, (data) => {
      data.results.forEach((item) => {
        const opt = document.createElement('option');
        opt.value = item.reason;
        opt.text = item.title;
        opt.setAttribute('data-message', item.message || '');
        $el.find('.js-archived-reasons').append(opt);
      });
    });
  },
  toggleNeuter(show) {
    this.$el.find('.js-neuter-options').toggle(show);
  },
  toggleSpay(show) {
    this.$el.find('.js-spay-options').toggle(show);
  },
  isTravelingService() {
    return this.conversation.getServiceType().location !== 'provider_home';
  },
  togglePrimaryButton(active, loading) {
    const selector = this.$el.find('.js-modal-primary-action');
    if (active) {
      selector.removeAttr('disabled');
    } else {
      selector.attr('disabled', 'true');
    }
    selector.children('i').toggleClass('hidden', !loading);
  },
});
