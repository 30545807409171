import Backbone from '@rover/globals/backbone';
import $ from '@rover/globals/jquery';
import Rover from '@rover/globals/Rover';
import _ from '@rover/globals/underscore';
// WARNING: DF from datetimeInjected is not SSR compatible.
// For newer code, use const DF = getDateTimeFormatMapForLang(<lang>) from '@rover/shared/js/constants/i18n/datetime'
import DF from '@rover/shared/js/constants/i18n/datetime/datetimeInjected';
import i18n from '@rover/utilities/translation';

export default Backbone.Model.extend({
  dateFormat: DF.DATE_ISO,
  dateDisplayFormat: DF.DATE_SHORT,
  idAttribute: 'url',
  isRecurring: false,
  url() {
    return this.get('url');
  },
  initialize() {
    _.bindAll(this, 'acceptRequest', 'createRequest', 'toggleRequesterAddOn');
  },
  getOpk() {
    // This should be overridden by "subclasses"
    return '';
  },
  getState() {
    const state = this.get('status');
    const request = this.get('request');

    if (state === 'booked' || state === 'cancelled' || Rover.context.isRetryCheckout) {
      return 'booked';
    }
    if (!request) {
      return 'authorize';
    }
    if (request && request.requester_actions.indexOf('accepted') !== -1) {
      return 'pay';
    }
    if (request && request.provider_actions.indexOf('accepted') !== -1) {
      return 'requested';
    }

    // We shouldn't get here
    return null;
  },
  getUnit() {
    const addOn = _.findWhere(this.get('booking_add_ons'), {
      is_alternative_rate: true,
    });
    return addOn ? addOn.unit_description : null;
  },
  isBooked() {
    return this.getState() === 'booked';
  },
  hasPaymentIssue() {
    return Rover.context.hasPaymentIssue;
  },
  isPayingState() {
    return (
      (['authorize', 'pay'].indexOf(this.getState()) !== -1 || this.hasPaymentIssue()) &&
      !Rover.context.isSitter
    );
  },
  getActiveBookingAddOns(inputBookingAddOns) {
    const bookingAddOns = inputBookingAddOns || this.get('booking_add_ons');
    const pets = this.get('pets') || [];
    const petUrls = _.pluck(pets, 'url');

    return _.filter(bookingAddOns, (addon) => {
      // exclude inactive addons and ones with pets not in the pets attribute

      if (!addon.active) {
        return false;
      }

      if (!addon.pet) {
        return true;
      }

      return _.contains(petUrls, addon.pet);
    });
  },
  hasRequest() {
    return this.get('request') !== null;
  },
  getRequestVersion() {
    if (!this.hasRequest()) {
      return null;
    }
    return this.get('request').request_version;
  },
  includesPet(petUrl) {
    return _.chain(this.get('pets')).pluck('url').contains(petUrl).value();
  },
  getRequestRecipient() {
    if (!this.hasRequest()) {
      return null;
    }

    const request = this.get('request');
    return this.getParticipantByUrl(request.recipient);
  },
  getParticipantByUrl(url) {
    const participants = [this.get('requester'), this.get('provider')];
    return _.chain(participants)
      .filter((person) => person.url === url)
      .first()
      .value();
  },
  setPets(pets) {
    const bookingAddOns = this.getCleanBookingAddOns(pets);
    this.set({
      pets,
      booking_add_ons: bookingAddOns,
    });
  },
  getCleanBookingAddOns(pets) {
    // get a list of addons which are either !is_alternative_rate or is a rate
    // for a pet included in the given list
    const bookingAddOns = this.get('booking_add_ons');
    const petUrls = _.pluck(pets, 'url');
    return bookingAddOns.filter(
      (bao) => (bao.active && !bao.is_alternative_rate) || petUrls.indexOf(bao.pet) > -1
    );
  },
  toggleRequesterAddOn(addOnSlug, active) {
    const addOn = _.filter(this.get('requester_add_ons'), (ao) => ao.slug === addOnSlug)[0];
    if (addOn.active === active) return;
    this.trigger('changing:requester_add_ons', addOnSlug, true);
    addOn.active = active;
    this.getDraftPricing({
      booking_add_ons: this.get('booking_add_ons'),
      requester_add_ons: this.get('requester_add_ons'),
    })
      .then(
        (data) => {
          this.trigger('change:requester_add_ons', this, this.get('requester_add_ons'));
          this.set('total_price', data.total_price);
        },
        () => {
          addOn.active = !active;
          this.trigger('change:requester_add_ons', this, this.get('requester_add_ons'));
        }
      )
      .always(() => {
        this.trigger('changing:requester_add_ons', addOnSlug, false);
      });
  },
  parse(response, xhr) {
    // The API returns inactive addons which are not useful to us. Remove them.
    return {
      ...response,
      booking_add_ons: _.filter(response.booking_add_ons, (bao) => bao.active),
    };
  },
  createRequest() {
    // aka "Book It"

    return this.shadowSave({
      request: {
        status: 'pending',
        request_version: null,
      },
      source: Rover.utils.getSourceDescription(),
    });
  },
  cancelRequest() {
    return this.shadowSave({
      request: {
        status: 'cancelled',
        request_version: this.getRequestVersion(),
      },
      source: Rover.utils.getSourceDescription(),
    });
  },
  declineRequest() {
    return this.shadowSave({
      request: {
        status: 'declined',
        request_version: this.getRequestVersion(),
      },
      source: Rover.utils.getSourceDescription(),
    });
  },
  acceptRequest() {
    return this.shadowSave({
      request: {
        status: 'accepted',
        request_version: this.getRequestVersion(),
      },
      source: Rover.utils.getSourceDescription(),
    });
  },
  getAlternativeRateAddOns() {
    return _.filter(this.getActiveBookingAddOns(), (ao) => ao.is_alternative_rate);
  },
  getNonAlternativeRateAddOns() {
    return _.filter(this.getActiveBookingAddOns(), (ao) => !ao.is_alternative_rate);
  },
  getActiveRequesterAddOns() {
    return _.filter(this.get('requester_add_ons'), (ao) => ao.active);
  },
  getOptionalRequesterAddOns() {
    return _.filter(this.get('requester_add_ons'), (ao) => !ao.is_required);
  },
  fetchModel(options) {
    // Normal fetch().then() gets (attributes, message, xhr) as arguments
    // For this method, fetchModel().then() gets (model, response) as arguments
    // so you can do normal promise chains and void having to use the
    // success and error callbacks.
    const d = $.Deferred();
    const o = {
      success: d.resolve,
      error: d.reject,
      ...options,
    };
    this.fetch(o);
    return d.promise();
  },
  getPricing() {
    return this.getDraftPricing();
  },
  updateDraftPricing(attrs) {
    const newAttrs = attrs;
    newAttrs.booking_add_ons = this.get('booking_add_ons');
    this.getDraftPricing(newAttrs).then((data) => {
      this.set(data);
    });
  },
  savePricingData(data, deferred) {
    const d = deferred || $.Deferred();
    const originalAttributes = {};

    _.each(data, (attr, key) => {
      const oldValue = this.get(key);
      if (oldValue) {
        originalAttributes[key] = oldValue;
        this.set(key, attr);
      }
    });
    this.save(
      {},
      {
        success: (model, response, options) => {
          d.resolve(response);
        },
        error: (model, response, options) => {
          this.set(originalAttributes);
          d.reject(response);
        },
      }
    );
    return d.promise();
  },
  handleError(err) {
    if (err.status === 400) {
      const nonFieldErrors = err.responseJSON.non_field_errors || [];
      const bookingAddOnsErrors = err.responseJSON.booking_add_ons || [];
      if (nonFieldErrors.includes('free bookings are not allowed')) {
        alert(i18n._('Free bookings are not allowed.'));
      } else if (bookingAddOnsErrors.includes('Total stay price must not be negative')) {
        alert(i18n._('Total stay price must not be negative.'));
      } else {
        alert(i18n._('An unknown error occurred.  Please try again.'));
      }
    }
  },
  saveDraftPricing(attrs, optimisticAttrUpdate = false) {
    const d = $.Deferred();

    if (optimisticAttrUpdate) {
      this.set(attrs);
    }

    this.getDraftPricing(attrs).then((data) => {
      this.savePricingData(data, d);
    }, d.reject);
    return d.promise();
  },
});
