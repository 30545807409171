import Alerts from '@rover/utilities/Alerts';

import $ from '@rover/globals/jquery';
import _ from '@rover/globals/underscore';
import Backbone from '@rover/globals/backbone';
import moment from '@rover/globals/moment';
import Rover from '@rover/globals/Rover';
// WARNING: DF from datetimeInjected is not SSR compatible.
// For newer code, use const DF = getDateTimeFormatMapForLang(<lang>) from '@rover/shared/js/constants/i18n/datetime'
import DF from '@rover/shared/js/constants/i18n/datetime/datetimeInjected';

$(() => {
  if (!$('body').hasClass('js-weekend-availability')) {
    return;
  }

  const WeekendAvailView = Backbone.View.extend({
    initialize() {
      const startDate = moment(Rover.context.weekendAvail.get('start_date'), DF.DATE_ISO).format(
        DF.MONTH_DAY_WEEK_MED
      );
      const lastDate = moment(Rover.context.weekendAvail.get('last_day'), DF.DATE_ISO).format(
        DF.MONTH_DAY_WEEK_MED
      );
      this.$('.js-start-date')
        .empty()
        .append(`<strong>${startDate}</strong>`);
      this.$('.js-last-date')
        .empty()
        .append(`<strong>${lastDate}</strong>`);
      Rover.context.weekendAvail.save(
        { is_available: Rover.context.isAvailableIntent },
        {
          success: _.bind(this.success, this),
          error: _.bind(this.error, this),
        }
      );
    },
    success() {
      this.$('.js-loading-content').remove();
      this.$('.js-loaded-content').removeClass('hidden');
    },
    error() {
      Alerts.showAlert('updating your availability');
    },
  });

  const weekendAvailView = new WeekendAvailView({
    el: '.js-weekend-availability',
  });
});
