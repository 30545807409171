import i18n from '@rover/utilities/translation';
import Mustache from '@rover/globals/Mustache';
import Rover from '@rover/globals/Rover';
import _ from '@rover/globals/underscore';
import DaysOfWeekView from './days_of_week_view';
import ServiceDateSpecView from './service_date_spec_view';

export default DaysOfWeekView.extend({
  template: Mustache.template('new_design/common/recurring_dates'),
  bindings: _.extend(DaysOfWeekView.prototype.bindings, {
    '.js-start-date': 'start_date',
  }),
  initialize(options) {
    DaysOfWeekView.prototype.initialize.call(this, options);

    this.startDateView = new Rover.views.DatePicker({
      model: this.model,
      slug: this.service.get('slug'),
      field: 'start_date',
      dateText: i18n._('Start Date'),
    });
  },
  render() {
    DaysOfWeekView.prototype.render.call(this);
    this.$('.js-recurring-start-date').append(this.startDateView.render().$el);
    return this;
  },
  getDatesData() {
    const data = this.model.toJSON();
    const startDate = data.start_date;
    const datesData = DaysOfWeekView.prototype.getDatesData.call(this);

    if (!startDate || !datesData) return {};

    return {
      start_date: this.formatDate(startDate),
      days: datesData.days,
    };
  },
});
