import $ from '@rover/globals/jquery';
import Rover from '@rover/globals/Rover';
import emitMetric from '@rover/utilities/emitMetric';
import inject from '@rover/utilities/injector';


$(() => {
  if (!$('body').hasClass('account-payments')) {
    return;
  }

  const displayActiveFlatNav = function (id) {
    $('.js-payments-table').hide();
    $(id).show();
  };

  const buildUrl = function (tabName, hash) {
    const params = new URLSearchParams(window.location.search);

    const pageNum = params.get('page');
    if (pageNum == null || hash !== window.location.hash) {
      return `${window.location.origin + window.location.pathname}?tab=${tabName}${hash}`;
    }
    return `${
      window.location.origin + window.location.pathname
    }?tab=${tabName}&page=${pageNum}${hash}`;
  };
  const getUrlForHash = function (hash) {
    switch (hash) {
      case '#payments-earnings':
        return buildUrl('earnings', hash);
      case '#payments-pending-earnings':
        return buildUrl('pending-earnings', hash);
      case '#payments-withdrawals':
        return buildUrl('withdrawals', hash);
      case '#payments-paid':
        return buildUrl('payments-paid', hash);
      case '#payment-issues':
        return buildUrl('payment-issues', hash);
      case '#payments-other':
        return buildUrl('other', hash);
      default:
        return buildUrl('earnings', hash);
    }
  };
  const displayHashedView = function (defaultHash) {
    const hash = window.location.hash || defaultHash;
    if (hash) {
      $(`a[href="${hash}"]`).trigger('click');
    }
  };

  const addBindings = function () {
    $('.js-payments-nav').on('click', function (e) {
      const $this = $(this);
      const href = $this.attr('href');

      displayActiveFlatNav(href);
      if (history && history.replaceState) {
        const replaceStateUrl = getUrlForHash(href);
        history.replaceState({}, '', replaceStateUrl);
      }
      $('.js-payments-nav').closest('li').removeClass('active');
      $this.closest('li').addClass('active');
      e.preventDefault();
    });
    $('.js-gus-tip-dismiss').on('click', function () {
      const $gusTip = $(this).closest('.js-gus-tip');
      $.cookie($gusTip.data('gus-tip-cookie'), 'dismissed', { expires: 180 });
      $gusTip.hide();
    });

    $('.payments-load-more').on('click', function (e) {
      const params = new URLSearchParams(window.location.search);
      let pageNum = Number(params.get('page')) || 1;
      pageNum += 1;
      window.location = `${window.location.origin + window.location.pathname}?tab=${params.get(
        'tab'
      )}&page=${pageNum}${window.location.hash}`;
      e.preventDefault();
    });

    const countryCode = inject('Rover.context.countryCode', '');
    $('.document-link').on('click', function (){
      emitMetric(
        "click-on-dac7-document-link", {country_code: countryCode}
      );
    })
  };

  const init = function () {
    Rover.stickyPopover('[data-toggle="sticky-popover"]', {
      container: 'body',
      placement: 'top',
    });

    addBindings();

    window.setTimeout(() => {
      displayHashedView('#payments-earnings');
    }, 0);
  };

  $(document).ready(function () {
    const params = new URLSearchParams(window.location.search);
    if (params.get('page') != null && document.querySelector('.footer-container')) {
      Rover.utils.scrollTo('.footer-container');
    }
  });
  init();
});
