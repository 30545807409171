import ServiceDateSpecView from './service_date_spec_view';
import ServiceNumUnits from './service_num_units_view';

export default ServiceDateSpecView.extend({
  initialize(options) {
    ServiceDateSpecView.prototype.initialize.call(this, options);
    if (this.service.supports_num_units_per_day()) {
      this.numUnitsView = this.createNumUnitsView();
    } else {
      this.numUnitsView = null;
    }
  },
  createNumUnitsView() {
    const view = new ServiceNumUnits({
      label: this.service.num_units_label(),
      desc: this.service.num_units_description.bind(this.service),
    });

    this.propagateDatesChanged(view.model, 'change');
    return view;
  },
  getNumUnits() {
    if (this.numUnitsView) return this.numUnitsView.model.get('num_units');
    return 1;
  },
});
