/* eslint-disable no-underscore-dangle */
import CurrentUser from './CurrentUser';

declare global {
  interface Window {
    _globalState: {
      currentUser: CurrentUser;
    };
  }
}
// In order to guarantee a singleton exists, we have to rely on
// window-level state, since multiple different bundles can all
// reference this module.
window._globalState = window._globalState || {};
window._globalState.currentUser = window._globalState?.currentUser || new CurrentUser();
export default window._globalState.currentUser;
