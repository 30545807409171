/*
 *   A base class for generating AJAX channels and handling success and
 *   error responses.
 *
 *   This class serves two puroses. The first is to abstract away from the
 *   nitty gritty of the $.ajax method. Simply pass a dictionary to the post
 *   or get methods and it handles the rest.
 *   
 *   The second is to provide a consistent experience to the user in the
 *   (unlikely) case of an error callback. It will display to the user a nice
 *   error message, complete with support contact information. 
 *
 *   This class extends Backbone.Model. Note that we are not using the models
 *   fetch functionality, which is tied to REST concepts. 
 * 
 *   Usage:
 * 
 *      myChannel = new AJAXView({ 
 *        url: '/ajax/myapp/myaction/', 
 *        successCallback: function(response){ 
 *          // Update some visual element 
 *        } 
 *      });
 *      myChannel.post({'foo': 'bar'}); 
 *   
 */
var AJAXView = Backbone.View.extend({
    url: null,

    initialize: function () {
        // Necessary Javascript voodoo
        _.bindAll(this, 
                  'get',
                  'post',
                  'successCallback',
                  'errorCallback'
                  );
    },

    /* 
     *  Submit GET request to url.
     *      params - a dictionary of key/value pairs that will be converted
     *               into a querystring
     *      url - (optional) override of this.url
     */ 
    get: function(params, url){
        alert("Not yet implemented");
        if (typeof url === 'undefined'){
            url = this.options.url;
        }
        // TODO: append params to querystring, being conscious of ? or no
        // TODO: Add stint to prevent IE from caching (e.g. ?noiecache=12345)
    },

    /* 
     *  Post to url, sending contents of post_data. 
     *      post_data - a dictionary of key, value pairs
     *      url - (optional) override of this.url
     */ 
    post: function(postData, url, contentType) {
        url = url || this.options.url;
        contentType = contentType || 'application/x-www-form-urlencoded';
        this._post(postData, url, 'POST', contentType);
    },

    put: function(put_data, url, contentType) {
        url = url || this.options.url;
        contentType = contentType || 'application/x-www-form-urlencoded';
        this._post(put_data, url, 'PUT', contentType);
    },
    
    _post: function(post_data, url, type, contentType) {
        $.ajax({
            type: type,
            url: url,
            data: post_data,
            dataType: 'json',
            contentType: contentType,
            success: this.successCallback,
            error: this.errorCallback
        });
    },

    successCallback: function(result){
        alert("Override me");
    },

    errorCallback: function(data){
        alert("Oops, an error occurred. The Rover.com tech team has been " +
              "alerted and will fix it shortly. If you would like immediate " + 
              "help visit our Help Center at support.rover.com, or call the " +
              "Barkline at 888-453-7889");
    }
});
