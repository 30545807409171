import Rover from '@rover/globals/Rover';
import { isAndroid, isiOS, isMobileEmbedded } from '@rover/rsdk/src/modules/Network/userAgent';

export const isMobileBrowser = () => Rover.userAgent.isMobile;
export const isTouchCapable = () => Rover.userAgent.isTouchCapable;
export const isTablet = () => Rover.userAgent.isTablet;
Rover.utils = Rover.utils || {};

Rover.utils.isMobileOrTablet = () => Rover.userAgent.isMobile || Rover.userAgent.isTablet;

Rover.utils.getSourceDescription = () => {
  let source = '';

  if (isMobileBrowser()) {
    if (isMobileEmbedded()) {
      if (isAndroid()) {
        source = 'android';
      } else if (isiOS()) {
        source = 'ios';
      }
    } else {
      source = 'mobile_web';
    }
  } else {
    source = 'web';
  }

  return source;
};

const exportedDevice = {
  isMobileBrowser,
  isTouchCapable,
  isTablet,
};
export default exportedDevice;
