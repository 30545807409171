import Backbone from '@rover/globals/backbone';
import Rover from '@rover/globals/Rover';
import _ from '@rover/globals/underscore';

import ServiceSelectorView from './service_selector_view';
import ContiguousDatesView from './contiguous_dates_view';
import NonContiguousDatesView from './non_contiguous_dates_view';
import RecurringDatesSupportView from './recurring_dates_support_view';

/**
 * services_el
 *   The div wrapping the service selection buttons.
 *   This div must have the buttons pre-rendered.
 * dates_el
 *   The div wrapping the num units and date pickers.
 * collection
 *   A ServiceButtonGroupCollection.
 */
export default Backbone.View.extend({
  initialize(opts) {
    const self = this;

    const selector = new ServiceSelectorView(
      _.extend(
        {
          collection: this.collection,
        },
        opts.services
      )
    );

    this.dates = opts.dates;
    this.viewContainer = new Backbone.ChildViewContainer();

    this.collection.each(service => {
      const serviceType = service.get('service_type');
      const nonContiguous = serviceType.supports_non_contiguous_dates;
      const isRecurring = serviceType.supports_recurring_billing;
      const Component = nonContiguous ? NonContiguousDatesView : ContiguousDatesView;

      const viewOpts = {
        service,
        disableStart: opts.dates.disableStart,
        startDate: opts.dates.startDate,
      };

      let view = new Component(viewOpts);

      if (isRecurring) {
        view = new RecurringDatesSupportView({
          service,
          nonRecurringView: view,
        });
      }

      return self.addServiceDateSelector(view);
    });
  },
  render() {
    const container = document.createDocumentFragment();
    this.viewContainer.each(view => container.appendChild(view.render().el));
    this.dates.el.find('.js-dates-for-service').append(container);
    return this;
  },
  addServiceDateSelector(view) {
    this.viewContainer.add(view, view.service.get('slug'));

    // Set up the service date views to hide / show themselves
    // when the selected service changes
    this.listenTo(view.service, 'change:selected', (model, show) =>
      view.$el.toggleClass('hidden', !show)
    );

    if (!view.service.get('selected')) {
      view.$el.addClass('hidden');
    }

    // propagate the datesChanged event
    this.listenTo(view, 'datesChanged', _.bind(() => this.trigger('datesChanged'), this));

    return view.render();
  },
  getActiveView() {
    const activeService = this.collection.getSelected();
    if (!activeService) return null;
    return this.viewContainer.findByCustom(activeService.get('slug'));
  },
  getDatesData() {
    const view = this.getActiveView();
    if (!view) return {};
    return view.getDatesData();
  },
  isRecurring() {
    const view = this.getActiveView();
    if (!view) return false;
    return view.isRecurring();
  },
  getContactUrl() {
    if (this.isRecurring()) return Rover.urls.recurringBillingCreationUrl;
    return Rover.urls.conversationCreationUrl;
  },
});
