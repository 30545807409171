declare global {
  interface Window {
    htmlUtils: {
      stopPropagation: (event: Event) => ReturnType<Event['stopPropagation']>;
    };
  }
}

if (typeof window.htmlUtils === 'undefined') {
  window.htmlUtils = {
    stopPropagation: (event) => event.stopPropagation(),
  };
}

export default window.htmlUtils;
