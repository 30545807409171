import NumUnitsSupportView from './num_units_support_view';
import ServiceDateSpecView from './service_date_spec_view';
import RecurringDatesModel from '../models/recurring_dates_model';
import Mustache from '@rover/globals/Mustache';
import _ from '@rover/globals/underscore';

export default NumUnitsSupportView.extend({
  template: Mustache.template('new_design/common/recurring_days'),
  bindings: {
    '.js-monday': 'monday',
    '.js-tuesday': 'tuesday',
    '.js-wednesday': 'wednesday',
    '.js-thursday': 'thursday',
    '.js-friday': 'friday',
    '.js-saturday': 'saturday',
    '.js-sunday': 'sunday',
    '.js-hidden-input': {
      observe: ServiceDateSpecView.prototype.DAYS,
      onGet(values) {
        const total = _.filter(values, value => value).length;
        return total ? '1' : '';
      },
      updateModel: false,
    },
  },
  initialize(options) {
    NumUnitsSupportView.prototype.initialize.call(this, options);
    const self = this;
    this.model = new RecurringDatesModel();
    _.each(this.getDaysFromUrl(), day => self.model.set(day, true));
    this.propagateDatesChanged(this.model, 'change');
    this.model.on('change', _.bind(this.onModelChange, this));
  },
  render() {
    const ctx = _.extend(this.model.toJSON(), {
      slug: this.service.get('slug'),
      units: this.service.units(),
      supportsNumUnitsPerDay: this.numUnitsView !== null,
    });

    this.$el.html(this.template.render(ctx));

    if (this.numUnitsView) {
      this.$('.js-recurring-num-units').append(this.numUnitsView.render().$el);
    }

    this.stickit();
    return this;
  },
  getDatesData() {
    const numUnits = this.getNumUnits();
    const days = [];
    const self = this;

    _.each(this.DAYS, day => {
      if (self.model.get(day)) {
        days.push({ day, num_units: numUnits });
      }
    });

    if (!days.length) return {};
    return { days };
  },
  isRecurring() {
    return true;
  },
  hasErrors() {
    return this.$('.js-days-of-the-week').hasClass('has-error');
  },
  onModelChange() {
    if (this.hasErrors()) {
      this.$('.js-hidden-input').valid();
    }
  },
});
