; (($, window, document) => {
    const getAbsoluteUrl = (() => {
        let a;

        return (url) => {
            a = a || document.createElement('a');
            a.href = url;
            return a.href;
        };
    })();

    const getData = (url) => {
        const data = Rover.API_DATA[url];
        delete Rover.API_DATA[url];
        return data;
    };

    $.ajaxTransport('+*', (options) => {
        /*
         * jQuery will call this function before making the actual AJAX call
         * to the server. If this function returns an object then that is used
         * as the response. If this function returns undefined then the real
         * AJAX call is made.
         *
         * The goal of this function is to work with the embed_api_data
         * template tag to have API data needed by the page embedded into the
         * page server side so that AJAX calls don't need to be made.
         *
         * This functionality should be transparent to the code using it. If
         * the data is available in the page then the AJAX call will return
         * instantly, if not then an AJAX request is made to the server and
         * that data is returned.
         *
         * This function checks for Rover API data inside the global variable
         * Rover.API_DATA, if data for that URL is present. After using that
         * local data once it is discarded so future requests go to the server.
         * If a non GET request is made for a URL that has cached data the
         * cached data is discarded and the non GET request is sent to the
         * server.
         */
        const data = getData(getAbsoluteUrl(options.url));
        if (data !== undefined && options.type === 'GET') {
            const dataType = options.dataType || 'application/json';
            return {
                send(headers, completeCallback) {
                    const response = {};
                    response[dataType] = JSON.parse(data);
                    completeCallback(200, 'success', response, '');
                },
                abort() {},
            };
        }
        return null;
    });
})(jQuery, window, document);
